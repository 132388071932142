<template>
 <section class="hero is-black is-fullwidth">
  <div class="container">
    <div class="hero-body">
    <p style="color: white; font-family: 'Baloo 2', cursive; font-size: 6rem; letter-spacing: 0.1em;" class="title">
      MNO Info
    </p>
    <p style="color: white;font-family: 'Baloo 2', cursive;letter-spacing: 0.1em;" class="subtitle">
    Freshly updated Mobile Network Operators data
    </p>
  </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>