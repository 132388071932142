<template>
<button id="excelExport" style="margin-bottom: 25px;" @click="ExportToExcel('xlsx')" class="btn-hover color-8 is-pulled-left ">
    <span class="icon is-small">
      <i class="fas fa-file-excel"></i>
    </span>
    <span>Export to .xls</span>
</button>
<button @click="technologyShow = !technologyShow" style="margin-bottom: 25px;" class="btn-hover color-9 is-pulled-left">{{techToggle}} Tech column</button>
<button @click="ownerShow = !ownerShow" class="btn-hover color-11 is-pulled-left">{{ownerToggle}} Owner column</button>
<input placeholder="Free text search Country or Network" id="input1" ref="searchInput" v-model="search" type="text" class="input is-large animate__animated animate__backInDown">
    <div class="table-container">
<table id="mnotable" class="table is-bordered is-striped is-hoverable is-fullwidth">
  <thead class="has-background-black-bis">
    <tr>
      <th class="has-text-white-bis">Country</th>
      <th class="has-text-white-bis">Rank</th>
      <th class="has-text-white-bis">Operator</th>
      <th v-if="technologyShow" class="has-text-white-bis animate__animated animate__bounce">Technology</th>
      <th class="has-text-white-bis">Subscribers</th>
      <th v-if="ownerShow" class="has-text-white-bis animate__animated animate__bounce">Owner</th>

    </tr>
  </thead>
  <tbody>
    <tr v-for="row in searchResult">
      <th>{{row['Country']}}</th>
      <td>{{row['Rank']}}</td>
      <!-- <td v-if="row['OperatorLink'].length == 0">{{row['Operator']}}</td> -->
      <td>{{row['Operator']}}</td>
      <td style="animate__animated animate__bounce" v-if="technologyShow"> {{row['Technology']}}</td>
      <td>{{row['Subscribers']}}</td>
      <td v-if="ownerShow">{{row['Owner']}}</td>
    </tr>
  </tbody>
</table>
</div>
</template>
<script>
export default {
data () {
    return {
        ownerShow: false,
        technologyShow: false,
        search: '',
        mno_data:  [
  
  {
    "Country": "Afghanistan",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "6.2 Q4 2020",
    "Owner": "MTN",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Afghanistan",
    "Rank": 2,
    "Operator": "Roshan",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "5.4 Q4 2020",
    "Owner": "Aga Khan Fund for Economic Development AKFED 51% Monaco Telecom International MTI 36.75% TeliaSonera AB 12.25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Roshan_(telco)"
  },
  {
    "Country": "Afghanistan",
    "Rank": 3,
    "Operator": "Etisalat Afghanistan",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS 3G HSPA 3.75G LTE Under Trial",
    "Subscribers": "5.4 Q4 2020",
    "Owner": "Emirates Telecommunications Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Etisalat"
  },
  {
    "Country": "Afghanistan",
    "Rank": 4,
    "Operator": "Afghan Wireless",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "3.7 Q4 2020",
    "Owner": "Telephone Systems International",
    "OperatorLink": "https://www.wikipedia.org/wiki/AWCC"
  },
  {
    "Country": "Afghanistan",
    "Rank": 5,
    "Operator": "Salaam",
    "Technology": "",
    "Subscribers": "2.0 Q4 2020",
    "Owner": "",
    "OperatorLink": ""
  },
  {
    "Country": "American Samoa",
    "Rank": 1,
    "Operator": "Blue Sky Communications",
    "Technology": "850 MHz GSM850 MHz UMTS HSPA LTE",
    "Subscribers": "0.0320 2012",
    "Owner": "Amper SpA",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "American Samoa",
    "Rank": 2,
    "Operator": "American Samoa Telecommunications Authority ASTCA",
    "Technology": "800 MHz CDMA2000 Evolution Data OptimizedRev.A LTE",
    "Subscribers": "NA",
    "Owner": "Government of American Samoa",
    "OperatorLink": ""
  },
  {
    "Country": "Australia",
    "Rank": 1,
    "Operator": "Telstra",
    "Technology": "850 MHz UMTS DC HSPA 700 28 900 8 1800 3 2600 7 MHz LTE LTE A 850 n5 3500 n78 5G NR",
    "Subscribers": "18.8 2020",
    "Owner": "Telstra",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telstra"
  },
  {
    "Country": "Australia",
    "Rank": 2,
    "Operator": "Optus",
    "Technology": "900 2100 MHz UMTS DC HSPA 700 28 1800 3 2100 1 2600 7 2300 40 MHz LTE A 2300 n40 3500 n78 5G NR 3G on band 2100 MHz available only till 04.2022",
    "Subscribers": "10.52 Dec 2019 including MBB",
    "Owner": "Singtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Optus"
  },
  {
    "Country": "Australia",
    "Rank": 3,
    "Operator": "Vodafone TPG Telecom formerly Vodafone VHA and TPG",
    "Technology": "900 MHz UMTS DC HSPA 850 5 1800 3 2100 1 2600 7 MHz LTE A 700 n28 3500 n78 5G NR",
    "Subscribers": "5.8 Q1 2020",
    "Owner": "Vodafone 25.05% Hutchison Telecom 25.05% Former TPG shareholders 49.9% e.g. David Teoh WHSP public shareholders",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Australia"
  },
  {
    "Country": "Bangladesh",
    "Rank": 1,
    "Operator": "Grameenphone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 900 1800 MHz FDD LTE VoLTE ViLTE",
    "Subscribers": 84.04,
    "Owner": "Telenor 55.8% Grameen Telecom 34.2% General Public other Institutions 10%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Grameenphone"
  },
  {
    "Country": "Bangladesh",
    "Rank": 2,
    "Operator": "Robi",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 900 1800 MHz FDD LTE LTE A VoLTE ViLTE",
    "Subscribers": 53.72,
    "Owner": "Axiata Group Berhad 61.82% Bharti Airtel 28.18% General public 10%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Robi_(company)"
  },
  {
    "Country": "Bangladesh",
    "Rank": 3,
    "Operator": "Banglalink",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 900 1800 MHz FDD LTE LTE A",
    "Subscribers": 37.21,
    "Owner": "VEON",
    "OperatorLink": "https://www.wikipedia.org/wiki/Banglalink"
  },
  {
    "Country": "Bangladesh",
    "Rank": 4,
    "Operator": "Teletalk",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 900 1800 MHz FDD LTE",
    "Subscribers": 6.56,
    "Owner": "State owned",
    "OperatorLink": "https://www.wikipedia.org/wiki/Teletalk"
  },
  {
    "Country": "Bhutan",
    "Rank": 1,
    "Operator": "B Mobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 850 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "0.464 June 2021",
    "Owner": "Bhutan Telecom Limited BTL",
    "OperatorLink": "https://www.wikipedia.org/wiki/B-Mobile_(Bhutan)"
  },
  {
    "Country": "Bhutan",
    "Rank": 2,
    "Operator": "TashiCell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 850 2100 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "0.275 June 2021",
    "Owner": "Tashi Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Brunei Darussalam",
    "Rank": 1,
    "Operator": "Datastream Technology Sdn Bhd DST",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSPA HSPA 1800 MHz LTE",
    "Subscribers": 0.32,
    "Owner": "DST group",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Brunei Darussalam",
    "Rank": 2,
    "Operator": "Progresif Cellular Sdn Bhd formerly B mobile",
    "Technology": "2100 MHz UMTS HSDPA HSPA HSPA",
    "Subscribers": 0.15,
    "Owner": "Darussalam Assets Sdn Bhd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Progresif_Cellular_Sdn_Bhd"
  },
  {
    "Country": "Cambodia",
    "Rank": 2,
    "Operator": "Smart Axiata",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": 8,
    "Owner": "Axiata Group Berhad",
    "OperatorLink": "https://www.wikipedia.org/wiki/Smart_Axiata"
  },
  {
    "Country": "Cambodia",
    "Rank": 1,
    "Operator": "Metfone",
    "Technology": "GSM 900 1800 GPRS UMTS HSDPA LTE",
    "Subscribers": "9 2015",
    "Owner": "Viettel Cambodia",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Metfone&action=edit&redlink=1"
  },
  {
    "Country": "Cambodia",
    "Rank": 3,
    "Operator": "Cellcard Mobitel",
    "Technology": "GSM 900 MHz2100 MHz UMTS LTE",
    "Subscribers": "2.5 2010",
    "Owner": "The Royal Group",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Cellcard/Mobitel&action=edit&redlink=1"
  },
  {
    "Country": "Cambodia",
    "Rank": 4,
    "Operator": "qb",
    "Technology": "GSM 1800 MHz 2100 MHz UMTS",
    "Subscribers": "0.05 citation needed",
    "Owner": "Cambodia Advance Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Cambodia",
    "Rank": 5,
    "Operator": "Yes Seatel",
    "Technology": "FDD LTE 850MHz 4G VoLTE network",
    "Subscribers": "0.01 July 2015",
    "Owner": "South East Asia Telecom Group",
    "OperatorLink": ""
  },
  {
    "Country": "China (mainland)",
    "Rank": 1,
    "Operator": "China Mobile",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 B1 1800 B3 900 B8 LTE 1900 B39 2300 B40 2600 B38 B41 MHz TD LTE VoLTE 2.6 GHz N41 5G NR",
    "Subscribers": "945.50 June 2021",
    "Owner": "Government of China",
    "OperatorLink": "https://www.wikipedia.org/wiki/China_Mobile"
  },
  {
    "Country": "China (mainland)",
    "Rank": 2,
    "Operator": "China Telecom",
    "Technology": "850 2100 MHz CDMA2000 1xRTT EV DO Rev 0 Rev A 850 B5 1800 B3 2100 B1 MHz LTE A VoLTE 3.5 GHz N78 5G NR",
    "Subscribers": "362.49 June 2021",
    "Owner": "Government of China",
    "OperatorLink": "https://www.wikipedia.org/wiki/China_Telecom"
  },
  {
    "Country": "China (mainland)",
    "Rank": 3,
    "Operator": "China Unicom",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 1800 B3 2100 B1 MHz LTE A 3.5 GHz N78 5G NR",
    "Subscribers": "310.45 June 2021",
    "Owner": "Government of China",
    "OperatorLink": "https://www.wikipedia.org/wiki/China_Unicom"
  },
  {
    "Country": "Cook Islands",
    "Rank": 1,
    "Operator": "Vodafone formerly Bluesky",
    "Technology": "900 MHz GSMUMTSLTE",
    "Subscribers": "NA",
    "Owner": "Teleraro Limited 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Fiji",
    "Rank": 1,
    "Operator": "Vodafone",
    "Technology": "900 MHz GSM GPRS EDGE2100 MHz UMTS HSDPA1800 MHz LTE",
    "Subscribers": "0.730 2017",
    "Owner": "Amalgamated Telecom Holdings 72.6% owned by the Fiji National Provident Fund 51% Fiji National Provident Fund 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_fiji"
  },
  {
    "Country": "Fiji",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "900 MHz GSM GPRS EDGE900 MHz UMTS HSPA 800 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "French Polynesia",
    "Rank": 1,
    "Operator": "VINI",
    "Technology": "900 MHz GPRS EDGE2100 MHz UMTS HSDPA 800 2600 MHz LTE",
    "Subscribers": "0.250 citation needed",
    "Owner": "ONATi SAS",
    "OperatorLink": "http://www.vini.pf"
  },
  {
    "Country": "French Polynesia",
    "Rank": 2,
    "Operator": "Vodafone",
    "Technology": "GSMUMTSLTE",
    "Subscribers": "0.035 citation needed",
    "Owner": "Pacific Mobile Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone"
  },
  {
    "Country": "French Polynesia",
    "Rank": 3,
    "Operator": "VITI",
    "Technology": "WiMAX 800 2600 MHz LTE",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/WiMAX"
  },
  {
    "Country": "Guam",
    "Rank": 1,
    "Operator": "IT E and Iconnect",
    "Technology": "1900 MHz UMTS DC HSPA 700 1900 MHz LTE Advanced",
    "Subscribers": "NA",
    "Owner": "PTI Pacifica Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/UMTS"
  },
  {
    "Country": "Guam",
    "Rank": 2,
    "Operator": "Docomo Pacific",
    "Technology": "CdmaOne GSM 1900 MHz GPRS 850 MHz UMTS700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "NTT DoCoMo",
    "OperatorLink": "https://www.wikipedia.org/wiki/Docomo_Pacific"
  },
  {
    "Country": "Guam",
    "Rank": 3,
    "Operator": "GTA",
    "Technology": "GSM 850 1900 MHz GPRS 850 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "TeleGuam Holdings LLC",
    "OperatorLink": "https://www.wikipedia.org/wiki/GTA_Teleguam"
  },
  {
    "Country": "Hong Kong",
    "Rank": 1,
    "Operator": "CMHK China Mobile Hong Kong",
    "Technology": "GSM 1800 GPRS EDGE 2100 MHz UMTS HSPA DC HSPA 900 1800 2100 2600 B7 2300 B40 MHz LTE A 3.5 GHz n78 4.7 GHz n79 5G NR",
    "Subscribers": "5.000 Jun 2021",
    "Owner": "China Mobile",
    "OperatorLink": "https://www.wikipedia.org/wiki/CMHK"
  },
  {
    "Country": "Hong Kong",
    "Rank": 2,
    "Operator": "HKT including csl 1O1O Club SIM and SUN Mobile",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA DC HSPA 900 1800 2600 B7 MHz LTE A3.5 GHz n78 4.7 GHz n79 5G NR",
    "Subscribers": "4.477 Jun 2021",
    "Owner": "PCCW Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Hong_Kong_Telecom"
  },
  {
    "Country": "Hong Kong",
    "Rank": 3,
    "Operator": "3 Hong Kong",
    "Technology": "GSM 900 1800 GPRS EDGE 900 MHz UMTS HSPA DC HSPA 900 1800 2100 2600 B7 2300 B40 MHz LTE A 3.5 GHz n78 5G NR",
    "Subscribers": "3.279 Dec 2020",
    "Owner": "Hutchison Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/3_Hong_Kong"
  },
  {
    "Country": "Hong Kong",
    "Rank": 4,
    "Operator": "SmarTone",
    "Technology": "GSM 900 1800 GPRS 850 2100 MHz UMTS HSPA DC HSPA 900 1800 2100 2600 B7 MHz LTE A3.5 GHz n78 5G NR",
    "Subscribers": "2.70 Dec 2020",
    "Owner": "Sun Hung Kai Properties",
    "OperatorLink": "https://www.wikipedia.org/wiki/SmarTone"
  },
  {
    "Country": "India",
    "Rank": 1,
    "Operator": "Jio",
    "Technology": "850 B5 1800 B3 2300 B40 LTE TD LTE FD LTE LTE A VoLTE ViLTE VoWiFi WiFi",
    "Subscribers": 426.6,
    "Owner": "Jio Platforms",
    "OperatorLink": "https://www.wikipedia.org/wiki/Jio"
  },
  {
    "Country": "India",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 1800 EDGE GPRS 900 B8 1800 B3 2100 B1 2300 B40 LTE TD LTE FD LTE LTE A VoLTE ViLTE VoWiFi WiFi",
    "Subscribers": 353.98,
    "Owner": "Bharti Airtel Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_India"
  },
  {
    "Country": "India",
    "Rank": 3,
    "Operator": "Vi",
    "Technology": "GSM 900 1800 EDGE GPRS 900 B8 1800 B3 2100 B1 2300 B40 2500 B41 LTE TD LTE FD LTE LTE A VoLTE ViLTE VoWiFi WiFi",
    "Subscribers": 269.03,
    "Owner": "Vodafone Idea Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Idea"
  },
  {
    "Country": "India",
    "Rank": 4,
    "Operator": "BSNL",
    "Technology": "GSM 900 1800 EDGE GPRS 850 B5 2100 B1 2500 B41 LTE TD LTE FD LTE LTE A VoLTE WiMAX WiFi",
    "Subscribers": 116.7,
    "Owner": "Government of India",
    "OperatorLink": "https://www.wikipedia.org/wiki/Bharat_Sanchar_Nigam_Limited"
  },
  {
    "Country": "Indonesia",
    "Rank": 1,
    "Operator": "Telkomsel Include previous TelkomFlexi network",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA 900 1800 2100 MHz FDD LTE 2300 MHz TDD LTE VoLTE2300 n40 MHz NR",
    "Subscribers": "169.5 2020",
    "Owner": "Telkom Indonesia 65% Singtel 35%",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 2,
    "Operator": "XL Axiata Include previous Axis network",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA DC HSPA 900 1800 MHz FDD LTE2100 n1 1800 n3 MH NR",
    "Subscribers": "53.3 June 2019",
    "Owner": "Axiata Group Berhad 61.16% Public 38%",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 3,
    "Operator": "Indosat Ooredoo Hutchison Include previous Satelindo Indosat M3 3 and StarOne network",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA DC HSPA 900 1800 2100 MHz FDD LTE LTE A1800 n3 MHz NR",
    "Subscribers": "NA",
    "Owner": "Ooredoo Hutchison Asia PTE Ltd 65% Garibaldi Thohir 10.8% Government of Indonesia 9.6% Public 14%",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 4,
    "Operator": "Smartfren Include previous Esia and Mobile 8 network",
    "Technology": "850 MHz FDD LTE 2300 MHz TDD LTE VoLTE",
    "Subscribers": "NA",
    "Owner": "Sinar Mas Group",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 1,
    "Operator": "by.U using Telkomsel",
    "Technology": "N A",
    "Subscribers": "N A",
    "Owner": "Telkomsel",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 2,
    "Operator": "MPWR using Indosat Ooredoo Hutchison",
    "Technology": "N A",
    "Subscribers": "N A",
    "Owner": "Indosat Ooredoo Hutchison",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 3,
    "Operator": "Live.On using XL Axiata",
    "Technology": "N A",
    "Subscribers": "N A",
    "Owner": "XL Axiata Circles.Life",
    "OperatorLink": ""
  },
  {
    "Country": "Indonesia",
    "Rank": 4,
    "Operator": "Power Up using Smartfren",
    "Technology": "N A",
    "Subscribers": "N A",
    "Owner": "Smartfren",
    "OperatorLink": ""
  },
  {
    "Country": "Japan",
    "Rank": 1,
    "Operator": "NTT Docomo",
    "Technology": "800 B6 2100 B1 MHz UMTS HSPA 700 B28 800 B19 1500 B21 1800 B3 2100 B1 MHz LTE Wideband LTE Advanced 3500 B42 MHz TD LTE LTE A 3.5 n78 4.9 n79 GHz 5G NR",
    "Subscribers": "82.632 Mar 2021",
    "Owner": "NTT",
    "OperatorLink": "https://www.wikipedia.org/wiki/NTT_Docomo"
  },
  {
    "Country": "Japan",
    "Rank": 2,
    "Operator": "au",
    "Technology": "850 2100 MHz CDMA2000 1xRTT EV DO 700 B28 800 B18 1500 B11 1800 B3 2100 B1 MHz LTE 2500 B41 3500 B42 MHz TD LTE 3.5 n78 3.7 n77 GHz 5G NR",
    "Subscribers": "60.398 Mar 2021",
    "Owner": "KDDI",
    "OperatorLink": "https://www.wikipedia.org/wiki/Au_(mobile_phone_operator)"
  },
  {
    "Country": "Japan",
    "Rank": 3,
    "Operator": "SoftBank Corp. Ymobile",
    "Technology": "900 B8 2100 B1 MHz UMTS HSPA DC HSPA 700 B28 900 B8 1500 B11 1800 B3 2100 B1 MHz LTE 2500 B41 3500 B42 MHz TD LTE 3.7 n77 GHz 5G NR",
    "Subscribers": "45.621 Mar 2021",
    "Owner": "SoftBank Group 99.99%",
    "OperatorLink": "https://www.wikipedia.org/wiki/SoftBank_Mobile"
  },
  {
    "Country": "Japan",
    "Rank": 4,
    "Operator": "Rakuten Mobile",
    "Technology": "1800 B3 MHz LTE 3.5 n77 GHz 5G NR",
    "Subscribers": "N A",
    "Owner": "Rakuten",
    "OperatorLink": "https://www.wikipedia.org#cite_note-62"
  },
  {
    "Country": "Japan",
    "Rank": 1,
    "Operator": "Disney Mobile using SoftBank Mobile",
    "Technology": "UMTS HSPA DC HSDPATD LTE",
    "Subscribers": "N A",
    "Owner": "SoftBank The Walt Disney Company",
    "OperatorLink": "https://www.wikipedia.org/wiki/Disney_Mobile"
  },
  {
    "Country": "Japan",
    "Rank": 2,
    "Operator": "b mobile using NTT Docomo",
    "Technology": "2100 800 MHz UMTS HSPA800 1500 2100 MHz LTE",
    "Subscribers": "N A",
    "Owner": "Japan Communications Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/UMTS"
  },
  {
    "Country": "Japan",
    "Rank": 3,
    "Operator": "IIJmio",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Internet Initiative Japan",
    "OperatorLink": "https://www.wikipedia.org/wiki/Internet_Initiative_Japan"
  },
  {
    "Country": "Kazakhstan",
    "Rank": 1,
    "Operator": "Beeline including izi",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2100 MHz LTE LTE A n78 5G NR",
    "Subscribers": "9.8 October 2021",
    "Owner": "VEON",
    "OperatorLink": "https://www.wikipedia.org/wiki/VimpelCom"
  },
  {
    "Country": "Kazakhstan",
    "Rank": 2,
    "Operator": "Kcell including activ",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2100 MHz LTE LTE A",
    "Subscribers": "7.957 Sep 2021",
    "Owner": "KazakhTelecom 51% Jysan Bank 24% free float 25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Kcell"
  },
  {
    "Country": "Kazakhstan",
    "Rank": 3,
    "Operator": "Tele2 Kazakhstan including Altel",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2100 MHz LTE LTE An78 5G NR",
    "Subscribers": "7.255 Dec 2019",
    "Owner": "KazakhTelecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Tele2_Kazakhstan&action=edit&redlink=1"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 1,
    "Operator": "MegaCom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2100 MHz LTE",
    "Subscribers": "2.7 2012",
    "Owner": "JSC Alpha Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 2,
    "Operator": "Beeline",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "2.4 Aug 2012",
    "Owner": "Sky Mobile Ltd. VEON",
    "OperatorLink": "https://www.wikipedia.org/wiki/Beeline_(telecommunications)"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 3,
    "Operator": "Fonex",
    "Technology": "CDMA HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.42 2008",
    "Owner": "Aktel Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CDMA"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 4,
    "Operator": "O",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 2600 MHz LTE",
    "Subscribers": 0.9,
    "Owner": "NUR Telecom Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 5,
    "Operator": "Katel",
    "Technology": "TDMA HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.1 2008",
    "Owner": "KATEL",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digital_AMPS"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 6,
    "Operator": "Nexi",
    "Technology": "CDMA HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.049 2008",
    "Owner": "SoTel",
    "OperatorLink": "https://www.wikipedia.org/wiki/CDMA"
  },
  {
    "Country": "Kyrgyzstan",
    "Rank": 7,
    "Operator": "Sapat Mobile",
    "Technology": "CDMA HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.011 2008",
    "Owner": "Winline Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CDMA"
  },
  {
    "Country": "Laos",
    "Rank": 1,
    "Operator": "Unitel",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA 1800 MHz LTE",
    "Subscribers": "2.705 citation needed",
    "Owner": "Lao Asia Telecommunication State Enterprise LAT 51% Viettel Global 49%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Star_Telecom_Co.,_Ltd&action=edit&redlink=1"
  },
  {
    "Country": "Laos",
    "Rank": 2,
    "Operator": "LaoTelecom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA 14.4 Mbit s 2600 MHz LTE",
    "Subscribers": "1.291 citation needed",
    "Owner": "Government of Lao P.D.R. 51% Shennington Investments Thaicom 49%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Lao_Telecommunications&action=edit&redlink=1"
  },
  {
    "Country": "Laos",
    "Rank": 3,
    "Operator": "TPLUS",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 21 Mbit s WiMAX",
    "Subscribers": 0.289,
    "Owner": "Government of Lao P.D.R. 100%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=TPLUS_(brand)&action=edit&redlink=1"
  },
  {
    "Country": "Laos",
    "Rank": 4,
    "Operator": "ETL",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 21 Mbit s",
    "Subscribers": "NA",
    "Owner": "Jiafu Holdings 51% Government of Lao P.D.R. 49%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=ETL_Co.,_Ltd&action=edit&redlink=1"
  },
  {
    "Country": "Macau",
    "Rank": 1,
    "Operator": "CTM",
    "Technology": "GSM 900 1800 MHz GPRS 2100 MHz UMTS HSDPA 1800 2600 B7 2300 B40 MHz LTE A",
    "Subscribers": "0.856 December 2016",
    "Owner": "CITIC Telecom International 99% Correios de Macau 1%",
    "OperatorLink": "https://www.wikipedia.org/wiki/CTM_(Macau)"
  },
  {
    "Country": "Macau",
    "Rank": 2,
    "Operator": 3,
    "Technology": "GSM 900 1800 MHz GPRS 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "Information not available",
    "Owner": "Hutchison Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Hutchison_3G"
  },
  {
    "Country": "Macau",
    "Rank": 3,
    "Operator": "China Telecom",
    "Technology": "CDMA CDMA2000 EVDO 1800 MHz LTE",
    "Subscribers": "Information not available",
    "Owner": "Government of China",
    "OperatorLink": "https://www.wikipedia.org/wiki/China_Telecom"
  },
  {
    "Country": "Macau",
    "Rank": 4,
    "Operator": "SmarTone",
    "Technology": "GSM 900 1800 MHz GPRS 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "Information not available",
    "Owner": "Sun Hung Kai Properties",
    "OperatorLink": "https://www.wikipedia.org/wiki/SmarTone"
  },
  {
    "Country": "Malaysia",
    "Rank": 1,
    "Operator": "ALTEL",
    "Technology": "lease 2600 MHz spectrum to Digi and Celcom uses Celcom 2G 3G 4G network",
    "Subscribers": "0.7 2016",
    "Owner": "Puncak Semangat Sdn Bhd unit owned by Syed Mokhtar Al Bukhary",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digi_Telecommunications"
  },
  {
    "Country": "Malaysia",
    "Rank": 2,
    "Operator": "Maxis",
    "Technology": "2G 900 1800 MHz EDGE 4G 900 B8 1800 B3 2100 B1 2600 B7 MHz LTE LTE A VoLTE VoWiFi eSIM",
    "Subscribers": "11.651 3Q 2021",
    "Owner": "Ananda Krishnan 62% Saudi Telecom Company 17.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Maxis_Communications"
  },
  {
    "Country": "Malaysia",
    "Rank": 3,
    "Operator": "Digi with a digital sub brand Tapp",
    "Technology": "2G 900 1800 MHz EDGE 4G 900 B8 1800 B3 2100 B1 2600 B7 MHz LTE LTE A VoLTE VoWiFi eSIM",
    "Subscribers": "10.365 3Q 2021",
    "Owner": "Telenor 49% Employees Provident Fund Malaysia 13.3%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digi_Telecommunications"
  },
  {
    "Country": "Malaysia",
    "Rank": 4,
    "Operator": "Celcom with a digital sub brand yoodo",
    "Technology": "2G 900 1800 MHz EDGE 3G 900 2100 MHz UMTS HSPA HSPA 4G 900 B8 1800 B3 2100 B1 2600 B7 MHz LTE LTE A VoLTE eSIM",
    "Subscribers": "9.223 2Q 2021 not including MVNOs 12.793 Incld MVNOs as 4Q19",
    "Owner": "Axiata Group Berhad",
    "OperatorLink": "https://www.wikipedia.org/wiki/Celcom"
  },
  {
    "Country": "Malaysia",
    "Rank": 5,
    "Operator": "U Mobile",
    "Technology": "2G Uses Celcom 2G nationwide 4G 900 B8 1800 B3 2100 B1 2600 B7 MHz LTE LTE A VoLTE VoWiFi eSIM",
    "Subscribers": "6.12 4Q 2017",
    "Owner": "ST Telemedia 35% U Television Sdn Bhd 21% Ibrahim Ismail of Johor 15% MPHB Capital Bhd. 6%",
    "OperatorLink": "https://www.wikipedia.org/wiki/U_Mobile"
  },
  {
    "Country": "Malaysia",
    "Rank": 6,
    "Operator": "Yes 4G",
    "Technology": "4G 800 B20 2300 B40 2600 B38 MHz LTE LTE A uses DNB 5G NSA VoLTE",
    "Subscribers": "0.7 3Q 2013",
    "Owner": "YTL Communications a unit of YTL Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Yes_4G"
  },
  {
    "Country": "Malaysia",
    "Rank": 7,
    "Operator": "Unifi Mobile formerly known as webe",
    "Technology": "2G Uses Celcom 2G nationwide4G 850 B5 2300 B40 2600 B7 2600 B38 MHz LTE LTE A VoLTE 2.6GHz Band 7 LTE in some in building places by Celcom network",
    "Subscribers": "0.4674 3Q 2014",
    "Owner": "Telekom Malaysia 91.8% SK Telecom 8.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Unifi_Mobile"
  },
  {
    "Country": "Malaysia",
    "Rank": 8,
    "Operator": "Redtone",
    "Technology": "2300 MHz WiMAX East Malaysia only lease 2600 MHz spectrum to Maxis",
    "Subscribers": "NA",
    "Owner": "Berjaya Corporation Berhad",
    "OperatorLink": "https://www.wikipedia.org/wiki/WiMAX"
  },
  {
    "Country": "Malaysia",
    "Rank": 9,
    "Operator": "Digital Nasional Berhad Malaysia 5G Single Wholesale Network",
    "Technology": "700 MHz B28 LTE3500 MHz B77 78 5G NR",
    "Subscribers": "Yes 4G",
    "Owner": "Ministry of Finance Malaysia",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digital_Nasional_Berhad"
  },
  {
    "Country": "Malaysia",
    "Rank": 1,
    "Operator": "TuneTalk uses Celcom",
    "Technology": "",
    "Subscribers": "1.5 August 2014",
    "Owner": "Tune Ventures 35.75% Celcom 35%",
    "OperatorLink": "https://www.wikipedia.org/wiki/TuneTalk"
  },
  {
    "Country": "Malaysia",
    "Rank": 2,
    "Operator": "XOX uses Celcom",
    "Technology": "",
    "Subscribers": "1.7 Mac 2017",
    "Owner": "XOX",
    "OperatorLink": "https://www.wikipedia.org/wiki/XOX"
  },
  {
    "Country": "Malaysia",
    "Rank": 3,
    "Operator": "redONE uses Celcom",
    "Technology": "",
    "Subscribers": "1.2 March 2019",
    "Owner": "Redtone International",
    "OperatorLink": "https://www.wikipedia.org/wiki/Celcom"
  },
  {
    "Country": "Malaysia",
    "Rank": 4,
    "Operator": "Merchantrade uses Celcom",
    "Technology": "",
    "Subscribers": "0.3 3Q 2012",
    "Owner": "CelcomSumitomo Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Merchantrade_Asia"
  },
  {
    "Country": "Malaysia",
    "Rank": 5,
    "Operator": "SpeakOut uses DiGi",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Pavo Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/DiGi_Telecommunications"
  },
  {
    "Country": "Malaysia",
    "Rank": 6,
    "Operator": "MY Evolution uses DiGi",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "M2M services in Asia",
    "OperatorLink": "https://www.wikipedia.org/wiki/DiGi_Telecommunications"
  },
  {
    "Country": "Malaysia",
    "Rank": 7,
    "Operator": "Telin Malaysia Kartu As uses U Mobile",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Telin Malaysia. Telin Malaysia is a joint venture JV company between Compudyne Telecommunication Systems Sdn. Bhd and PT Telin a company fully owned by PT. Telekomunikasi Indonesia Telkom .",
    "OperatorLink": "https://www.wikipedia.org/wiki/U_Mobile"
  },
  {
    "Country": "Malaysia",
    "Rank": 8,
    "Operator": "mCalls uses DiGi",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Partnership between Pavo Communications and DiGi.",
    "OperatorLink": "https://www.wikipedia.org/wiki/DiGi"
  },
  {
    "Country": "Maldives",
    "Rank": 1,
    "Operator": "Dhiraagu",
    "Technology": "GSM 900 EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "0.350 October 2012",
    "Owner": "Dhiraagu",
    "OperatorLink": "https://www.wikipedia.org/wiki/Dhiraagu"
  },
  {
    "Country": "Maldives",
    "Rank": 2,
    "Operator": "Ooredoo Maldives",
    "Technology": "GSM 900 EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE",
    "Subscribers": "0.272 Q2 2014",
    "Owner": "Ooredoo",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo_Maldives"
  },
  {
    "Country": "Marshall Islands",
    "Rank": 1,
    "Operator": "NTA",
    "Technology": "GSM 900 UMTS LTE",
    "Subscribers": "0.0007 2008",
    "Owner": "MINTA",
    "OperatorLink": "http://www.ntamar.net"
  },
  {
    "Country": "Micronesia, Federated States of",
    "Rank": 1,
    "Operator": "FSM Telecom",
    "Technology": "GSM 900 UMTS",
    "Subscribers": "0.0274 2008",
    "Owner": "FSMTC",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=FSM_Telecom&action=edit&redlink=1"
  },
  {
    "Country": "Mongolia",
    "Rank": 1,
    "Operator": "Mobicom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 700 1800 MHz LTE",
    "Subscribers": "1.7 June 2014",
    "Owner": "KDDI Sumitomo Corporation Newcom Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobicom_Corporation"
  },
  {
    "Country": "Mongolia",
    "Rank": 2,
    "Operator": "Unitel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE",
    "Subscribers": "1.54 June 2014",
    "Owner": "MCS Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Unitel_(Mongolia)"
  },
  {
    "Country": "Mongolia",
    "Rank": 3,
    "Operator": "Skytel",
    "Technology": "CdmaOne2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.6 June 2014",
    "Owner": "Altai Holdings Shunkhlai Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Skytel_(Mongolia)"
  },
  {
    "Country": "Mongolia",
    "Rank": 4,
    "Operator": "G Mobile",
    "Technology": "CdmaOne2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.47 June 2014",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/G-Mobile"
  },
  {
    "Country": "Myanmar",
    "Rank": 1,
    "Operator": "MPT",
    "Technology": "450 800 MHz CDMA CDMA2000 1x EVDOGSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 1800 2100 MHz LTE LTE A",
    "Subscribers": "22 October 2018",
    "Owner": "State owned KDDI Sumitomo Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Myanmar_Posts_and_Telecommunications"
  },
  {
    "Country": "Myanmar",
    "Rank": 2,
    "Operator": "Telenor",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 2100 MHz LTE LTE A",
    "Subscribers": "17.183 October 2020",
    "Owner": "Telenor",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telenor_Myanmar"
  },
  {
    "Country": "Myanmar",
    "Rank": 3,
    "Operator": "Ooredoo",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 2100 MHz LTE LTE A",
    "Subscribers": "14.839 May 2020",
    "Owner": "Ooredoo",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo_Myanmar"
  },
  {
    "Country": "Myanmar",
    "Rank": 4,
    "Operator": "Mytel",
    "Technology": "GSM 900 MHz GPRS EDGE 1800 2100 MHz LTE LTE A",
    "Subscribers": "6 June 2019",
    "Owner": "Viettel 49% Star High Telecom 28% Myanmar National Telecom Holding 23%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mytel"
  },
  {
    "Country": "Nauru",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 UMTS LTE",
    "Subscribers": 0.007,
    "Owner": "Digicel Pacific Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Nepal",
    "Rank": 1,
    "Operator": "Ncell",
    "Technology": "GSM 900 18002100 MHz UMTS 3G 4G HSDPALTE 1800 MHz",
    "Subscribers": "15.889 August 2017",
    "Owner": "Axiata Group Berhad 80%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ncell"
  },
  {
    "Country": "Nepal",
    "Rank": 2,
    "Operator": "Nepal Telecom",
    "Technology": "CDMA EV DO900 MHz GSM GPRS900 2100 MHz UMTS HSDPA 3G 4G HSPA WiMAX 1800 MHz LTE 1800 MHz",
    "Subscribers": "15.502 August 2017",
    "Owner": "Nepal Doorsanchar Company Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Nepal_Telecom"
  },
  {
    "Country": "Nepal",
    "Rank": 3,
    "Operator": "Smart Telecom",
    "Technology": "3G 4G GSM 900 1800GPRS 900 2100 LTE 1800 MHz Testing",
    "Subscribers": "1.623 August 2017",
    "Owner": "Smart Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Smart_Telecom"
  },
  {
    "Country": "New Caledonia",
    "Rank": 1,
    "Operator": "Mobilis",
    "Technology": "900 MHz GSM2100 MHz UMTS800 1800 2600 MHz LTE",
    "Subscribers": "0.244 2012",
    "Owner": "Office des Postes et Telecommunications",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Office_des_Postes_et_Telecommunications&action=edit&redlink=1"
  },
  {
    "Country": "New Zealand",
    "Rank": 1,
    "Operator": "Vodafone formerly BellSouth",
    "Technology": "900 MHz GSM GPRS900 2100 MHz UMTS HSPA HSPA DC HSPA 700 1800 2100 2600 MHz LTE LTE A",
    "Subscribers": "2.538 July 2019",
    "Owner": "Infratil Limited Brookfield Asset Management Inc",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_New_Zealand"
  },
  {
    "Country": "New Zealand",
    "Rank": 2,
    "Operator": "Spark formerly Telecom",
    "Technology": "850 2100 MHz UMTS HSPA HSPA DC HSPA 700 1800 2100 2300 2600 MHz LTE LTE A",
    "Subscribers": "2.46 May 2019",
    "Owner": "Public NZX SPK",
    "OperatorLink": "https://www.wikipedia.org/wiki/Spark_New_Zealand"
  },
  {
    "Country": "New Zealand",
    "Rank": 3,
    "Operator": "2degrees",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 700 900 1800 2100 MHz LTE LTE A",
    "Subscribers": "1.41 May 2019",
    "Owner": "Tesbrit B.V. a Dutch investment company 27.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/2degrees"
  },
  {
    "Country": "New Zealand",
    "Rank": "",
    "Operator": "Compass Mobile using Spark",
    "Technology": "UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Compass Communications",
    "OperatorLink": "https://compass.net.nz/residential/mobile-plans"
  },
  {
    "Country": "New Zealand",
    "Rank": "",
    "Operator": "Orcon Mobile using Spark",
    "Technology": "UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Vocus Communications ASX VOC",
    "OperatorLink": "https://www.orcon.net.nz/mobile"
  },
  {
    "Country": "New Zealand",
    "Rank": "",
    "Operator": "Slingshot Mobile using Spark",
    "Technology": "UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Vocus Communications ASX VOC",
    "OperatorLink": "https://www.slingshot.co.nz/mobile/slingshot-mobile"
  },
  {
    "Country": "New Zealand",
    "Rank": "",
    "Operator": "Skinny using Spark",
    "Technology": "UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Spark NZX SPK",
    "OperatorLink": "https://www.wikipedia.org/wiki/Spark_New_Zealand_Mobile"
  },
  {
    "Country": "New Zealand",
    "Rank": "",
    "Operator": "Warehouse Mobile using 2degrees",
    "Technology": "UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "The Warehouse Group NZX TWG",
    "OperatorLink": ""
  },
  {
    "Country": "Niue",
    "Rank": 1,
    "Operator": "Telecom Niue",
    "Technology": "900 MHz GSM700 MHz LTE",
    "Subscribers": "0.000385 2008",
    "Owner": "Telecom Niue",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telecom_Niue&action=edit&redlink=1"
  },
  {
    "Country": "Norfolk Island",
    "Rank": 1,
    "Operator": "Norfolk Telecom",
    "Technology": "900 MHz UMTS700 MHz LTE",
    "Subscribers": "0.000131 October 2008",
    "Owner": "Norfolk Telecom",
    "OperatorLink": "https://www.ni.net.nf/plans/mobile/"
  },
  {
    "Country": "North Korea",
    "Rank": 1,
    "Operator": "Koryolink",
    "Technology": "2100 MHz UMTS",
    "Subscribers": "3.8 end 2015",
    "Owner": "Global Telecom Holding S.A.E. 75% Korea Post and Telecommunications Corporation 25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Koryolink"
  },
  {
    "Country": "Northern Mariana Islands",
    "Rank": 1,
    "Operator": "Docomo Pacific",
    "Technology": "CdmaOneGSM 1900 GPRS UMTS LTE",
    "Subscribers": "NA",
    "Owner": "NTT DoCoMo",
    "OperatorLink": "https://www.wikipedia.org/wiki/NTT_DoCoMo"
  },
  {
    "Country": "Northern Mariana Islands",
    "Rank": 2,
    "Operator": "IT E",
    "Technology": "CdmaOne GSM 850 UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "IT E Overseas Inc",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=IT%26E&action=edit&redlink=1"
  },
  {
    "Country": "Pakistan",
    "Rank": 1,
    "Operator": "Jazz Includes the previous Warid and Mobilink networks",
    "Technology": "900 MHz 1800 MHz GPRS EDGE 900 MHz 2100 MHz UMTS HSPA DC HSPA 900 MHz 1800 MHz LTE LTE A",
    "Subscribers": 72.1,
    "Owner": "VEON",
    "OperatorLink": ""
  },
  {
    "Country": "Pakistan",
    "Rank": 2,
    "Operator": "Telenor",
    "Technology": "900 MHz 1800 MHz GPRS EDGE 850 MHz 2100 MHz UMTS HSPA HSPA 850 MHz 1800 MHz LTE LTE A",
    "Subscribers": 49.56,
    "Owner": "Telenor",
    "OperatorLink": ""
  },
  {
    "Country": "Pakistan",
    "Rank": 3,
    "Operator": "Zong",
    "Technology": "900 MHz 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA DC HSPA 1800 MHz 2100 MHz LTE LTE A",
    "Subscribers": 41.99,
    "Owner": "China Mobile",
    "OperatorLink": ""
  },
  {
    "Country": "Pakistan",
    "Rank": 4,
    "Operator": "Ufone Roams on SCOM in Azad Kashmir Gilgit Baltistan",
    "Technology": "900 MHz 1800 MHz GPRS EDGE 900 MHz 2100 MHz UMTS HSPA HSPA 1800 MHz LTE",
    "Subscribers": 22.73,
    "Owner": "Etisalat 26% and Government of Pakistan",
    "OperatorLink": ""
  },
  {
    "Country": "Pakistan",
    "Rank": 5,
    "Operator": "SCOM Roams on Ufone in Mainland Pakistan Also known as SCO Mobile 41005",
    "Technology": "900 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 MHz LTE",
    "Subscribers": 1.61,
    "Owner": "Government of Pakistan",
    "OperatorLink": ""
  },
  {
    "Country": "Palau",
    "Rank": 1,
    "Operator": "PNCC PalauCel",
    "Technology": "GSM 900 MHz WCDMA 900 MHz Band 8 LTE 700 MHz Band 28",
    "Subscribers": "17 150",
    "Owner": "Palau National Communications Corporation PNCC",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Palau_National_Communications_Corp.&action=edit&redlink=1"
  },
  {
    "Country": "Papua New Guinea",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 GPRS EDGE 900 MHz UMTS HSDPA700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Papua New Guinea",
    "Rank": 2,
    "Operator": "BMobile",
    "Technology": "GSM 900 GPRS 900 MHz UMTS",
    "Subscribers": "NA",
    "Owner": "Pacific Mobile Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Papua New Guinea",
    "Rank": 3,
    "Operator": "Telikom PNG",
    "Technology": "850 MHz UMTS HSDPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Telikom PNG LTD",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telikom_PNG&action=edit&redlink=1"
  },
  {
    "Country": "Philippines",
    "Rank": 1,
    "Operator": "Globe Telecom",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA 700 850 1800 2100 MHz FDD LTE 2500 MHz TDD LTEWiMAX LTE A 3500 MHz 5G NR",
    "Subscribers": "83.9 September 30 2021",
    "Owner": "Ayala Corporation 13.20% Singtel 20.10% Asiacom Philippines Inc. 50.85% Directors Officers ESOP 0.19% Public Stock 15.66%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Globe_Telecom"
  },
  {
    "Country": "Philippines",
    "Rank": 2,
    "Operator": "Smart Communications",
    "Technology": "GSM 900 1800 GPRS EDGE 850 2100 MHz UMTS HSPA HSPA 700 850 1800 2100 MHz LTE LTE A WiMAX 3500 MHz 5G NR",
    "Subscribers": "70.5 September 30 2021",
    "Owner": "PLDT",
    "OperatorLink": "https://www.wikipedia.org/wiki/Smart_Communications"
  },
  {
    "Country": "Philippines",
    "Rank": 3,
    "Operator": "Dito Telecommunity",
    "Technology": "700 2000 2100 2500 3300 MHz LTE LTE A 3500 MHz 5G NR VoLTE ViLTE",
    "Subscribers": "5.0 December 27 2021",
    "Owner": "Dito Holdings Corporation 60% China Telecommunications Corporation 40%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Dito_Telecommunity"
  },
  {
    "Country": "Philippines",
    "Rank": 1,
    "Operator": "TNT using Smart",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Smart",
    "OperatorLink": "https://www.wikipedia.org/wiki/TNT_(cellular_service)"
  },
  {
    "Country": "Philippines",
    "Rank": 2,
    "Operator": "TM using Globe",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Globe",
    "OperatorLink": "https://www.wikipedia.org/wiki/TM_(cellular_service)"
  },
  {
    "Country": "Philippines",
    "Rank": 3,
    "Operator": "Sun using Smart",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Smart",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sun_Cellular"
  },
  {
    "Country": "Philippines",
    "Rank": 4,
    "Operator": "GOMO using Globe",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Globe",
    "OperatorLink": ""
  },
  {
    "Country": "Philippines",
    "Rank": 5,
    "Operator": "Cherry Prepaid using Globe",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Globe TelecomCosmic Technologies",
    "OperatorLink": ""
  },
  {
    "Country": "Samoa",
    "Rank": 1,
    "Operator": "Vodafone Samoa formerly Bluesky Samoa",
    "Technology": "900 MHz GSM1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": "https://www.vodafone.com.ws/"
  },
  {
    "Country": "Samoa",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "900 MHz GSM GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Singapore",
    "Rank": 1,
    "Operator": "Singtel Mobile with a digital sub brand GOMO",
    "Technology": "3G 900 2100 MHz UMTS HSPA HSPA DC HSPA 4G 900 B8 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A5G 2.1 GHz n1 3.5 GHz n78 5G NR NSA SA VoLTE VoWiFi eSIM Available only for Apple Watch",
    "Subscribers": "4.1 March 2021",
    "Owner": "Temasek Holdings",
    "OperatorLink": "https://www.wikipedia.org/wiki/Singtel_Mobile"
  },
  {
    "Country": "Singapore",
    "Rank": 2,
    "Operator": "M1",
    "Technology": "3G 900 2100 MHz UMTS HSPA HSPA 4G 1800 B3 2600 B7 900 B8 MHz LTE A5G 2.1 GHz n1 3.5 GHz n78 5G NR NSA SA VoLTE VoWiFi VoNR eSIM Available",
    "Subscribers": "2.11 March 2021",
    "Owner": "Keppel Corporation SPH",
    "OperatorLink": "https://www.wikipedia.org/wiki/M1_Limited"
  },
  {
    "Country": "Singapore",
    "Rank": 3,
    "Operator": "StarHub with a digital sub brand giga",
    "Technology": "3G 900 2100 MHz UMTS HSPA HSPA DC HSPA 4G 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A5G 2.1 GHz n1 3.5 GHz n78 5G NR NSA SA VoLTE VoWiFi VoNR",
    "Subscribers": "1.95 1Q 2021",
    "Owner": "ST TelemediaOoredooNTT docomo",
    "OperatorLink": "https://www.wikipedia.org/wiki/StarHub"
  },
  {
    "Country": "Singapore",
    "Rank": 4,
    "Operator": "TPG Mobile",
    "Technology": "4G 900 B8 2300 B40 MHz LTE A VoLTE",
    "Subscribers": "0.4 March 2020",
    "Owner": "TPG Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/TPG_(ISP)"
  },
  {
    "Country": "Singapore",
    "Rank": 5,
    "Operator": "GRID Communications",
    "Technology": "806 821 851 866 MHz iDEN",
    "Subscribers": "N A",
    "Owner": "GRID Communications Pte Ltd",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=GRID_Communications&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 1,
    "Operator": "Circles.Life Using M1",
    "Technology": "900 2100 MHz UMTS HSPA HSPA 1800 B3 2600 B7 900 B8 MHz LTE A VoLTE eSIM Available",
    "Subscribers": "0.26 End 2018",
    "Owner": "Liberty Wireless Pte Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Circles.Life"
  },
  {
    "Country": "Singapore",
    "Rank": 2,
    "Operator": "Zero1 Using Singtel",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 900 B8 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "Zero1",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Zero1_(Singapore)&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 3,
    "Operator": "MyRepublic Using StarHub Using M1 w.e.f. 31 March 2020 for new signups",
    "Technology": "StarHub 900 2100 MHz UMTS HSPA HSPA DC HSPA 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A M1 900 2100 MHz UMTS HSPA HSPA 1800 B3 2600 B7 900 B8 MHz LTE A VoLTE",
    "Subscribers": "0.07 May2021",
    "Owner": "MyRepublic",
    "OperatorLink": "https://www.wikipedia.org/wiki/MyRepublic"
  },
  {
    "Country": "Singapore",
    "Rank": 4,
    "Operator": "redONE Using StarHub",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "Redtone International",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=RedONE&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 5,
    "Operator": "VIVIFI Using Singtel",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 900 B8 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "ICYMI Pte Ltd",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=VIVIFI&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 6,
    "Operator": "Geenet Mobile Using M1",
    "Technology": "900 2100 MHz UMTS HSPA HSPA 1800 B3 2600 B7 900 B8 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "Geenet Pte Ltd",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Geenet&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 7,
    "Operator": "China Mobile CMLink SG Using Singtel",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 900 B8 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "China Mobile International",
    "OperatorLink": "https://www.wikipedia.org/wiki/China_Mobile"
  },
  {
    "Country": "Singapore",
    "Rank": 8,
    "Operator": "ZYM Using Singtel",
    "Technology": "900 2100 MHz UMTS HSPA HSPA DC HSPA 900 B8 1800 B3 2600 B7 2100 B1 2600 B38 MHz LTE A",
    "Subscribers": "NA",
    "Owner": "mDR Limited",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=ZYM&action=edit&redlink=1"
  },
  {
    "Country": "Singapore",
    "Rank": 9,
    "Operator": "Changi Mobile Using M1",
    "Technology": "900 2100 MHz UMTS HSPA HSPA 1800 B3 2600 B7 900 B8 MHz LTE A VoLTE VoWiFi",
    "Subscribers": "NA",
    "Owner": "Changi Recommends",
    "OperatorLink": "https://www.wikipedia.org/wiki/MobileOne"
  },
  {
    "Country": "Solomon Islands",
    "Rank": 1,
    "Operator": "Our Telekom",
    "Technology": "900 1800 MHz GSM850 2100 MHz UMTS HSDPALTE",
    "Subscribers": "0.03 2008",
    "Owner": "Solomon Telekom Company Limited",
    "OperatorLink": "https://www.ourtelekom.com.sb/"
  },
  {
    "Country": "South Korea",
    "Rank": 1,
    "Operator": "SK Telecom",
    "Technology": "2G none shut down since 2020 3G 2100 MHz UMTS4G 850 1800 2100 2600 MHz LTE FDD LTE A Pro with VoLTE5G n78 Sub 6 n257 mmWave",
    "Subscribers": "27.019 Q4 2017",
    "Owner": "SK Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/SK_Telecom"
  },
  {
    "Country": "South Korea",
    "Rank": 2,
    "Operator": "KT formerly olleh KTF",
    "Technology": "2G none shut down since 2012 3G 2100 MHz UMTS4G 900 1800 2100 MHz LTE FDD LTE A Pro with VoLTE5G n78 Sub 6 n257 mmWave",
    "Subscribers": "16.493 Q4 2017",
    "Owner": "KT Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/KT_Corporation"
  },
  {
    "Country": "South Korea",
    "Rank": 3,
    "Operator": "LG U formerly LG Telecom",
    "Technology": "2G CDMA 1X3G CDMA EVDO Rev B4G 850 2100 2600 MHz LTE FDD with VoLTE5G n78 Sub 6",
    "Subscribers": "12.625 Q4 2017",
    "Owner": "LG Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/LG_Uplus"
  },
  {
    "Country": "Sri Lanka",
    "Rank": "#cite_note-SL_Frequencies-147",
    "Operator": "/wiki/Mobile_country_code",
    "Technology": "",
    "Subscribers": "",
    "Owner": "",
    "OperatorLink": ""
  },
  {
    "Country": "Sri Lanka",
    "Rank": 1,
    "Operator": "Dialog Formerly MTN Network",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 2100 MHz UMTS HSPA HSPA DC HSPA 4G 1800 B3 MHz LTE FDD4G Calling VoLTE Supported5G 3.5 n78 GHz 5G NR Trial Wi Fi Calling VoWiFi SupportedeSIM Available",
    "Subscribers": "17.1 Q3 2021",
    "Owner": "Axiata Group Berhad 83.32%",
    "OperatorLink": ""
  },
  {
    "Country": "Sri Lanka",
    "Rank": 2,
    "Operator": "SLTMobitel Formerly Mobitel",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 2100 MHz UMTS HSPA HSPA DC HSPA 4G 850 B5 1800 B3 MHz LTE FDD4.5G 900 B8 1800 B3 2100 B1 MHz LTE A4G Calling VoLTE Supported 5G 3.5 n78 GHz 5G NR Trial Wi Fi Calling VoWiFi Supported eSIM Available",
    "Subscribers": "7.9 Q4 2020",
    "Owner": "Sri Lanka Telecom PLC 100%",
    "OperatorLink": ""
  },
  {
    "Country": "Sri Lanka",
    "Rank": 3,
    "Operator": "Hutch Includes the previous Etisalat network.",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 2100 MHz UMTS HSPA HSPA DC HSPA 4G 900 B8 1800 B3 MHz LTE FDD 4G Calling VoLTE Supported 5G 3.5 n78 GHz 5G NR Trial Wi Fi Calling VoWiFi Not supported eSIM Not available",
    "Subscribers": "5.3 Q3 2018",
    "Owner": "CK Hutchison Holdings Limited 85% Emirates Telecommunication Group Company PJSC 15%",
    "OperatorLink": ""
  },
  {
    "Country": "Sri Lanka",
    "Rank": 4,
    "Operator": "Airtel",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 2100 MHz UMTS HSPA Planned to discontinue 3G services 4G 850 B5 2100 B1 2500 B41 MHz LTE4G Calling VoLTE SupportedWi Fi Calling VoWiFi Not supported eSIM Not available",
    "Subscribers": "2.9 Q1 2020",
    "Owner": "Bharti Airtel Limited 100%",
    "OperatorLink": ""
  },
  {
    "Country": "Taiwan",
    "Rank": 1,
    "Operator": "Chunghwa Telecom",
    "Technology": "3G 2100 900 MHz UMTS HSPA DC HSPA 4G 2100 B1 1800 B3 2600 B7 900 B8 MHz LTE4.5G Up to 5CA 4x4 MIMO 256 QAM LTE A Pro5G 2100 n1 3500 n78 28000 n257 MHz 5G NR NSA LTE U LAA VoLTE EVS VoWiFi eSIM",
    "Subscribers": "11.38 March 2021",
    "Owner": "Republic of China Ministry of Transportation and Communications 35.29%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Chunghwa_Telecom"
  },
  {
    "Country": "Taiwan",
    "Rank": 2,
    "Operator": "Taiwan Mobile",
    "Technology": "3G 2100 MHz UMTS HSPA DC HSPA 4G 2100 B1 1800 B3 700 B28 MHz LTE4.5G Up to 3CA 4x4 MIMO 256 QAM LTE A Pro5G 700 n28 3500 n78 28000 n257 MHz 5G NR NSA VoLTE VoWiFi eSIM",
    "Subscribers": "7.094 March 2021",
    "Owner": "Fubon Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Taiwan_Mobile"
  },
  {
    "Country": "Taiwan",
    "Rank": 3,
    "Operator": "Far EasTone",
    "Technology": "3G 2100 MHz UMTS HSPA 4G 2100 B1 1800 B3 2600 B7 700 B28 2600 B38 MHz LTE4.5G Up to 4CA 4x4 MIMO 256 QAM LTE A Pro5G 2100 n1 3500 n78 28000 n257 MHz 5G NR NSA LTE U LAA VoLTE VoWiFi eSIM 5G 3.5 GHz sharing with Asia Pacific Telecom",
    "Subscribers": "7.066 March 2021",
    "Owner": "Far Eastern Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Far_EasTone"
  },
  {
    "Country": "Taiwan",
    "Rank": 4,
    "Operator": "T STAR",
    "Technology": "3G 2100 MHz UMTS HSDPA4G 2100 B1 2600 B7 900 B8 MHz LTE4.5G Up to 2CA 4x4 MIMO 256 QAM LTE A5G 3500 n78 MHz 5G NR NSA VoLTE eSIM",
    "Subscribers": "2.597 March 2021",
    "Owner": "Ting Hsin International Group 63%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Taiwan_Star_Telecom"
  },
  {
    "Country": "Taiwan",
    "Rank": 5,
    "Operator": "GT",
    "Technology": "2G 900 MHz GSM4G 900 B8 700 B28 2600 B38 MHz LTE4.5G Up to 2CA 4x4 MIMO 256 QAM LTE A5G 3500 n78 28000 n257 MHz 5G NR NSA VoLTE VoWiFi eSIM 5G 3.5 GHz using FarEasTone s network",
    "Subscribers": "2.049 March 2021",
    "Owner": "Foxconn 14% FarEasTone 11.58%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Taiwan",
    "Rank": 1,
    "Operator": "Circles.Life Using Chunghwa",
    "Technology": "2100 MHz UMTS HSPA DC HSPA 2100 1 900 8 1800 3 2600 B7 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Wireless Pte Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Circles.Life"
  },
  {
    "Country": "Taiwan",
    "Rank": 2,
    "Operator": "Ibon Mobile Using FarEasTone",
    "Technology": "2100 MHz UMTS HSPA 2100 1 1800 3 2600 7 700 28 2600 38 MHz LTE",
    "Subscribers": "NA",
    "Owner": "7 Eleven",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Ibon_Mobile&action=edit&redlink=1"
  },
  {
    "Country": "Tajikistan",
    "Rank": 1,
    "Operator": "Tcell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 MHz LTE",
    "Subscribers": "3.301 2013",
    "Owner": "Aga Khan Fund for Economic Development 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tcell"
  },
  {
    "Country": "Tajikistan",
    "Rank": 2,
    "Operator": "Babilon Mobile",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 2100 MHz LTE",
    "Subscribers": "0.8 March 2008",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Babilon-Mobile&action=edit&redlink=1"
  },
  {
    "Country": "Tajikistan",
    "Rank": 3,
    "Operator": "ZET Mobile Formerly Beeline",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.836 May 2011",
    "Owner": "ZET Mobile Ltd. 100%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=ZET_Mobile&action=edit&redlink=1"
  },
  {
    "Country": "Tajikistan",
    "Rank": 4,
    "Operator": "MegaFon",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA",
    "Subscribers": "0.517 May 2011",
    "Owner": "MegaFon 75%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MegaFon#Tajikistan"
  },
  {
    "Country": "Thailand",
    "Rank": 1,
    "Operator": "AIS AWN Advance Info Service Advance Wireless Network",
    "Technology": "2G GSM 900 MHz3G UMTS WCDMA B1 B84G FDD LTE B1 B3 B8 B28 TDD LTE B41 B384.5G LTE A Pro Up to 4xCA B1 B1 B3 B28 4x4 MIMO 256 64QAM ULCA4G Calling VoLTE ViLTE Supported Wi Fi Calling VoWiFi Native device support and via WiFi calling app 5G NR NSA ENDC 3x NR SA FR1 N28 N41 FR2 N258 B1 B3 B8 N28 B41 2100 1800 900 700 2500 2600 MHz respectively Pseudo MVNO on 80% of TOT s 3G UMTS WCDMA B1 B1 2100 MHz capacity",
    "Subscribers": "43.65 Q3 2021",
    "Owner": "Intouch Holdings",
    "OperatorLink": "https://www.wikipedia.org/wiki/Advanced_Info_Service"
  },
  {
    "Country": "Thailand",
    "Rank": 2,
    "Operator": "TrueMove H 2001 2005 TA Orange or Orange TH 2005 2012 True Move 2012 now True Move H rebrand after buy CDMA network provider on 850 mHz for the 850 mHz spectrum license s . Hutchison CAT wireless multimedia",
    "Technology": "2G GSM 900 MHz3G UMTS WCDMA B1 B5 4G FDD LTE B1 B3 B8 TDD LTE B41 4.5G LTE A Pro Up to 3xCA B1 B3 B8 nationwide 4x4MIMO 256 64QAM ULCA4G Calling VoLTE ViLTE Supported Wi Fi Calling VoWiFi Native device support and via WiFi calling app 5G NR NSA EN DC 3x FR1 N28 N41 FR2 N258 B1 B3 B5 B8 N28 B41 2100 1800 850 900 700 2500 2600 MHz respectively Pseudo MVNO on 80% of CAT Telecom s 3G UMTS WCDMA B5 capacity",
    "Subscribers": "32.0 Q3 2021",
    "Owner": "True CorporationChina Mobile 18%",
    "OperatorLink": "https://www.wikipedia.org/wiki/True_Corporation"
  },
  {
    "Country": "Thailand",
    "Rank": 3,
    "Operator": "Dtac or dtac Total Access Communication a member of Telenor group dtac T dtac Turbo or dtac TOT this is the co resellers brands with the government s mobile operators TOT Mobile on 2300 mHz spectrums. dtac Trinet Network",
    "Technology": "2G GSM 1800 MHz3G UMTS WCDMA B1 B5 B84G FDD LTE B1 B3 B28 TDD LTE B40 4.5G LTE A Pro Up to 3xCA on B40 4x4MIMO 256 64QAM ULCA4G Calling VoLTE ViLTE Supported Wi Fi Calling VoWiFi Native device support and via WiFi calling app 5G NR NSA EN DC 3x FR1 N28 FR2 N258 B1 B3 B5 B8 B28 B40 2100 1800 850 900 700 2300 MHz respectively Pseudo MVNO on 60% of TOT s 4G TDD LTE B40 B40 2300 MHz capacity",
    "Subscribers": "19.3 Q3 2021",
    "Owner": "Telenor 42.61% Thai Telco Holdings 15.03% Telephone Organization of Thailand TOT 5.58%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Dtac"
  },
  {
    "Country": "Thailand",
    "Rank": 4,
    "Operator": "MY",
    "Technology": "3G UMTS WCDMA B54G FDD LTE B1 B3 Roaming on Truemove H B8 prohibited 4.5G Up to 2xCA B1 B3 nationwide 4x4 MIMO on B3 256 64QAM 4G Calling VoLTE ViLTE Unsupported 5G NR Low band N28 Wi Fi Calling VoWiFi No B1 B3 B5 2100 1800 850 MHz respectively",
    "Subscribers": "2.8 EOY 2020",
    "Owner": "CAT Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 5,
    "Operator": "TOT",
    "Technology": "3G UMTS WCDMA B1 4G FDD LTE B1 B1 deployed on AIS infrastructure. TDD LTE B40 B40 2300 MHz 4.5G LTE A Pro Up to 3xCA on B40 4x4MIMO 256 64QAM Using DTN s infrastructure. 5G 5G NSA mmWave N258",
    "Subscribers": "0.533 EOY 2020",
    "Owner": "TOT Public Company Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 1,
    "Operator": "IEC3G Buzzme Host operator TOT",
    "Technology": "3G UMTS WCDMA B1",
    "Subscribers": "Closed January 2019",
    "Owner": "Mobile 8 Telco Sdn Bhd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 2,
    "Operator": "i Kool 3G Host operator TOT",
    "Technology": "3G UMTS WCDMA B1",
    "Subscribers": 0.002251,
    "Owner": "Loxley Public Company Limited.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 3,
    "Operator": "MyWorld 3G Host operator CAT",
    "Technology": "3G UMTS WCDMA B5",
    "Subscribers": "Closed",
    "Owner": "Data CDMA Communication Co. Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 4,
    "Operator": "Penguin Host operator CAT TOT",
    "Technology": "3G UMTS WCDMA B1 B5",
    "Subscribers": 0.02969,
    "Owner": "The WhiteSpace Co.Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 5,
    "Operator": "OPEN SIM i mobile Host operator CAT",
    "Technology": "3G UMTS WCDMA B5",
    "Subscribers": "Closed June 2017",
    "Owner": "Samart Corporation Public Company Limited.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 6,
    "Operator": "imobile 3GX Host operator CAT",
    "Technology": "3G UMTS WCDMA B5",
    "Subscribers": "Closed June 2017",
    "Owner": "Samart Corporation Public Company Limited.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 7,
    "Operator": "168 Host operator CAT",
    "Technology": "3G UMTS WCDMA B5",
    "Subscribers": "Closed 2018",
    "Owner": "168 Communication Co Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/CAT_Telecom"
  },
  {
    "Country": "Thailand",
    "Rank": 8,
    "Operator": "Mojo 3G Host operator TOT",
    "Technology": "3G UMTS WCDMA B1",
    "Subscribers": "Closed January 2017",
    "Owner": "MConzult Asia Co. Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 9,
    "Operator": "Tune Talk Host operator TOT",
    "Technology": "3G UMTS WCDMA B1 2100 MHz",
    "Subscribers": "Closed",
    "Owner": "Loxley Public Company Limited 50% Tune Group Sdn Bhd 10% Thai AirAsia Co. Ltd. 40%",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 10,
    "Operator": "Feels Host operator TOT",
    "Technology": "3G UMTS WCDMA B1 2100 MHz 4G LTE FDD B1 2100 MHz 4.5G TDD LTE B40 2300 MHz",
    "Subscribers": "0.004574 EOY 2020",
    "Owner": "Feels Telecom Corporation Co. Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 11,
    "Operator": "AJ SIM Host operator TOT",
    "Technology": "3G UMTS WCDMA B1 2100 MHz 4G LTE FDD B1 2100 MHz 4.5G TDD LTE B40 2300 MHz",
    "Subscribers": "Launched November 2020",
    "Owner": "AJ Advance Technology Pcl.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Thailand",
    "Rank": 12,
    "Operator": "Redone Host operator TOT",
    "Technology": "3G UMTS WCDMA B1 2100 MHz 4G LTE FDD B1 2100 MHz 4.5G TDD LTE B40 2300 MHz",
    "Subscribers": "Launched May 2021",
    "Owner": "Red One Network Thailand Co. Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/TOT_(Thailand)"
  },
  {
    "Country": "Timor-Leste",
    "Rank": 1,
    "Operator": "Telemor",
    "Technology": "GSM 850 UMTS LTE",
    "Subscribers": "0.726913 End 2017",
    "Owner": "Viettel",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telemor&action=edit&redlink=1"
  },
  {
    "Country": "Timor-Leste",
    "Rank": 2,
    "Operator": "Timor Telecom",
    "Technology": "GSM 900 UMTS LTE",
    "Subscribers": "0.620204 Q2 2015",
    "Owner": "Timor Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Timor_Telecom"
  },
  {
    "Country": "Timor-Leste",
    "Rank": 3,
    "Operator": "Telkomcel",
    "Technology": "GSM 850 850 MHz UMTS LTE",
    "Subscribers": "0.16 Mid 2015",
    "Owner": "Telekomunikasi Indonesia International TL S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telkomcel"
  },
  {
    "Country": "Tonga",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "900 MHz GSM GPRS1800 MHz LTE",
    "Subscribers": "0.020 November 2007",
    "Owner": "Digicel Group Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Tonga",
    "Rank": 2,
    "Operator": "TCC",
    "Technology": "900 MHz GSM GPRSUMTS",
    "Subscribers": "0.010 November 2007",
    "Owner": "Tonga Government",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Tonga_Communications_Corporation&action=edit&redlink=1"
  },
  {
    "Country": "Turkmenistan",
    "Rank": 1,
    "Operator": "TM CELL Altyn Asyr",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA LTE",
    "Subscribers": "3 October 2012",
    "Owner": "TM CELL",
    "OperatorLink": "https://www.wikipedia.org/wiki/Altyn_Asyr_(mobile_operator)"
  },
  {
    "Country": "Turkmenistan",
    "Rank": 2,
    "Operator": "A TU",
    "Technology": "CDMA ETS 450 D",
    "Subscribers": "0.055 August 2018",
    "Owner": "Ashgabat City Telephone Network",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ashgabat_City_Telephone_Network"
  },
  {
    "Country": "Tuvalu",
    "Rank": 1,
    "Operator": "TTC",
    "Technology": "900 MHz GSMLTE",
    "Subscribers": "0.002 2008",
    "Owner": "TTC",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tuvalu_Telecommunications_Corporation"
  },
  {
    "Country": "Uzbekistan",
    "Rank": 1,
    "Operator": "UCell Formerly Coscom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 700 2600 MHz LTE",
    "Subscribers": "7.6 November 2010",
    "Owner": "State Competition Committee of Uzbekistan",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telia_(company)#Uzbekistan"
  },
  {
    "Country": "Uzbekistan",
    "Rank": 2,
    "Operator": "Beeline",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 2600 MHz LTE",
    "Subscribers": "7.1 May 2011",
    "Owner": "VEON",
    "OperatorLink": "https://www.wikipedia.org/wiki/VimpelCom"
  },
  {
    "Country": "Uzbekistan",
    "Rank": 3,
    "Operator": "Mobi.UZ Formerly UMS",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 MHz LTE",
    "Subscribers": "0.63 June 2015",
    "Owner": "Universal Mobile Systems",
    "OperatorLink": "https://www.wikipedia.org/wiki/Universal_Mobile_Systems"
  },
  {
    "Country": "Uzbekistan",
    "Rank": 4,
    "Operator": "Uzmobile",
    "Technology": "CdmaOne",
    "Subscribers": "Not available",
    "Owner": "Uzbektelecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Uzbektelecom&action=edit&redlink=1"
  },
  {
    "Country": "Uzbekistan",
    "Rank": 5,
    "Operator": "Perfectum",
    "Technology": "CdmaOne",
    "Subscribers": "NA",
    "Owner": "Perfectum",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Perfectum&action=edit&redlink=1"
  },
  {
    "Country": "Vanuatu",
    "Rank": 1,
    "Operator": "Telecom Vanuatu",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.01 citation needed as of",
    "Owner": "Telecom Vanuatu Ltd",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telecom_Vanuatu&action=edit&redlink=1"
  },
  {
    "Country": "Vanuatu",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 MHz GPRS EDGE 900 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "0.01 citation needed as of",
    "Owner": "Digicel Group Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Vanuatu",
    "Rank": 3,
    "Operator": "AIL",
    "Technology": "GSM 900 MHz",
    "Subscribers": "NA",
    "Owner": "ACeS International",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Vanuatu",
    "Rank": 4,
    "Operator": "WanTok",
    "Technology": "2300 MHz TDD LTE",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=WanTok&action=edit&redlink=1"
  },
  {
    "Country": "Vietnam",
    "Rank": 1,
    "Operator": "Viettel Mobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 1900 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "36.72 May 2012",
    "Owner": "Viettel Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Viettel_Mobile"
  },
  {
    "Country": "Vietnam",
    "Rank": 2,
    "Operator": "MobiFone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS 1800 MHz LTE",
    "Subscribers": "29.11 May 2012",
    "Owner": "VMS VNPT subsidiary",
    "OperatorLink": "https://www.wikipedia.org/wiki/MobiFone"
  },
  {
    "Country": "Vietnam",
    "Rank": 3,
    "Operator": "Vinaphone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "28.71 May 2012",
    "Owner": "GPC VNPT subsidiary",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vinaphone"
  },
  {
    "Country": "Vietnam",
    "Rank": 4,
    "Operator": "Vietnamobile",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "10.0 May 2012",
    "Owner": "Hanoi Telecom Hutchison Asia Telecommunications Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vietnamobile"
  },
  {
    "Country": "Vietnam",
    "Rank": 5,
    "Operator": "Gmobile formerly Beeline",
    "Technology": "GSM 1800 MHz",
    "Subscribers": "2 May 2010",
    "Owner": "GTEL Mobile Joint Stock Company",
    "OperatorLink": "https://www.wikipedia.org/wiki/Gmobile"
  },
  {
    "Country": "Algeria",
    "Rank": 1,
    "Operator": "Mobilis",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 MHz LTE",
    "Subscribers": "18.6 2014",
    "Owner": "Alg rie T l com",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobilis_(Algeria)"
  },
  {
    "Country": "Algeria",
    "Rank": 2,
    "Operator": "Djezzy",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 MHz LTE",
    "Subscribers": "13.9 2014",
    "Owner": "State owned 51% Global Telecom Holding VEON 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Djezzy"
  },
  {
    "Country": "Algeria",
    "Rank": 3,
    "Operator": "Ooredoo formerly Nedjma",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA HSPA 1800 MHz LTE",
    "Subscribers": "12.9 2014",
    "Owner": "Ooredoo 74.4%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo_Algeria"
  },
  {
    "Country": "Angola",
    "Rank": 1,
    "Operator": "Unitel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA 2100 MHz LTE",
    "Subscribers": "7 November 2011",
    "Owner": "Mercury Sonangol group 25% Group GENI 25% Vidatel 25% Portugal Telecom 25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Unitel_(Angola)"
  },
  {
    "Country": "Angola",
    "Rank": 2,
    "Operator": "Movicel",
    "Technology": "GSM GPRS EDGE 900 MHz UMTS HSDPA 1800 MHz LTE",
    "Subscribers": "2.5 September 2010",
    "Owner": "Government Angola Telecom 18% Correios Telegrafos de Angola 2% Porturil 40% Modus Comicare 19% Ipang 10% Lambda 6%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movicel"
  },
  {
    "Country": "Bahrain",
    "Rank": 1,
    "Operator": "Batelco",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE",
    "Subscribers": "0.738 Dec 2011",
    "Owner": "Bahrain Telecommunications Company",
    "OperatorLink": "https://www.wikipedia.org/wiki/Batelco"
  },
  {
    "Country": "Bahrain",
    "Rank": 2,
    "Operator": "Zain formerly MTC Vodafone",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA1800 MHz LTEWiMAX",
    "Subscribers": "0.787 Sep 2014",
    "Owner": "Zain Group 56.25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Bahrain",
    "Rank": 3,
    "Operator": "stc",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "STC",
    "OperatorLink": "https://www.wikipedia.org/wiki/Saudi_Telecom_Company"
  },
  {
    "Country": "Benin",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 MHz UMTS DC HSPA LTE",
    "Subscribers": "4.3 Dec 2015",
    "Owner": "MTN 75%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Benin",
    "Rank": 2,
    "Operator": "Moov",
    "Technology": "GSM 900 MHzUMTS DC HSPA LTE",
    "Subscribers": "3.3 Dec 2015",
    "Owner": "Etisalat",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Benin",
    "Rank": 3,
    "Operator": "Glo",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "1.7 Dec 2015",
    "Owner": "Globacom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Globacom"
  },
  {
    "Country": "Benin",
    "Rank": 4,
    "Operator": "Libercom",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.056 Dec 2015",
    "Owner": "Benin Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Benin",
    "Rank": "",
    "Operator": "BBcom",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "ND Dec 2015",
    "Owner": "Bell Benin Communications",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=BBcom&action=edit&redlink=1"
  },
  {
    "Country": "Botswana",
    "Rank": 1,
    "Operator": "MASCOM",
    "Technology": "GSM 9002100 MHz UMTS HSPA LTE",
    "Subscribers": "1.7 March 2015",
    "Owner": "Mascom Wireless Pty Limited MTN 53%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MASCOM"
  },
  {
    "Country": "Botswana",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 9002100 MHz UMTS HSDPA LTE",
    "Subscribers": "1.1 March 2015",
    "Owner": "Orange S.A. 69% Mosokelatsebeng Cellular local investors 26% local individuals 5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Botswana",
    "Rank": 3,
    "Operator": "BeMobile",
    "Technology": "GSM 900 1800UMTS",
    "Subscribers": "0.52 March 2015",
    "Owner": "BTC Botswana Telecommunications Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Botswana_Telecommunications_Corporation"
  },
  {
    "Country": "Burkina Faso",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "4.1 2012",
    "Owner": "Orange",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Burkina Faso",
    "Rank": 2,
    "Operator": "Telmob Onatel",
    "Technology": "CDMA2000 EVDO GSM 900 MHz GPRS EDGE 2100 MHz UMTS DC HSPA",
    "Subscribers": "3.9 2012",
    "Owner": "Maroc Telecom 51%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Onatel&action=edit&redlink=1"
  },
  {
    "Country": "Burkina Faso",
    "Rank": 3,
    "Operator": "Telecel Faso",
    "Technology": "GSM 900 MHz GPRS EDGE",
    "Subscribers": "2 2012",
    "Owner": "Planor Afrique",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telecel_Faso&action=edit&redlink=1"
  },
  {
    "Country": "Burundi",
    "Rank": 1,
    "Operator": "Econet Leo",
    "Technology": "CDMAGSM 900 1800 MHz2100 MHz UMTS LTE",
    "Subscribers": "2.6 July 2015",
    "Owner": "Econet Wireless purchased Leo from Vimpelcom",
    "OperatorLink": "https://www.wikipedia.org/wiki/CDMA"
  },
  {
    "Country": "Burundi",
    "Rank": 2,
    "Operator": "Lumitel Viettel Burundi",
    "Technology": "GSMUMTS4G LTE",
    "Subscribers": 1.8,
    "Owner": "Viettel Group Vietnam",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Burundi",
    "Rank": 3,
    "Operator": "ONAMOB",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.140 November 2008 citation needed",
    "Owner": "Onatel state owned",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=ONAMOB&action=edit&redlink=1"
  },
  {
    "Country": "Burundi",
    "Rank": 4,
    "Operator": "Smart Mobile formerly Lacell",
    "Technology": "GSM 1800 MHzUMTS",
    "Subscribers": "NA",
    "Owner": "Smart Telecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Lacell&action=edit&redlink=1"
  },
  {
    "Country": "Cabo Verde",
    "Rank": 1,
    "Operator": "CV M vel",
    "Technology": "GSM 900 1800 MHz UMTS",
    "Subscribers": "0.355 September 2009",
    "Owner": "Portugal Telecom 40%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=CV_M%C3%B3vel&action=edit&redlink=1"
  },
  {
    "Country": "Cabo Verde",
    "Rank": 2,
    "Operator": "T MAIS",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "0.047 Q1 2010",
    "Owner": "T Mais",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=T-MAIS&action=edit&redlink=1"
  },
  {
    "Country": "Cameroon",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM MHz 900 LTE",
    "Subscribers": "10.1 dec 2014",
    "Owner": "MTN Group 70%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Cameroon",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 MHz LTE",
    "Subscribers": "6.2 dec 2014",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Cameroon",
    "Rank": 3,
    "Operator": "Nexttel",
    "Technology": "GSM 900 1800 MHzUMTS",
    "Subscribers": "2.0 May 2015",
    "Owner": "Viettel Global Bestinver Cameroon S.A.R.L",
    "OperatorLink": "https://www.wikipedia.org/wiki/Viettel_Mobile"
  },
  {
    "Country": "Cameroon",
    "Rank": 4,
    "Operator": "Camtel",
    "Technology": "GSM",
    "Subscribers": "new licence in September 2014",
    "Owner": "Camtel government",
    "OperatorLink": "https://www.wikipedia.org/wiki/Camtel"
  },
  {
    "Country": "Cameroon",
    "Rank": 5,
    "Operator": "YooMee",
    "Technology": "WiMAX",
    "Subscribers": "Not yet available",
    "Owner": "YooMee",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=YooMee&action=edit&redlink=1"
  },
  {
    "Country": "Central African Republic",
    "Rank": 1,
    "Operator": "Telecel Centrafrique",
    "Technology": "GSM 900",
    "Subscribers": "0.143 December 2008",
    "Owner": "Orascom Telecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Telecel_Centrafrique&action=edit&redlink=1"
  },
  {
    "Country": "Central African Republic",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 1800 GPRS 2100 MHz UMTS HSPA",
    "Subscribers": "0.189 December 2009",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Central African Republic",
    "Rank": 3,
    "Operator": "Nationlink",
    "Technology": "GSM 900",
    "Subscribers": "0.158 December 2008",
    "Owner": "Bintel",
    "OperatorLink": "https://www.wikipedia.org/wiki/NationLink_Telecom"
  },
  {
    "Country": "Central African Republic",
    "Rank": 4,
    "Operator": "Moov",
    "Technology": "GSM 900",
    "Subscribers": "NA",
    "Owner": "Maroc Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Chad",
    "Rank": 1,
    "Operator": "Moov",
    "Technology": "GSM 900 1800 MHz GPRS EDGE. 3G HSPA Plus 2100 MHz 4G LTE 10 MHz",
    "Subscribers": "1.257 October 2010",
    "Owner": "Maroc Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Moov"
  },
  {
    "Country": "Chad",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "1.199 June 2009",
    "Owner": "Bharti Airtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Comoros",
    "Rank": 1,
    "Operator": "Comores Telecom Comtel",
    "Technology": "GSM 900 UMTS HSPA HSPA LTE",
    "Subscribers": "0.056 2007",
    "Owner": "government Societe Nationale des Telecommunications",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Comores_Telecom&action=edit&redlink=1"
  },
  {
    "Country": "Comoros",
    "Rank": 2,
    "Operator": "Comoro Gulf Holding",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "90% Comoro Gulf Holding 10% government",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Congo",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 MHzUMTS HSPA HSPA LTE",
    "Subscribers": "2.250 Q4 2015",
    "Owner": "MTN",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_Telephone_Networks"
  },
  {
    "Country": "Congo",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 MHz2100 MHz UMTS HSPA LTE",
    "Subscribers": "1.851 Q4 2015",
    "Owner": "Bharti Airtel 90%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Congo",
    "Rank": 3,
    "Operator": "Azur",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "0.313 Q4 2015",
    "Owner": "Bintel",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Azur_Congo&action=edit&redlink=1"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "13.531 Sep 2017",
    "Owner": "Orange S.A. 85% Comafrique 15%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": 2,
    "Operator": "MTN",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "11.173 Sep 2017",
    "Owner": "MTN 64.7% Planor 25.3% Teylium group 10%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": 3,
    "Operator": "Moov",
    "Technology": "GSM GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "7.587 Sep 2017",
    "Owner": "Etisalat",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": 4,
    "Operator": "Koz",
    "Technology": "GSM 900 1800",
    "Subscribers": "2.500 April 2010",
    "Owner": "Comium licence revoqued",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": 5,
    "Operator": "GreenN",
    "Technology": "GSM 900 1800",
    "Subscribers": "0.617 Q1 2010 citation needed",
    "Owner": "LAP Oricel licence revoqued",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=GreenN&action=edit&redlink=1"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": "",
    "Operator": "Warid",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "Warid Telecom licence revoqued",
    "OperatorLink": "https://www.wikipedia.org/wiki/Warid_Telecom"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": "",
    "Operator": "Mobile Cafe",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "Aircomm licence revoqued",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Mobile_Cafe&action=edit&redlink=1"
  },
  {
    "Country": "Cte d'Ivoire",
    "Rank": "",
    "Operator": "YooMee",
    "Technology": "TD LTE",
    "Subscribers": "NA",
    "Owner": "YooMee",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=YooMee&action=edit&redlink=1"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 1,
    "Operator": "Vodacom",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA WiMAX",
    "Subscribers": "13.766 Mar 2014",
    "Owner": "Vodacom 51% Congolese Wireless Network 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodacom"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "10.674 Q3 2015",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_RDC"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": "",
    "Operator": "Tigo Orange",
    "Technology": "GSM GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "6.5 Q1 2016",
    "Owner": "MIC acquired by Orange in Feb 2016 subject to Regulator approval",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 3,
    "Operator": "Africell",
    "Technology": "GSM UMTS",
    "Subscribers": "5.5 Dec 2014",
    "Owner": "Africell Holding",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 4,
    "Operator": "Airtel",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "5.0 Aug 2012",
    "Owner": "Bharti Airtel 98.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 5,
    "Operator": "Supercell",
    "Technology": "GSM 900",
    "Subscribers": "0.028 covers Kivu only",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 6,
    "Operator": "SemaTel",
    "Technology": "GSM",
    "Subscribers": "not started",
    "Owner": "Hits Telecom 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": 7,
    "Operator": "U Com",
    "Technology": "CDMA",
    "Subscribers": "not started",
    "Owner": "Global Vision Telecom Telecel International",
    "OperatorLink": "https://www.wikipedia.org/wiki/CDMA"
  },
  {
    "Country": "Democratic Republic of the Congo",
    "Rank": "",
    "Operator": "Smile",
    "Technology": "LTE",
    "Subscribers": "launch expected in 2016",
    "Owner": "Smile Communications Al Nahla Technology Atheeb Trading Company Capitalworks Verene",
    "OperatorLink": "https://www.wikipedia.org/wiki/LTE_(telecommunication)"
  },
  {
    "Country": "Djibouti",
    "Rank": 1,
    "Operator": "Evatis",
    "Technology": "GSM 900 MHzUMTS HSPA",
    "Subscribers": "0.106 Q1 2010 citation needed",
    "Owner": "Djibouti Telecom government",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Evatis&action=edit&redlink=1"
  },
  {
    "Country": "Egypt",
    "Rank": 1,
    "Operator": "Vodafone",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA LTE",
    "Subscribers": "39.951 July 2020",
    "Owner": "Vodafone Group plc 54.93% Telecom Egypt 44.94% Free Float 0.13%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Egypt"
  },
  {
    "Country": "Egypt",
    "Rank": 2,
    "Operator": "Orange formerly Mobinil",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA LTE",
    "Subscribers": "27.601 July 2020",
    "Owner": "Orange S.A. 99.39% Free Float 0.61%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_Egypt"
  },
  {
    "Country": "Egypt",
    "Rank": 3,
    "Operator": "Etisalat",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA LTE",
    "Subscribers": "26.0 July 2020",
    "Owner": "Etisalat 76% Egypt Post 20% Other Investors 4%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Etisalat_Egypt"
  },
  {
    "Country": "Egypt",
    "Rank": 4,
    "Operator": "We by Telecom Egypt",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA",
    "Subscribers": "1.6 December 2017",
    "Owner": "Egyptian government 80% Free Float 20%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telecom_Egypt"
  },
  {
    "Country": "Equatorial Guinea",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.333 Dec 2009",
    "Owner": "Orange S.A. 40% Equatorial Guinea state 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Equatorial Guinea",
    "Rank": 2,
    "Operator": "Muni",
    "Technology": "GSM 900 1800 MHz UMTS",
    "Subscribers": "0.150 September 2013 citation needed",
    "Owner": "Green com S.A. Hits Africa",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Equatorial Guinea",
    "Rank": 3,
    "Operator": "Gecomsa",
    "Technology": "",
    "Subscribers": "not yet available created in 2012",
    "Owner": "Equatorial Guinea state 51% China state 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Eritrea",
    "Rank": 1,
    "Operator": "Eritel",
    "Technology": "GSM 900 MHz GPRS",
    "Subscribers": "NA",
    "Owner": "Eritrea Telecommunications Services Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Eritel"
  },
  {
    "Country": "Ethiopia",
    "Rank": 1,
    "Operator": "Ethiotelecom",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA LTE",
    "Subscribers": 26.2,
    "Owner": "Ethiopian Telecommunications Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ethiopian_Telecommunications_Corporation"
  },
  {
    "Country": "Gabon",
    "Rank": 1,
    "Operator": "Airtel formerly Celtel Zain",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "1.181 June 2018",
    "Owner": "Bharti Airtel 90%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Gabon",
    "Rank": 2,
    "Operator": "Gabon Telecom",
    "Technology": "GSM 900 MHz",
    "Subscribers": "1.648 June 2018",
    "Owner": "Maroc Telecom 51% Gabonese government 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Gambia",
    "Rank": 1,
    "Operator": "Africell",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "1.30 Jan 2015 citation needed",
    "Owner": "Africell Gambia LTD",
    "OperatorLink": "https://www.wikipedia.org/wiki/Africell"
  },
  {
    "Country": "Gambia",
    "Rank": 2,
    "Operator": "Comium",
    "Technology": "GSM 900 1800 GPRS",
    "Subscribers": "0.25 2012 citation needed",
    "Owner": "Comium Group",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Comium&action=edit&redlink=1"
  },
  {
    "Country": "Gambia",
    "Rank": 3,
    "Operator": "Gamcel",
    "Technology": "GSM 900",
    "Subscribers": "0.24 2012 citation needed",
    "Owner": "Gambia Telecommunications Cellular Company Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Gamtel"
  },
  {
    "Country": "Gambia",
    "Rank": 4,
    "Operator": "Qcell",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "0.115 2012 citation needed",
    "Owner": "Qcell",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Qcell&action=edit&redlink=1"
  },
  {
    "Country": "Ghana",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "23.23 March 2021",
    "Owner": "MTN 97.7%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Ghana"
  },
  {
    "Country": "Ghana",
    "Rank": 2,
    "Operator": "Vodafone",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "8.91 March 2021",
    "Owner": "Vodafone 70% Government of Ghana 30%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Ghana"
  },
  {
    "Country": "Ghana",
    "Rank": 3,
    "Operator": "AirtelTigo",
    "Technology": "",
    "Subscribers": "8.32 March 2021",
    "Owner": "Government of Ghana",
    "OperatorLink": ""
  },
  {
    "Country": "Ghana",
    "Rank": 4,
    "Operator": "Glo",
    "Technology": "GSM GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "0.78 March 2021",
    "Owner": "Globacom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Globacom"
  },
  {
    "Country": "Guinea",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "5.24 Q4 2015",
    "Owner": "Orange S.A. 52.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Guinea",
    "Rank": 2,
    "Operator": "MTN formerly Areeba",
    "Technology": "GSM 900 1800 MHz GPRS 2100 MHz UMTS HSPA WiMAX",
    "Subscribers": "3.21 Q4 2015",
    "Owner": "MTN 75%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Guinea",
    "Rank": 3,
    "Operator": "Cellcom Guin e",
    "Technology": "GSM 900 1800 MHz2100 MHz UMTS",
    "Subscribers": "2.14 Q4 2015",
    "Owner": "Cellcom Telecommunications Ltd",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Cellcom_Guin%C3%A9e&action=edit&redlink=1"
  },
  {
    "Country": "Guinea",
    "Rank": 4,
    "Operator": "Intercel",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.11 Q4 2015",
    "Owner": "Telecel Guinee SARL",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Intercel&action=edit&redlink=1"
  },
  {
    "Country": "Guinea",
    "Rank": 5,
    "Operator": "Sotelgui",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.16 citation needed",
    "Owner": "Telekom Malaysia government",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Sotelgui&action=edit&redlink=1"
  },
  {
    "Country": "Guinea-Bissau",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 UMTS LTE",
    "Subscribers": "0.575 Sep 2014",
    "Owner": "MTN Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group#Coverage"
  },
  {
    "Country": "Guinea-Bissau",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 LTE",
    "Subscribers": "0.477 Dec 2013",
    "Owner": "Orange S.A. 52.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Guinea-Bissau",
    "Rank": 3,
    "Operator": "pt Guinetel",
    "Technology": "GSM 900",
    "Subscribers": "not available",
    "Owner": "Guinea Telecom",
    "OperatorLink": "https://pt.wikipedia.org/wiki/Guinetel"
  },
  {
    "Country": "Iran",
    "Rank": 1,
    "Operator": "Hamrahe Aval",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 900 MHz HSPA DC HSPA 1800 2600 2100 900 MHz LTE LTE A VoLTE VoWiFi",
    "Subscribers": "68 Q4 2020",
    "Owner": "Telecommunication Company of Iran",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_Telecommunication_Company_of_Iran"
  },
  {
    "Country": "Iran",
    "Rank": 2,
    "Operator": "Irancell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 900 MHz HSPA DC HSPA 1800 2600 2100 900 MHz LTE LTE A VoLTE",
    "Subscribers": "55 Q4 2020",
    "Owner": "Iran Electronic Development Company 51% MTN Group 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Irancell"
  },
  {
    "Country": "Iran",
    "Rank": 3,
    "Operator": "RighTel",
    "Technology": "2100 900 MHz HSPA DC HSPA 1800 MHz LTE",
    "Subscribers": "2.5 Q1 2018",
    "Owner": "Social Security Investment Company",
    "OperatorLink": "https://www.wikipedia.org/wiki/RighTel_Communications"
  },
  {
    "Country": "Iraq",
    "Rank": 1,
    "Operator": "Zain",
    "Technology": "GSM 900 MHz GPRS EDGE 3G LTE",
    "Subscribers": "14.9 July 2020",
    "Owner": "Zain Group 71.67%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Iraq",
    "Rank": 2,
    "Operator": "Asia Cell",
    "Technology": "GSM 900 MHz GPRS EDGE",
    "Subscribers": "13.3 Q2 2020",
    "Owner": "Ooredoo 30%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Asia_Cell"
  },
  {
    "Country": "Iraq",
    "Rank": 3,
    "Operator": "Korek",
    "Technology": "GSM 900 MHz GPRS HSPA",
    "Subscribers": "NA",
    "Owner": "Korek Telecom Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Korek_Telecom"
  },
  {
    "Country": "Iraq",
    "Rank": 4,
    "Operator": "Omnnea",
    "Technology": "CDMA",
    "Subscribers": "NA",
    "Owner": "Omnnea",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Omnnea&action=edit&redlink=1"
  },
  {
    "Country": "Iraq",
    "Rank": 5,
    "Operator": "Itisaluna",
    "Technology": "CDMA 2000 1x EVDO 450 800 1900 MHz",
    "Subscribers": "NA",
    "Owner": "Itisaluna",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Itisaluna&action=edit&redlink=1"
  },
  {
    "Country": "Israel",
    "Rank": 1,
    "Operator": "Cellcom",
    "Technology": "GSM 1800 MHz GPRS EDGE 850 2100 MHz UMTS HSPA1800 MHz LTE100 MHz 5G NR",
    "Subscribers": "3.641 Q3 2020",
    "Owner": "DIC 41.77% Public 58.23%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cellcom_(Israel)"
  },
  {
    "Country": "Israel",
    "Rank": 2,
    "Operator": "Partner",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS DC HSPA 1800 MHz LTE100 MHz 5G NR",
    "Subscribers": "2.760 Q3 2020",
    "Owner": "S.B. Israel Telecom 30.4% exclusive Israeli shareholders 5% Public 64.6%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Partner_Communications_Company"
  },
  {
    "Country": "Israel",
    "Rank": 3,
    "Operator": "Pelephone",
    "Technology": "850 2100 MHz UMTS DC HSPA 1800 MHz LTE100 MHz 5G NR",
    "Subscribers": "2.406 Q3 2020",
    "Owner": "Bezeq",
    "OperatorLink": "https://www.wikipedia.org/wiki/Pelephone"
  },
  {
    "Country": "Israel",
    "Rank": 4,
    "Operator": "Hot Mobile",
    "Technology": "800 MHz iDEN2100 MHz UMTS DC HSPA 1800 MHz LTE100 MHz 5G NR",
    "Subscribers": "N A",
    "Owner": "HOT",
    "OperatorLink": "https://www.wikipedia.org/wiki/Hot_Mobile"
  },
  {
    "Country": "Israel",
    "Rank": 5,
    "Operator": "Golan Telecom",
    "Technology": "GSM 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE100 MHz 5G NR",
    "Subscribers": "N A",
    "Owner": "ELCO Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Golan_Telecom"
  },
  {
    "Country": "Israel",
    "Rank": 6,
    "Operator": "XFONE",
    "Technology": "1800 MHz LTE",
    "Subscribers": "N A",
    "Owner": "Marathon Telecom Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/XFONE"
  },
  {
    "Country": "Israel",
    "Rank": 7,
    "Operator": "Rami Levy using Pelephone",
    "Technology": "CDMA2000 EV DO UMTS DC HSPA LTE",
    "Subscribers": "N A",
    "Owner": "Rami Levy Hashikma Marketing",
    "OperatorLink": "https://www.wikipedia.org/wiki/Rami_Levy_Communications"
  },
  {
    "Country": "Israel",
    "Rank": 8,
    "Operator": "HomeCellular using Cellcom",
    "Technology": "GSM GPRS EDGE UMTS HSPA LTE",
    "Subscribers": "N A",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cellcom_(Israel)"
  },
  {
    "Country": "Israel",
    "Rank": 9,
    "Operator": "019 Mobile using Partner",
    "Technology": "GSM GPRS EDGE UMTS DC HSPA LTE",
    "Subscribers": "N A",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/Partner_Communications_Company"
  },
  {
    "Country": "Israel",
    "Rank": 10,
    "Operator": "Cellact using Pelephone",
    "Technology": "CDMA2000 EV DO UMTS DC HSPA LTE",
    "Subscribers": "N A",
    "Owner": "Cellact Communication",
    "OperatorLink": "https://www.wikipedia.org/wiki/Pelephone"
  },
  {
    "Country": "Jordan",
    "Rank": 1,
    "Operator": "Zain Jordan",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "4.94 Q1 2016",
    "Owner": "Zain Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Jordan",
    "Rank": 2,
    "Operator": "Orange Jordan",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "4.59 Q1 2016",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_Jordan"
  },
  {
    "Country": "Jordan",
    "Rank": 3,
    "Operator": "Umniah",
    "Technology": "GSM 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "4.55 Q1 2016",
    "Owner": "Batelco",
    "OperatorLink": "https://www.wikipedia.org/wiki/Umniah"
  },
  {
    "Country": "Kenya",
    "Rank": 1,
    "Operator": "Safaricom",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA WiMAX800 1800 MHz LTE",
    "Subscribers": "33.119 July 2019",
    "Owner": "Vodafone 40% Public Float 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Safaricom"
  },
  {
    "Country": "Kenya",
    "Rank": 2,
    "Operator": "Airtel formerly Zain",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "6.09 Jan 2018",
    "Owner": "Bharti Airtel 80%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Kenya",
    "Rank": 3,
    "Operator": "Telkom Kenya formerly Orange",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "3.4 Jan 2018",
    "Owner": "Helios Investment Partners 70% Kenyan government 30%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telkom_Kenya"
  },
  {
    "Country": "Kenya",
    "Rank": 4,
    "Operator": "Jamii Telecommunications Faiba 4G",
    "Technology": "LTE 700 MHz",
    "Subscribers": "NA",
    "Owner": "Jamii Telecommunications Limited",
    "OperatorLink": ""
  },
  {
    "Country": "Kuwait",
    "Rank": 1,
    "Operator": "Zain",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "2.6 Sep 2014",
    "Owner": "Zain Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Kuwait",
    "Rank": 2,
    "Operator": "Ooredoo formerly Wataniya",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA1800 MHz LTE",
    "Subscribers": "2.394 Q2 2014",
    "Owner": "Ooredoo 52.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo"
  },
  {
    "Country": "Kuwait",
    "Rank": 3,
    "Operator": "stc formerly Viva",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE",
    "Subscribers": "1.106 when citation needed",
    "Owner": "STC 26%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Stc&action=edit&redlink=1"
  },
  {
    "Country": "Lebanon",
    "Rank": 1,
    "Operator": "touch",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 800 1800 MHz LTE LTE A",
    "Subscribers": "2.4 Sep 2014",
    "Owner": "government management contract Zain Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mtc_touch"
  },
  {
    "Country": "Lebanon",
    "Rank": 2,
    "Operator": "Alfa",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 800 1800 MHz LTE LTE A",
    "Subscribers": "1.8 2014",
    "Owner": "government management contract Orascom Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Alfa_(Lebanon)"
  },
  {
    "Country": "Lesotho",
    "Rank": 1,
    "Operator": "Vodacom",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPALTE WiMAX",
    "Subscribers": "1.449 2016",
    "Owner": "Sekha Metsi Consortium 12% Vodacom 88%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodacom"
  },
  {
    "Country": "Lesotho",
    "Rank": 2,
    "Operator": "Econet Telecom Lesotho",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "0.448 2016",
    "Owner": "Econet Ezi Cel Lesotho Pty",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Econet_Ezi-Cel&action=edit&redlink=1"
  },
  {
    "Country": "Liberia",
    "Rank": 1,
    "Operator": "Lonestar Cell",
    "Technology": "GSM 900",
    "Subscribers": "1.299 Sep 2014",
    "Owner": "MTN 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Lonestar_Cell"
  },
  {
    "Country": "Liberia",
    "Rank": 2,
    "Operator": "Cellcom",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "0.997 end 2013",
    "Owner": "Orange C te d Ivoire formerly owned by Cellcom Telecommunications",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Liberia",
    "Rank": 3,
    "Operator": "Novafone formerly Comium",
    "Technology": "GSM 900 GPRS 2100 MHz UMTS DC HSPA",
    "Subscribers": "0.236 end 2013",
    "Owner": "Comium Services BVI",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Liberia",
    "Rank": 4,
    "Operator": "Libtelco",
    "Technology": "",
    "Subscribers": "0.005 still active",
    "Owner": "Liberia Telecommunications Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Liberia_Telecommunications_Corporation"
  },
  {
    "Country": "Libya",
    "Rank": 1,
    "Operator": "Almadar Aljadid",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSPA LTE",
    "Subscribers": "5.0 December 2019",
    "Owner": "State owned via GPTC",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Almadar_Aljadid&action=edit&redlink=1"
  },
  {
    "Country": "Libya",
    "Rank": 2,
    "Operator": "Libyana",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSPA 4G LTE",
    "Subscribers": "6.2 December 2019",
    "Owner": "State owned via GPTC",
    "OperatorLink": "https://www.wikipedia.org/wiki/Libyana"
  },
  {
    "Country": "Madagascar",
    "Rank": 1,
    "Operator": "Airtel",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "2.56 April 2013",
    "Owner": "Bharti Airtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Madagascar",
    "Rank": 2,
    "Operator": "Telma Mobile",
    "Technology": "CdmaOneGSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "1.98 April 2013",
    "Owner": "Distacom Group Telma SA Hiridjee group",
    "OperatorLink": "https://www.wikipedia.org/wiki/CdmaOne"
  },
  {
    "Country": "Madagascar",
    "Rank": 3,
    "Operator": "Orange",
    "Technology": "GSM 900 GPRS EDGE LTE",
    "Subscribers": "1.85 April 2013",
    "Owner": "Orange S.A. 40.01%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Madagascar",
    "Rank": 4,
    "Operator": "Blueline",
    "Technology": "LTE",
    "Subscribers": "N A",
    "Owner": "Blueline",
    "OperatorLink": "https://www.wikipedia.org/wiki/LTE_(telecommunication)"
  },
  {
    "Country": "Madagascar",
    "Rank": 5,
    "Operator": "bip Madagascar",
    "Technology": "LTE",
    "Subscribers": "launched in June 2016",
    "Owner": "Blueline",
    "OperatorLink": "https://www.wikipedia.org/wiki/LTE_(telecommunication)"
  },
  {
    "Country": "Malawi",
    "Rank": 1,
    "Operator": "Airtel formerly Zain",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "3.5 September 2015",
    "Owner": "Bharti Airtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Malawi",
    "Rank": 2,
    "Operator": "TNM",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "2 July 2013",
    "Owner": "Malawi Telecommunications Limited Telekom Malaysia",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telekom_Networks_Malawi"
  },
  {
    "Country": "Malawi",
    "Rank": 3,
    "Operator": "G Mobile",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "Globally Advanced Integrated Networks Beryl South Africa",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Malawi",
    "Rank": 4,
    "Operator": "Lacell",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "La Cell Private Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Malawi",
    "Rank": 5,
    "Operator": "G Expresso",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "Expresso Telecom Group Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Mali",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "13.1 end 2016",
    "Owner": "Orange S.A. 52.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Mali",
    "Rank": 2,
    "Operator": "Sotelma Malitel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "7.1 end 2016",
    "Owner": "Maroc Telecom 51% local investors 20% government 19% staff 10%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sotelma"
  },
  {
    "Country": "Mali",
    "Rank": 3,
    "Operator": "Planor",
    "Technology": "GSM",
    "Subscribers": "licensed in Jan 2012",
    "Owner": "Planor support of Monaco Telecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Planor&action=edit&redlink=1"
  },
  {
    "Country": "Mauritania",
    "Rank": 1,
    "Operator": "Moov Mauritel",
    "Technology": "GSM 850 MHz 900 MHz 1800 MHz 1900 MHz UMTS HSPA 4G LTE",
    "Subscribers": "2.600 Dec 2020 citation needed",
    "Owner": "Maroc Telecom 51.5%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Moov_Mauritel&action=edit&redlink=1"
  },
  {
    "Country": "Mauritania",
    "Rank": 2,
    "Operator": "Chinguitel",
    "Technology": "GSM 850 MHz 900 MHz 1800 MHz 1900 MHz CDMA UMTS HSPA",
    "Subscribers": "1.40 May 2008",
    "Owner": "Sudatel 60%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Chinguitel&action=edit&redlink=1"
  },
  {
    "Country": "Mauritania",
    "Rank": 3,
    "Operator": "MATTEL",
    "Technology": "GSM 850 MHz 900 MHz 1800 MHz 1900 MHz UMTS HSPA 4G LTE",
    "Subscribers": "NA",
    "Owner": "Tunisie Telecom 51% Mauritanian Investors 49%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=MATTEL&action=edit&redlink=1"
  },
  {
    "Country": "Mauritius",
    "Rank": 1,
    "Operator": "Mauritius Telecom",
    "Technology": "GSM 900 EDGE 2100 MHz UMTS HSDPA1800 MHz LTE",
    "Subscribers": "0.628 Dec 2009",
    "Owner": "Orange S.A. 40% Government of Mauritius 33.49% SBM Investments Managers Ltd 19% National Pensions Fund 6.55% Employees of Mauritius Telecom 0.96%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mauritius_Telecom"
  },
  {
    "Country": "Mauritius",
    "Rank": 2,
    "Operator": "Emtel",
    "Technology": "GSM 900 EDGE 2100 MHz UMTS HSDPA1800 MHz LTE",
    "Subscribers": "0.437 Dec 2009",
    "Owner": "MIC 50% Currimjee",
    "OperatorLink": "https://www.wikipedia.org/wiki/Emtel"
  },
  {
    "Country": "Mauritius",
    "Rank": 3,
    "Operator": "CHiLi",
    "Technology": "CdmaOne EvDo GSM UMTS",
    "Subscribers": "0.318 March 2019",
    "Owner": "Mahanagar Telephone Mauritius Limited MTML",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTML"
  },
  {
    "Country": "Morocco",
    "Rank": 1,
    "Operator": "Maroc Telecom",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA 800 1800 MHz LTE LTE A",
    "Subscribers": "18.373 June 2017",
    "Owner": "Etisalat 49% state of Morocco 51%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Maroc_Telecom"
  },
  {
    "Country": "Morocco",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA DC HSDPA 800 1800 2600 MHz LTE LTE A",
    "Subscribers": "14.692 June 2017",
    "Owner": "Orange S.A. 49% FinanceCom CDG 51%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_(telecommunications)"
  },
  {
    "Country": "Morocco",
    "Rank": 3,
    "Operator": "inwi",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA 800 1800 2600 MHz LTE LTE A",
    "Subscribers": "6.425 June 2017",
    "Owner": "Zain Group 31%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Wana_(Telecommunications)"
  },
  {
    "Country": "Mozambique",
    "Rank": 1,
    "Operator": "mcel",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA",
    "Subscribers": "5.3 when",
    "Owner": "Partially state owned",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Mcel&action=edit&redlink=1"
  },
  {
    "Country": "Mozambique",
    "Rank": 2,
    "Operator": "Vodacom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "4.3 Mar 2014",
    "Owner": "Vodacom 85%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodacom"
  },
  {
    "Country": "Mozambique",
    "Rank": 3,
    "Operator": "Movitel",
    "Technology": "GSM",
    "Subscribers": "2 May 2013",
    "Owner": "Viettel 70% SPI 30%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movitel,_SA"
  },
  {
    "Country": "Namibia",
    "Rank": 1,
    "Operator": "MTC",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSUPA HSPA 1800 MHz LTE",
    "Subscribers": "2.422 Dec 2016",
    "Owner": "Namibia Post and Telecommunications Holdings 66% Portugal Telecom 34%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTC_Namibia"
  },
  {
    "Country": "Namibia",
    "Rank": 2,
    "Operator": "TN Mobile formerly Cell One Leo",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "0.187 Dec 2016",
    "Owner": "Telecom Namibia Ltd.",
    "OperatorLink": "https://www.wikipedia.org/wiki/TN_Mobile"
  },
  {
    "Country": "Niger",
    "Rank": 1,
    "Operator": "Airtel",
    "Technology": "GSM 900",
    "Subscribers": "1.341 June 2009",
    "Owner": "Bharti Airtel 90%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Niger",
    "Rank": 2,
    "Operator": "Sonitel",
    "Technology": "GSM 900",
    "Subscribers": "2.5 Q1 2012",
    "Owner": "Sahel Com Lap Green overtaken by government",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sonitel"
  },
  {
    "Country": "Niger",
    "Rank": 3,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "0.462 Dec 2009",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Niger",
    "Rank": 4,
    "Operator": "Moov",
    "Technology": "GSM 900",
    "Subscribers": "NA",
    "Owner": "Maroc Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Nigeria",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "74.04 May 2021",
    "Owner": "MTN 41.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_Telephone_Networks"
  },
  {
    "Country": "Nigeria",
    "Rank": 2,
    "Operator": "Airtel formerly Zain",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "50.02 May 2021",
    "Owner": "Bharti Airtel 20.6%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Networks_Limited"
  },
  {
    "Country": "Nigeria",
    "Rank": 3,
    "Operator": "Glo Mobile",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "49.78 May 2021",
    "Owner": "Globacom 20.78%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Globacom"
  },
  {
    "Country": "Nigeria",
    "Rank": 4,
    "Operator": "9mobile formerly Etisalat",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "12.84 May 2021",
    "Owner": "Etisalat Mubadala Nigerian Investors 15.60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Oman",
    "Rank": 1,
    "Operator": "Omantel",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 1800 MHz FD LTE 2300 MHz TD LTE",
    "Subscribers": "2.72 Dec 2013",
    "Owner": "Omantel 99%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Omantel"
  },
  {
    "Country": "Oman",
    "Rank": 2,
    "Operator": "Ooredoo formerly Nawras",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS DC HSPA 800 1800 MHz FD LTE 2300 MHz TD LTE",
    "Subscribers": "2.472 Q2 2014",
    "Owner": "Ooredoo 55%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo"
  },
  {
    "Country": "Palestine",
    "Rank": 1,
    "Operator": "Jawwal",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "2.45 Q1 2012",
    "Owner": "Paltel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Jawwal"
  },
  {
    "Country": "Palestine",
    "Rank": 2,
    "Operator": "Ooredoo",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "0.679 Q2 2014",
    "Owner": "Ooredoo 57% Palestine Investment Fund 43%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo"
  },
  {
    "Country": "Qatar",
    "Rank": 1,
    "Operator": "Ooredoo formerly Qtel",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 800 1800 2600 MHz LTE LTE A",
    "Subscribers": "2.996 Q2 2014",
    "Owner": "Ooredoo",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo"
  },
  {
    "Country": "Qatar",
    "Rank": 2,
    "Operator": "Vodafone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA800 1800 2600 MHz LTE LTE A",
    "Subscribers": "1.372 September 2014",
    "Owner": "Vodafone 22.95% Qatari Institutions 37.05% Free Float 40%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Qatar"
  },
  {
    "Country": "La Runion",
    "Rank": 1,
    "Operator": "SFR",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "NA",
    "Owner": "SFR",
    "OperatorLink": "https://www.wikipedia.org/wiki/SFR"
  },
  {
    "Country": "La Runion",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Orange",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "La Runion",
    "Rank": 3,
    "Operator": "FREE",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "NA",
    "Owner": "fr ILIAD and TELCO OI",
    "OperatorLink": "https://fr.wikipedia.org/wiki/FREE"
  },
  {
    "Country": "Rwanda",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "3.65 Dec 2017",
    "Owner": "MTN Group 55% Crystal Telecom 35%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Rwanda",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "5.00 Dec 2017",
    "Owner": "Bharti",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Rwanda",
    "Rank": "",
    "Operator": "Rwandatel",
    "Technology": "CdmaOneGSM 900 18002100 MHz UMTS",
    "Subscribers": "license revoked Apr 2011",
    "Owner": "LAP Green Networks 80%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Rwandatel"
  },
  {
    "Country": "So Tom and Prncipe",
    "Rank": 1,
    "Operator": "CST",
    "Technology": "GSM 900 UMTS",
    "Subscribers": "0.078 September 2009",
    "Owner": "Portugal Telecom 51%",
    "OperatorLink": ""
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 1,
    "Operator": "STC",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 900 2100 MHz UMTS DC HSPA 4G FD LTE 1800 MHz4G TD LTE 2300 MHz",
    "Subscribers": "19.914 Q3 2010",
    "Owner": "70% state owned and 30% private citation needed",
    "OperatorLink": "https://www.wikipedia.org/wiki/Saudi_Telecom_Company"
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 2,
    "Operator": "Mobily",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 2100 MHz UMTS HSPA 4G TD LTE 2600 MHz4G FD LTE 1800 MHz",
    "Subscribers": "15.06 Q3 2010",
    "Owner": "Etisalat 25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobily"
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 3,
    "Operator": "Zain",
    "Technology": "2G GSM 900 1800 GPRS EDGE 3G 900 2100 MHz UMTS HSPA 4G FD LTE 2100 1800 MHz LTE LTE A",
    "Subscribers": "11.40 Dec 2015",
    "Owner": "Zain Group 37%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 4,
    "Operator": "Bravo",
    "Technology": "iDEN",
    "Subscribers": "0.196 Sep 2009 citation needed",
    "Owner": "STC",
    "OperatorLink": "https://www.wikipedia.org/wiki/IDEN"
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 5,
    "Operator": "Virgin mobile KSA",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz FD LTE 2300 MHz TD LTE",
    "Subscribers": "2.8 2017",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/Virgin_Mobile_Saudi_Arabia"
  },
  {
    "Country": "Saudi Arabia",
    "Rank": 6,
    "Operator": "Lebara KSA",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz FD LTE 2600 MHz TD LTE",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Lebara_KSA&action=edit&redlink=1"
  },
  {
    "Country": "Senegal",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 9002100 MHz UMTS LTE",
    "Subscribers": "9.936 31 Dec 2015",
    "Owner": "Sonatel Orange S.A. 52.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Senegal",
    "Rank": 2,
    "Operator": "Free",
    "Technology": "GSM 900UMTS HSPA LTE",
    "Subscribers": "3.355 31 Dec 2015",
    "Owner": "Teyliom NJJ and AXIAN",
    "OperatorLink": "https://www.wikipedia.org/wiki/Free_(ISP)"
  },
  {
    "Country": "Senegal",
    "Rank": 3,
    "Operator": "Expresso",
    "Technology": "CDMAGSM 900 1800 UMTS",
    "Subscribers": "3.188 31 Dec 2015",
    "Owner": "Sudatel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Expresso_Telecom"
  },
  {
    "Country": "Senegal",
    "Rank": 4,
    "Operator": "Hayo Telecom",
    "Technology": "CDMAGSM 900 1800 UMTS",
    "Subscribers": "0.483 31 Dec 2015",
    "Owner": "CSU",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Hayo_Telecom&action=edit&redlink=1"
  },
  {
    "Country": "Seychelles",
    "Rank": 1,
    "Operator": "Airtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": 0.065,
    "Owner": "Bharti Airtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Seychelles",
    "Rank": 2,
    "Operator": "Cable Wireless",
    "Technology": "GSM 900 MHz",
    "Subscribers": "NA",
    "Owner": "Cable Wireless",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cable_%26_Wireless_Worldwide"
  },
  {
    "Country": "Sierra Leone",
    "Rank": 1,
    "Operator": "Africell",
    "Technology": "GSM 900UMTS",
    "Subscribers": "2.9 Feb 2015",
    "Owner": "Lintel Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Africell"
  },
  {
    "Country": "Sierra Leone",
    "Rank": 2,
    "Operator": "Orange",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "0.644 Feb 2012",
    "Owner": "Orange",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Sierra Leone",
    "Rank": 3,
    "Operator": "Sierratel",
    "Technology": "CDMA",
    "Subscribers": "NA",
    "Owner": "government managed by MDIC",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Sierratel&action=edit&redlink=1"
  },
  {
    "Country": "Sierra Leone",
    "Rank": "",
    "Operator": "Comium",
    "Technology": "GSM 900 1800 WiMAX",
    "Subscribers": "0.650 2009",
    "Owner": "Comium licence revoked since October 2014 while NATCOM managers have been sacked in 2015",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Comium&action=edit&redlink=1"
  },
  {
    "Country": "Sierra Leone",
    "Rank": "",
    "Operator": "GreenN",
    "Technology": "GSM",
    "Subscribers": "NA",
    "Owner": "Ambitel LapGreen licence revoked since September 2014",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=GreenN&action=edit&redlink=1"
  },
  {
    "Country": "Somalia",
    "Rank": 1,
    "Operator": "Hormuud",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "2.38 Q4 2015",
    "Owner": "Hormuud Telecom Somalia Inc",
    "OperatorLink": "https://www.wikipedia.org/wiki/Hormuud_Telecom"
  },
  {
    "Country": "Somalia",
    "Rank": 2,
    "Operator": "Telcom Somalia",
    "Technology": "GSM2100 MHz UMTS LTE",
    "Subscribers": "1.00 Q4 2015",
    "Owner": "Haatif Telecom Somalia",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telcom_(Somalia)"
  },
  {
    "Country": "Somalia",
    "Rank": 3,
    "Operator": "Telesom Mobile",
    "Technology": "GSM2100 MHz UMTS",
    "Subscribers": "0.80 Q4 2015",
    "Owner": "Telesom Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telesom"
  },
  {
    "Country": "Somalia",
    "Rank": 4,
    "Operator": "Nationlink",
    "Technology": "GSM 900",
    "Subscribers": "0.71 Q4 2015",
    "Owner": "Bintel",
    "OperatorLink": "https://www.wikipedia.org/wiki/NationLink_Telecom"
  },
  {
    "Country": "Somalia",
    "Rank": 5,
    "Operator": "Somtel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "0.46 Q4 2015",
    "Owner": "Dahabshiil",
    "OperatorLink": "https://www.wikipedia.org/wiki/Somtel"
  },
  {
    "Country": "Somalia",
    "Rank": 6,
    "Operator": "Golis Telecom Somalia",
    "Technology": "GSM 900",
    "Subscribers": "0.24 Q4 2015",
    "Owner": "Golis Telecom Somalia Gaani Wireless",
    "OperatorLink": "https://www.wikipedia.org/wiki/Golis_Telecom_Somalia"
  },
  {
    "Country": "Somalia",
    "Rank": 7,
    "Operator": "Somafone",
    "Technology": "GSM 900",
    "Subscribers": "0.20 Q4 2015",
    "Owner": "Somali Telecom Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Somafone"
  },
  {
    "Country": "Somalia",
    "Rank": 8,
    "Operator": "Netco",
    "Technology": "GSM 900",
    "Subscribers": "NA",
    "Owner": "Somali Telecom Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Netco_(Somalia)"
  },
  {
    "Country": "Somalia",
    "Rank": 9,
    "Operator": "SomNet",
    "Technology": "GSM 900 LTE",
    "Subscribers": "NA",
    "Owner": "SomNet Telecom",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=SomNet&action=edit&redlink=1"
  },
  {
    "Country": "South Africa",
    "Rank": 1,
    "Operator": "Vodacom",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE 5G 3500 MHzWiMAX",
    "Subscribers": "39.433 August 2020",
    "Owner": "Vodafone 65%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodacom"
  },
  {
    "Country": "South Africa",
    "Rank": 2,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS DC HSPA 1800 MHz LTE 5G 3500 MHz",
    "Subscribers": "29.028 August 2020",
    "Owner": "MTN Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "South Africa",
    "Rank": 3,
    "Operator": "Cell C",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS DC HSPA 1800 2100 MHz LTE LTE A",
    "Subscribers": "14.407 Mar 2020",
    "Owner": "Oger Telecom in process of diluting it s 70% stake to 27% Blue Label Telecoms is planning to acquire a 30% stake in Cell C",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cell_C"
  },
  {
    "Country": "South Africa",
    "Rank": 4,
    "Operator": "Telkom",
    "Technology": "GSM 1800 GPRS 2100 MHz UMTS HSPA 2300 MHz LTE",
    "Subscribers": "4.0 March 2017",
    "Owner": "Telkom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telkom_SA"
  },
  {
    "Country": "South Africa",
    "Rank": 5,
    "Operator": "Rain",
    "Technology": "1800 MHz LTE 5G 3500 MHz",
    "Subscribers": "NA",
    "Owner": "Rain formerly Wireless Business Solutions 80% ARC 20%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Rain_(telecommunications)"
  },
  {
    "Country": "South Sudan",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "0.787 Sep 2014",
    "Owner": "MTN Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "South Sudan",
    "Rank": 2,
    "Operator": "Zain",
    "Technology": "GSM GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "0.664 Sep 2014",
    "Owner": "Zain Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "South Sudan",
    "Rank": 3,
    "Operator": "Sudani",
    "Technology": "GSM GPRS 2100 MHz UMTS HSDPA",
    "Subscribers": "NA",
    "Owner": "Sudatel not operationally active",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sudani"
  },
  {
    "Country": "South Sudan",
    "Rank": 4,
    "Operator": "Vivacell NOW",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "NA",
    "Owner": "Fattouch Investment Group 75% Wawat Securities 25%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Vivacell&action=edit&redlink=1"
  },
  {
    "Country": "South Sudan",
    "Rank": 5,
    "Operator": "Gemtel",
    "Technology": "GSM 900 1800 MHz",
    "Subscribers": "NA",
    "Owner": "Lap Green Networks 80%",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Gemtel&action=edit&redlink=1"
  },
  {
    "Country": "Sudan",
    "Rank": 1,
    "Operator": "Zain",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "11.095 Sep 2014",
    "Owner": "Zain Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zain_Group"
  },
  {
    "Country": "Sudan",
    "Rank": 2,
    "Operator": "MTN",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "8.474 Sep 2014",
    "Owner": "MTN Group 85%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Sudan",
    "Rank": 3,
    "Operator": "Sudani",
    "Technology": "CDMA GSM 1800 MHz GPRS 2100 MHz UMTS HSDPA HSUPA",
    "Subscribers": "NA",
    "Owner": "Sudatel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sudani"
  },
  {
    "Country": "Sudan",
    "Rank": 4,
    "Operator": "Canar",
    "Technology": "CdmaOne",
    "Subscribers": "NA",
    "Owner": "Bank of Khartoum 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/CdmaOne"
  },
  {
    "Country": "Eswatini",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "0.928 Sep 2014",
    "Owner": "MTN Group 30%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Syrian Arab Republic",
    "Rank": 1,
    "Operator": "MTN formerly Areeba",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "5.676 Sep 2014",
    "Owner": "MTN Group 39%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Syrian Arab Republic",
    "Rank": 2,
    "Operator": "Syriatel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "7.1 Aug 2014",
    "Owner": "Rami Makhlouf",
    "OperatorLink": "https://www.wikipedia.org/wiki/Syriatel"
  },
  {
    "Country": "Tanzania (United Republic of)",
    "Rank": 1,
    "Operator": "Vodacom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "15.59 March 2020",
    "Owner": "Vodacom 82.2%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodacom"
  },
  {
    "Country": "Tanzania (United Republic of)",
    "Rank": 2,
    "Operator": "Airtel formerly Zain",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "13.39 March 2020",
    "Owner": "Bharti Airtel 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Tanzania"
  },
  {
    "Country": "Tanzania (United Republic of)",
    "Rank": 3,
    "Operator": "Tigo",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "12.64 March 2020",
    "Owner": "MIC",
    "OperatorLink": "https://www.wikipedia.org/wiki/MIC_Tanzania_Limited"
  },
  {
    "Country": "Tanzania (United Republic of)",
    "Rank": 4,
    "Operator": "Halotel",
    "Technology": "GSM UMTS",
    "Subscribers": 5.2,
    "Owner": "Viettel Tanzania Limited Subsidiary of Viettel Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Halotel"
  },
  {
    "Country": "Tanzania (United Republic of)",
    "Rank": 5,
    "Operator": "Zantel",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "0.93 March 2020",
    "Owner": "Etisalat 51% and Zanzibar Telecom Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Etisalat#Zantel__Tanzania"
  },
  {
    "Country": "Togo",
    "Rank": 1,
    "Operator": "Togocel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "2.751 4Q 2014 citation needed",
    "Owner": "Togo Telecom state owned",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Togocel&action=edit&redlink=1"
  },
  {
    "Country": "Togo",
    "Rank": 2,
    "Operator": "Moov",
    "Technology": "GSM 900 MHz GPRS EDGE",
    "Subscribers": "1.648 4Q 2014 citation needed",
    "Owner": "Etisalat",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Tunisia",
    "Rank": 1,
    "Operator": "Ooredoo formerly Tunisiana",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSPA DC HSPA LTE",
    "Subscribers": "8 836 544 Q3 2017",
    "Owner": "Ooredoo 75%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ooredoo"
  },
  {
    "Country": "Tunisia",
    "Rank": 2,
    "Operator": "Tunisie Telecom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "4 462 998 Q3 2017",
    "Owner": "Tunisie T l com Group 65% Tecom DIG 35%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tunisie_Telecom"
  },
  {
    "Country": "Tunisia",
    "Rank": 3,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "3 907 791 Q3 2017",
    "Owner": "Orange S.A. Divona",
    "OperatorLink": "https://www.wikipedia.org/wiki/Orange_S.A."
  },
  {
    "Country": "Turkey",
    "Rank": 1,
    "Operator": "Turkcell",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 3C HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi5G testing",
    "Subscribers": "34.000 Q2 2015",
    "Owner": "Turkey Wealth Fund 26.2% LetterOne 24.8% free float 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Turkcell"
  },
  {
    "Country": "Turkey",
    "Rank": 2,
    "Operator": "Vodafone Formerly Telsim",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi 5G testing",
    "Subscribers": "23.437 Q3 2020",
    "Owner": "Vodafone 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Turkey"
  },
  {
    "Country": "Turkey",
    "Rank": 3,
    "Operator": "T rk Telekom Formerly Avea Aria Aycell",
    "Technology": "GSM 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi5G testing",
    "Subscribers": "16.700 Q2 2015",
    "Owner": "T rk Telekom 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/T%C3%BCrk_Telekom"
  },
  {
    "Country": "Uganda",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "CDMAGSM 900 MHz GPRS EDGE 2100 MHz UMTS DC HSPA 2600 MHz TD LTEWiMAX",
    "Subscribers": "10.181 September 2014",
    "Owner": "MTN Group 96%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Uganda",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "4.6 April 2013",
    "Owner": "Bharti Airtel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_(India)"
  },
  {
    "Country": "Uganda",
    "Rank": 3,
    "Operator": "Africell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 MHz 2100 MHz UMTS HSPA 800 MHz LTE 2600 MHz LTE",
    "Subscribers": "1.8 November 2016",
    "Owner": "Africell Holding 95%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Africell"
  },
  {
    "Country": "Uganda",
    "Rank": "",
    "Operator": "Warid Bought by Airtel in 2013",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA WiMAX",
    "Subscribers": "2.8 April 2013",
    "Owner": "Airtel Africa",
    "OperatorLink": "https://www.wikipedia.org/wiki/Warid_Telecom"
  },
  {
    "Country": "Uganda",
    "Rank": 4,
    "Operator": "UT Mobile",
    "Technology": "CdmaOneGSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "1.0 July 2016",
    "Owner": "Uganda Telecom Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/CdmaOne"
  },
  {
    "Country": "Uganda",
    "Rank": 5,
    "Operator": "Essar",
    "Technology": "GSM GPRS",
    "Subscribers": "NA",
    "Owner": "Essar Group 90%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Essar"
  },
  {
    "Country": "Uganda",
    "Rank": 6,
    "Operator": "Smart Telecom",
    "Technology": "unknown",
    "Subscribers": "uses Sure Telecom licence",
    "Owner": "Smart Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "United Arab Emirates",
    "Rank": 1,
    "Operator": "Etisalat",
    "Technology": "GSM 900 MHz GPRS EDGE 900 MHz UMTS DC HSPA 800 1800 2100 2600 MHz LTE LTE A 5G NR",
    "Subscribers": "10.8 July 2019",
    "Owner": "UAE Federal Government 40% and public shareholders 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Etisalat"
  },
  {
    "Country": "United Arab Emirates",
    "Rank": 2,
    "Operator": "du",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS DC HSPA 800 1800 MHz LTE LTE A",
    "Subscribers": "7.6 July 2019",
    "Owner": "UAE Federal Government 39.5% Mubadala Development Company 19.75% TECOM Investments 19.5% and public shareholders 21.25%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Du_(telco)"
  },
  {
    "Country": "Yemen",
    "Rank": 1,
    "Operator": "Spacetel",
    "Technology": "GSM 900 MHz",
    "Subscribers": "5.300 Sep 2014",
    "Owner": "MTN Group 85%",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Yemen",
    "Rank": 2,
    "Operator": "Sabafon",
    "Technology": "GSM 900 MHz",
    "Subscribers": "3.65 September 2012",
    "Owner": "Yemen Mobile Phone Company Batelco 26.942%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sabafon"
  },
  {
    "Country": "Yemen",
    "Rank": 3,
    "Operator": "Yemen Mobile",
    "Technology": "CDMA2000 1xEVDO RevA 800 MHz",
    "Subscribers": "2.180 2008 2009 citation needed",
    "Owner": "Yemen Mobile",
    "OperatorLink": ""
  },
  {
    "Country": "Yemen",
    "Rank": 4,
    "Operator": "HiTS UNITEL Y",
    "Technology": "GSM 900 MHz",
    "Subscribers": "0.281 2008 2009 citation needed",
    "Owner": "HiTS UNITEL",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Zambia",
    "Rank": 1,
    "Operator": "MTN",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS DC HSPA LTE",
    "Subscribers": "4.447 Sep 2014",
    "Owner": "MTN Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTN_Group"
  },
  {
    "Country": "Zambia",
    "Rank": 2,
    "Operator": "Airtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "4.04 May 2014",
    "Owner": "Bharti Airtel 78.88%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Airtel_Africa"
  },
  {
    "Country": "Zambia",
    "Rank": 3,
    "Operator": "Zamtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "1.2 Dec 2011 citation needed",
    "Owner": "Zambia Telecommunications Company Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Zamtel"
  },
  {
    "Country": "Zimbabwe",
    "Rank": 1,
    "Operator": "Econet",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA1800 MHz LTEWiMAX",
    "Subscribers": "5.686 February 2012",
    "Owner": "Econet Wireless Private Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Econet_Wireless"
  },
  {
    "Country": "Zimbabwe",
    "Rank": 2,
    "Operator": "Telecel",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "1.875 February 2012",
    "Owner": "VimpelCom Ltd 60%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Zimbabwe",
    "Rank": 3,
    "Operator": "Net One",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSDPA1800 MHz LTE",
    "Subscribers": "1.456 February 2012",
    "Owner": "NetOne Cellular Pvt Ltd government",
    "OperatorLink": "https://www.wikipedia.org/wiki/Net*One"
  },
  {
    "Country": "Albania",
    "Rank": 1,
    "Operator": "Vodafone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE A LTE A ProVoLTE 3600 MHz 3700 MHz 5G NR testing",
    "Subscribers": "1.492 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Albania",
    "Rank": 2,
    "Operator": "One Telecommunications Formerly Telekom AMC",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE",
    "Subscribers": "1.364 Q3 2021",
    "Owner": "Albania telecom invest",
    "OperatorLink": ""
  },
  {
    "Country": "Albania",
    "Rank": 3,
    "Operator": "ALBtelecom Mobile Formerly Eagle Mobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 2600 MHz LTE LTE A",
    "Subscribers": "0.550 Q3 2021",
    "Owner": "Albtelecom",
    "OperatorLink": ""
  },
  {
    "Country": "Andorra",
    "Rank": 1,
    "Operator": "Andorra Telecom Formerly Servei de Telecomunicacions d Andorra",
    "Technology": "900 1800 MHz GPRS 2100 MHz UMTS HSDPA 800 1800 MHz LTE LTE AVoLTE",
    "Subscribers": "0.088 2021",
    "Owner": "Government of Andorra",
    "OperatorLink": ""
  },
  {
    "Country": "Armenia",
    "Rank": 1,
    "Operator": "Viva MTS",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz Band B1 B8 UMTS HSDPA LTE LTE A 1800 2600 MHz Band B3 B7 LTE",
    "Subscribers": "2.2 Q3 2021",
    "Owner": "MTS",
    "OperatorLink": "https://www.wikipedia.org/wiki/MTS_(network_provider)"
  },
  {
    "Country": "Armenia",
    "Rank": 2,
    "Operator": "Beeline Armenia",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz Band B1 B8 UMTS HSDPA LTE LTE A 900 MHz 1800 MHz",
    "Subscribers": "1.0 November 2017",
    "Owner": "Telecom Armenia Team",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telecom_Armenia"
  },
  {
    "Country": "Armenia",
    "Rank": 3,
    "Operator": "Ucom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz Band B1 B8 UMTS HSDPA LTE LTE A 1800 2600 MHz Band B3 B7 LTE",
    "Subscribers": "0.933 December 2017",
    "Owner": "Ucom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Ucom"
  },
  {
    "Country": "Austria",
    "Rank": 1,
    "Operator": "Magenta Telekom Formerly T Mobile max.mobil.",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA roams on 3 s UMTS network where no UMTS 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi 3500 MHz 5G NR",
    "Subscribers": "5.126 3Q 2021",
    "Owner": "Deutsche Telekom AG",
    "OperatorLink": ""
  },
  {
    "Country": "Austria",
    "Rank": 2,
    "Operator": "A1",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi 3500 MHz 5G NR",
    "Subscribers": "5.023 including yesss Q2 2021",
    "Owner": "A1 Telekom Austria Group Am rica M vil 51% BAG 28 42% free float 20 58%",
    "OperatorLink": ""
  },
  {
    "Country": "Austria",
    "Rank": 3,
    "Operator": "Drei Includes the previous Orange network.",
    "Technology": "GSM 900 1800 GPRS EDGE roams on Magenta Telekom s GSM network when needed 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi 3500 MHz 5G NR",
    "Subscribers": "3.369 Q2 2021",
    "Owner": "CK Hutchison Holdings",
    "OperatorLink": ""
  },
  {
    "Country": "Azerbaijan",
    "Rank": 1,
    "Operator": "Azercell",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE",
    "Subscribers": "4.417 April 2013",
    "Owner": "AzInTelecom state owned company",
    "OperatorLink": ""
  },
  {
    "Country": "Azerbaijan",
    "Rank": 2,
    "Operator": "Bakcell",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE",
    "Subscribers": 3.4,
    "Owner": "Neqsol Holding",
    "OperatorLink": ""
  },
  {
    "Country": "Azerbaijan",
    "Rank": 3,
    "Operator": "Nar",
    "Technology": "GSM 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE",
    "Subscribers": "2.1 March 2014",
    "Owner": "Azerfon LLC",
    "OperatorLink": ""
  },
  {
    "Country": "Azerbaijan",
    "Rank": 4,
    "Operator": "Nakhtel",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE CDMA 800",
    "Subscribers": "NA",
    "Owner": "Nakhtel LLC",
    "OperatorLink": ""
  },
  {
    "Country": "Belarus",
    "Rank": 1,
    "Operator": "MTS",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2600 MHz LTE LTE A",
    "Subscribers": "5.7 Q4 2021",
    "Owner": "Beltelecom 51% MTS 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Belarus",
    "Rank": 2,
    "Operator": "A1 Formerly velcom",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE",
    "Subscribers": "4.935 Q2 2021",
    "Owner": "A1 Telekom Austria Group",
    "OperatorLink": ""
  },
  {
    "Country": "Belarus",
    "Rank": 3,
    "Operator": "Life",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2600 MHz LTE LTE A",
    "Subscribers": "1.5 Q3 2021",
    "Owner": "Turkcell 80% Belarusian Government 20%",
    "OperatorLink": ""
  },
  {
    "Country": "Belgium",
    "Rank": 1,
    "Operator": "Proximus",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE 2100 3500 MHz 5G NR",
    "Subscribers": 5.32,
    "Owner": "Proximus",
    "OperatorLink": ""
  },
  {
    "Country": "Belgium",
    "Rank": 2,
    "Operator": "Orange formerly Mobistar OBE",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi",
    "Subscribers": "5.144 Q3 2021",
    "Owner": "Orange S.A. 52.91% free float 35.78% others 11.31%",
    "OperatorLink": ""
  },
  {
    "Country": "Belgium",
    "Rank": 3,
    "Operator": "Base",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi",
    "Subscribers": "2.952 Q3 2021",
    "Owner": "Telenet",
    "OperatorLink": ""
  },
  {
    "Country": "Bosnia and Herzegovina",
    "Rank": 1,
    "Operator": "BH Telecom",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA HSPA HSDPA HSDPA DC HSPA DC HSPA DC HSUPA DC HSDPA1800 MHz LTE",
    "Subscribers": "1.8 Jan 2021",
    "Owner": "BH Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "Bosnia and Herzegovina",
    "Rank": 2,
    "Operator": "m tel",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA HSDPA HSDPA DC HSPA DC HSPA 800 1800 MHz LTE LTE A LTE A Pro",
    "Subscribers": "1.414 Dec 2012",
    "Owner": "Telekom Srbija",
    "OperatorLink": ""
  },
  {
    "Country": "Bosnia and Herzegovina",
    "Rank": 3,
    "Operator": "HT Eronet",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA HSDPA HSDPA DC HSPA DC HSPA DC HSUPA DC HSDPA HSUPA800 1800 MHz LTE LTE A LTE A Pro",
    "Subscribers": "0.445 Dec 2012",
    "Owner": "Federation of Bosnia and Herzegovina 50% Hrvatski Telekom 39% free float 11%",
    "OperatorLink": ""
  },
  {
    "Country": "Bulgaria",
    "Rank": 1,
    "Operator": "A1 Formerly Mtel Mobiltel",
    "Technology": "GSM 900 GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA DC HSPA 900 1800 2100 2600 MHz LTE LTE AVoLTE3600 MHz 5G NR",
    "Subscribers": "3.737 Q3 2021",
    "Owner": "Link",
    "OperatorLink": "A1 Telekom Austria Group Am rica M vil 51% BAG 28 42% free float 20 58%"
  },
  {
    "Country": "Bulgaria",
    "Rank": 2,
    "Operator": "Telenor Formerly Globul",
    "Technology": "GSM 900 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 900 1800 2100 2600 MHz LTE LTE A VoLTE ViLTE2600 3600 MHz 5G NR",
    "Subscribers": "3.463 Q2 2021",
    "Owner": "Link",
    "OperatorLink": "PPF"
  },
  {
    "Country": "Bulgaria",
    "Rank": 3,
    "Operator": "Vivacom Formerly Vivatel",
    "Technology": "GSM 900 GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi1800 3600 MHz 5G NR",
    "Subscribers": "2.953 Q1 2021",
    "Owner": "Link",
    "OperatorLink": "United Group"
  },
  {
    "Country": "Croatia",
    "Rank": 1,
    "Operator": "Hrvatski Telekom Formerly T Mobile HTmobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA800 1800 2600 MHz LTE LTE A LTE A Pro VoLTEVoWiFi2100 3500 MHz 5G NR",
    "Subscribers": "2.326 Q3 2021",
    "Owner": "Deutsche Telekom 51.7%",
    "OperatorLink": ""
  },
  {
    "Country": "Croatia",
    "Rank": 2,
    "Operator": "A1 Formerly Vip Vipnet",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSPA 800 1800 2100 2600 MHz LTE LTE A LTE A ProVoLTE700 3600 MHz 5G NR",
    "Subscribers": "1.990 Q3 2021",
    "Owner": "A1 Telekom Austria Group Am rica M vil 51% BAG 28 42% free float 20 58%",
    "OperatorLink": ""
  },
  {
    "Country": "Croatia",
    "Rank": 3,
    "Operator": "Telemach Formerly Tele2",
    "Technology": "GSM 900 1800 MHz GPRS EDGE Roaming on Hrvatski Telekom when needed 900 2100 MHz UMTS HSPA 1800 2600 MHz LTE LTE A",
    "Subscribers": "0.923 Q1 2021",
    "Owner": "United Group",
    "OperatorLink": ""
  },
  {
    "Country": "Cyprus",
    "Rank": 1,
    "Operator": "Epic Formerly MTN",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 800 1800 2100 2600 MHz LTE LTE A",
    "Subscribers": "0.399 March 2014",
    "Owner": "Monaco Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "Cyprus",
    "Rank": 2,
    "Operator": "Cytamobile Vodafone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 800 1800 2100 2600 MHz LTE LTE A3600 MHz 5G NR",
    "Subscribers": "0.296 December 2012",
    "Owner": "Cyprus Telecommunications Authority Government of Cyprus",
    "OperatorLink": ""
  },
  {
    "Country": "Cyprus",
    "Rank": 3,
    "Operator": "PrimeTel",
    "Technology": "2100 MHz UMTS HSPA 900 1800 MHz LTE 2G 3G roaming on epic when needed",
    "Subscribers": "0.115 November 2019",
    "Owner": "PrimeTel PLC",
    "OperatorLink": ""
  },
  {
    "Country": "Cyprus",
    "Rank": 1,
    "Operator": "Kuzey K br s Turkcell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 1800 MHz UMTS HSPA 900 1800 MHz LTE",
    "Subscribers": "0.5 Q3 2021",
    "Owner": "Turkcell",
    "OperatorLink": "https://www.wikipedia.org/wiki/Kuzey_K%C4%B1br%C4%B1s_Turkcell"
  },
  {
    "Country": "Cyprus",
    "Rank": 2,
    "Operator": "Telsim Vodafone",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 1800 MHz UMTS",
    "Subscribers": "0.15 Q3 2021",
    "Owner": "Uzan Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/KKTC_Telsim"
  },
  {
    "Country": "Czech Republic",
    "Rank": 1,
    "Operator": "T Mobile Formerly Paegas",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 800 1800 2100 2600 MHz LTE LTE AVoLTE ViLTE VoWiFi1800 2100 MHz 5G NR",
    "Subscribers": "6.259 Q3 2021",
    "Owner": "Deutsche Telekom AG",
    "OperatorLink": ""
  },
  {
    "Country": "Czech Republic",
    "Rank": 2,
    "Operator": "O2 Formerly Eurotel esk Telecom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE CDMA EV DO 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "5.987 Q2 2021",
    "Owner": "PPF N.V.",
    "OperatorLink": ""
  },
  {
    "Country": "Czech Republic",
    "Rank": 3,
    "Operator": "Vodafone Formerly Oskar",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 800 900 1800 2100 2600 MHz LTE LTE AVoLTE ViLTE VoWiFi 1800 2100 2700 planning 5GNR",
    "Subscribers": "3.983 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Denmark",
    "Rank": 1,
    "Operator": "TDC",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA 800 2600 MHz LTEVoLTE3500 MHz 5G NR",
    "Subscribers": "3.157 Q3 2021",
    "Owner": "TDC",
    "OperatorLink": ""
  },
  {
    "Country": "Denmark",
    "Rank": 2,
    "Operator": "Telenor Formerly Sonofon",
    "Technology": "900 1800 GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA 800 900 1800 2100 MHz LTEVoLTE Shares network with Telia 3500 MHz 5G NR",
    "Subscribers": "1.688 Q3 2021",
    "Owner": "Telenor",
    "OperatorLink": ""
  },
  {
    "Country": "Denmark",
    "Rank": 3,
    "Operator": "Telia",
    "Technology": "900 1800 GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA 900 1800 2100 2600 MHz LTEVoLTE Shares network with Telenor",
    "Subscribers": "1.603 Q3 2021",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Denmark",
    "Rank": 4,
    "Operator": 3,
    "Technology": "900 2100 MHz UMTS HSDPA HSPA 1800 2100 2600 MHz LTEVoLTEVoWiFi Roams on TDC s GSM UMTS LTE network where 3 does not reach",
    "Subscribers": "1.482 Q2 2021",
    "Owner": "CK Hutchison Holdings 60% Investor AB 40%",
    "OperatorLink": ""
  },
  {
    "Country": "Estonia",
    "Rank": 1,
    "Operator": "Telia Formerly EMT",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS HSPA800 1800 2600 MHz LTEVoLTE",
    "Subscribers": "1.184 Q3 2021",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Estonia",
    "Rank": 2,
    "Operator": "Elisa Formerly Radiolinja",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS HSPA800 1800 2100 2600 MHz LTE",
    "Subscribers": "0.684 Q3 2021",
    "Owner": "Elisa",
    "OperatorLink": ""
  },
  {
    "Country": "Estonia",
    "Rank": 3,
    "Operator": "Tele2 Formerly Q GSM",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS HSPA800 1800 2100 MHz LTE",
    "Subscribers": "0.439 Q3 2021",
    "Owner": "Tele2",
    "OperatorLink": ""
  },
  {
    "Country": "Faroe Islands",
    "Rank": 1,
    "Operator": "F roya Tele",
    "Technology": "GSM 900 900 2100 MHz UMTS 1800 MHz LTE",
    "Subscribers": "0.045 74%",
    "Owner": "Government of Faroe Islands",
    "OperatorLink": ""
  },
  {
    "Country": "Faroe Islands",
    "Rank": 2,
    "Operator": "Hey Formerly Vodafone Kall",
    "Technology": "GSM 900 2100 MHz UMTS1800 MHz LTE",
    "Subscribers": "0.013 25%",
    "Owner": "Nema",
    "OperatorLink": ""
  },
  {
    "Country": "Faroe Islands",
    "Rank": 3,
    "Operator": "Tosa",
    "Technology": "GSM 900 2100 MHz UMTS1800 MHz LTE",
    "Subscribers": "0.0 0%",
    "Owner": "Tosa",
    "OperatorLink": ""
  },
  {
    "Country": "Finland",
    "Rank": 1,
    "Operator": "Elisa Formerly Radiolinja",
    "Technology": "GSM 900 1800 MHz EDGE 900 2100 MHz UMTS HSPA800 1800 2600 MHz LTE LTE AVoLTE3500 MHz 5G NR",
    "Subscribers": "4.239 Q3 2021",
    "Owner": "Elisa",
    "OperatorLink": ""
  },
  {
    "Country": "Finland",
    "Rank": 2,
    "Operator": "Telia Formerly Telecom Finland Sonera",
    "Technology": "GSM 900 1800 MHz EDGE 900 2100 MHz UMTS HSPA800 1800 2600 MHz LTE LTE AVoLTE3500 MHz 5G NR",
    "Subscribers": "3.216 Q3 2021",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Finland",
    "Rank": 3,
    "Operator": "DNA",
    "Technology": "GSM 900 1800 MHz EDGE 900 2100 MHz UMTS HSPA700 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "2.707 Q3 2021",
    "Owner": "Telenor",
    "OperatorLink": ""
  },
  {
    "Country": "Finland",
    "Rank": 4,
    "Operator": "lcom Formerly MT lands Mobiltelefon",
    "Technology": "GSM 900 MHz EDGE 900 2100 MHz UMTS HSPA800 1800 2600 MHz LTE LTE A",
    "Subscribers": "NA",
    "Owner": "Mariehamns Telefon lands Telefonandelslag",
    "OperatorLink": ""
  },
  {
    "Country": "France",
    "Rank": 1,
    "Operator": "Orange Formerly Itineris",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 700 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi 2100 3500 MHz 5G NR",
    "Subscribers": "35.099 Q3 2021",
    "Owner": "Orange S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "France",
    "Rank": 2,
    "Operator": "Bouygues Telecom",
    "Technology": "900 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA 700 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi 2100 3500 MHz 5G NR",
    "Subscribers": "21.603 Q3 2021",
    "Owner": "Bouygues Group 89.5%",
    "OperatorLink": ""
  },
  {
    "Country": "France",
    "Rank": 3,
    "Operator": "SFR",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 700 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi 2100 3500 MHz 5G NR",
    "Subscribers": "18.630 Q3 2021",
    "Owner": "Altice 80.4% free float 19.6%",
    "OperatorLink": ""
  },
  {
    "Country": "France",
    "Rank": 4,
    "Operator": "Free Mobile",
    "Technology": "900 2100 MHz UMTS HSPA HSPA Roaming on Orange 2G 3G when needed limited to 384 kbit s download 384 kbit s upload 700 1800 2600 MHz LTE LTE A 700 3500 MHz 5G NR",
    "Subscribers": "13.486 Q3 2021",
    "Owner": "Iliad S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Georgia",
    "Rank": 1,
    "Operator": "Magticom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 MHz LTE LTE AdvancedVoLTE",
    "Subscribers": "2 099 983 July 2021",
    "Owner": "International Tellcell LLC",
    "OperatorLink": ""
  },
  {
    "Country": "Georgia",
    "Rank": 2,
    "Operator": "Silknet Formerly Geocell",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA CDMA2000 EVDO Rev. A2300 MHz TD LTE LTE Advanced LTE Advanced Pro1800 MHz LTE",
    "Subscribers": "1.693263 July 2021",
    "Owner": "Silk Road Group",
    "OperatorLink": ""
  },
  {
    "Country": "Georgia",
    "Rank": 3,
    "Operator": "Beeline",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 MHz LTE LTE Advanced",
    "Subscribers": "1.291112 July 2021",
    "Owner": "Veon 51% GMC Group 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Germany",
    "Rank": 1,
    "Operator": "Telekom Formerly D1 DeTeMobil T Mobile",
    "Technology": "900 1800 MHz GSM EDGE 700 800 900 1500 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi2100 3600 MHz 5G NR",
    "Subscribers": "52.184 Q3 2021",
    "Owner": "Deutsche Telekom AG",
    "OperatorLink": ""
  },
  {
    "Country": "Germany",
    "Rank": 2,
    "Operator": "O2 Formerly VIAG Interkom Includes the previous E Plus network.",
    "Technology": "900 1800 MHz GSM GPRS EDGE 700 800 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "45.325 Q3 2021",
    "Owner": "Telef nica S.A. 69.2% free float 30.8%",
    "OperatorLink": ""
  },
  {
    "Country": "Germany",
    "Rank": 3,
    "Operator": "Vodafone Formerly D2 Mannesmann",
    "Technology": "900 MHz GSM GPRS EDGE 700 800 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3600 MHz 5G NR",
    "Subscribers": "31.151 Q3 2021",
    "Owner": "Vodafone Group plc",
    "OperatorLink": ""
  },
  {
    "Country": "Gibraltar",
    "Rank": 1,
    "Operator": "Gibtelecom",
    "Technology": "GSM 900 18002100 MHz UMTS 800 2600 MHz LTE LTE A",
    "Subscribers": "0.022 citation needed",
    "Owner": "Government of Gibraltar",
    "OperatorLink": ""
  },
  {
    "Country": "Greece",
    "Rank": 1,
    "Operator": "Cosmote",
    "Technology": "GSM 900 1800 EDGE 900 MHz 2100 MHz UMTS HSDPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "7.040 Q3 2021",
    "Owner": "OTE Deutsche Telekom 48.3%",
    "OperatorLink": ""
  },
  {
    "Country": "Greece",
    "Rank": 2,
    "Operator": "Vodafone Formerly Panafon Includes the previous Cyta Hellas network.",
    "Technology": "GSM 900 1800 EDGE 900 2100 MHz UMTS HSDPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "4.178 Q3 2021",
    "Owner": "Vodafone 99.8%",
    "OperatorLink": ""
  },
  {
    "Country": "Greece",
    "Rank": 3,
    "Operator": "Wind Formerly Telestet TIM Includes the previous Q Telecom network.",
    "Technology": "GSM 900 1800 EDGE 2100 UMTS HSDPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE3500 MHz 5G NR",
    "Subscribers": "3.700 Q2 2021",
    "Owner": "United Group",
    "OperatorLink": ""
  },
  {
    "Country": "Guernsey",
    "Rank": 1,
    "Operator": "Sure",
    "Technology": "GSM 900 2100 MHz UMTSLTE",
    "Subscribers": "Not yet available",
    "Owner": "Batelco",
    "OperatorLink": ""
  },
  {
    "Country": "Guernsey",
    "Rank": 2,
    "Operator": "JT",
    "Technology": "GSM 18002100 MHz UMTS",
    "Subscribers": "Not yet available",
    "Owner": "JT Group",
    "OperatorLink": ""
  },
  {
    "Country": "Guernsey",
    "Rank": 3,
    "Operator": "Airtel Vodafone",
    "Technology": "GSM 18002100 MHz UMTS",
    "Subscribers": "Not yet available",
    "Owner": "Bharti Airtel 50% Vodafone 50%",
    "OperatorLink": ""
  },
  {
    "Country": "Hungary",
    "Rank": 1,
    "Operator": "Telekom Formerly Westel T Mobile",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi LTE 800 MHz is in cooperating with Telenor 3600 MHz 5G NR",
    "Subscribers": "5.582 Q3 2021",
    "Owner": "Deutsche Telekom 59.21% Free Float 40.79%",
    "OperatorLink": ""
  },
  {
    "Country": "Hungary",
    "Rank": 2,
    "Operator": "Telenor Formerly Pannon",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE LTE 800 MHz is in cooperating with Telekom 3600 MHz 5G NR",
    "Subscribers": "3.449 Q2 2021",
    "Owner": "PPF",
    "OperatorLink": ""
  },
  {
    "Country": "Hungary",
    "Rank": 3,
    "Operator": "Vodafone",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 700 800 900 1800 2100 2600 MHz LTE LTE A3500 MHz 5G NR",
    "Subscribers": "3.055 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Hungary",
    "Rank": 4,
    "Operator": "Digi",
    "Technology": "GSM 18001800 MHz LTEVoLTE3600 MHz TD LTE3500 MHz 5G NR Seldom used some cells in Budapest",
    "Subscribers": "0.187 Q3 2021",
    "Owner": "4iG",
    "OperatorLink": ""
  },
  {
    "Country": "Iceland",
    "Rank": 1,
    "Operator": "S minn formerly Landss minn",
    "Technology": "GSM 900 1800 MHz 900 2100 MHz UMTS700 1800 2600 MHz LTE3600 MHz 5G NR",
    "Subscribers": "0.173 July 2019",
    "Owner": "S minn",
    "OperatorLink": ""
  },
  {
    "Country": "Iceland",
    "Rank": 2,
    "Operator": "Nova",
    "Technology": "2100 MHz UMTS HSDPA1800 MHz LTEVoLTE3500 MHz 5G NR",
    "Subscribers": "0.155 July 2019",
    "Owner": "Nova",
    "OperatorLink": ""
  },
  {
    "Country": "Iceland",
    "Rank": 3,
    "Operator": "Vodafone formerly Og Vodafone slandss mi",
    "Technology": "GSM 900 1800 MHz 2100 MHz UMTS800 1800 MHz LTE",
    "Subscribers": "0.130 July 2019",
    "Owner": "S n",
    "OperatorLink": ""
  },
  {
    "Country": "Ireland",
    "Rank": 1,
    "Operator": "Three Includes the previous O2 network",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 MHz LTE LTE A700 2100 MHz LTE LTE A Operating on a temporary licence 1800 3500 MHz 5G NRAML",
    "Subscribers": "2.777 Q2 2021",
    "Owner": "CK Hutchison Holdings",
    "OperatorLink": ""
  },
  {
    "Country": "Ireland",
    "Rank": 2,
    "Operator": "Vodafone Formerly Eircell",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 MHz LTE LTE A700 2100 MHz LTE LTE A Operating on a temporary licence 3500 MHz 5G NRVoLTE VoWiFi RCS AML",
    "Subscribers": "2.028 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Ireland",
    "Rank": 3,
    "Operator": "eir Formerly Meteor",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 MHz LTE LTE A700 2100 MHz LTE LTE A Operating on a temporary licence 1800 3500 MHz 5G NRVoLTE VoWiFi AML",
    "Subscribers": "1.206 Q3 2021",
    "Owner": "Iliad 31 6% NJJ 32 9%",
    "OperatorLink": ""
  },
  {
    "Country": "Isle of Man",
    "Rank": 1,
    "Operator": "Manx Telecom",
    "Technology": "GSM 900 2100 MHz UMTS800 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Free float AIM listed",
    "OperatorLink": ""
  },
  {
    "Country": "Isle of Man",
    "Rank": 2,
    "Operator": "Sure",
    "Technology": "GSM 900 1800 2100 MHz UMTS",
    "Subscribers": "NA",
    "Owner": "Batelco",
    "OperatorLink": ""
  },
  {
    "Country": "Italy",
    "Rank": 1,
    "Operator": "TIM Formerly SIP",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1500 1800 2600 MHz LTE LTE AVoLTE3700 MHz 5G NR Only in Rome and Turin",
    "Subscribers": "30.473 Q3 2021",
    "Owner": "TIM Group",
    "OperatorLink": ""
  },
  {
    "Country": "Italy",
    "Rank": 2,
    "Operator": "Wind Tre Formerly Wind and 3 Italy",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE A3500 MHz 5G NR",
    "Subscribers": "20.994 Q2 2021",
    "Owner": "CK Hutchison Holdings",
    "OperatorLink": ""
  },
  {
    "Country": "Italy",
    "Rank": 3,
    "Operator": "Vodafone Formerly Omnitel",
    "Technology": "900 1800 MHz GSM GPRS EDGE 800 1800 2100 2600 MHz LTE LTE AVoLTE3700 MHz 5G NR",
    "Subscribers": "18.146 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Italy",
    "Rank": 4,
    "Operator": "Iliad",
    "Technology": "900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 1800 2600 MHz LTE LTE A Roaming on Wind Tre when needed 3500 MHz 5G NR",
    "Subscribers": "8.165 Q3 2021",
    "Owner": "Iliad SA",
    "OperatorLink": ""
  },
  {
    "Country": "Italy",
    "Rank": 5,
    "Operator": "Fastweb",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1500 1800 2100 2600 MHz LTE LTE A Roaming on Wind Tre and TIM disambiguation needed 3500 MHz 5G NR",
    "Subscribers": "2.316 Q3 2021",
    "Owner": "Swisscom",
    "OperatorLink": ""
  },
  {
    "Country": "Jersey",
    "Rank": 1,
    "Operator": "JT",
    "Technology": "GSM 900 18002100 MHz UMTS 800 1800 2600 MHz LTE",
    "Subscribers": "NA",
    "Owner": "JT Group",
    "OperatorLink": ""
  },
  {
    "Country": "Jersey",
    "Rank": 2,
    "Operator": "Sure",
    "Technology": "GSM 900 18002100 MHz UMTS",
    "Subscribers": "NA",
    "Owner": "Batelco",
    "OperatorLink": ""
  },
  {
    "Country": "Jersey",
    "Rank": 3,
    "Operator": "Airtel Vodafone",
    "Technology": "GSM 900 18002100 MHz UMTS",
    "Subscribers": "NA",
    "Owner": "Bharti Airtel 50% Vodafone 50%",
    "OperatorLink": ""
  },
  {
    "Country": "Kosovo",
    "Rank": 1,
    "Operator": "Vala",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE LTE A",
    "Subscribers": "51.95% Q2 2018",
    "Owner": "Government of Kosovo",
    "OperatorLink": ""
  },
  {
    "Country": "Kosovo",
    "Rank": 2,
    "Operator": "Ipko",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE LTE A",
    "Subscribers": "36.07% Q2 2018",
    "Owner": "Telekom Slovenije",
    "OperatorLink": ""
  },
  {
    "Country": "Kosovo",
    "Rank": 3,
    "Operator": "mts",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE LTE A",
    "Subscribers": "1.65% Q2 2018",
    "Owner": "Telekom Srbija",
    "OperatorLink": ""
  },
  {
    "Country": "Latvia",
    "Rank": 1,
    "Operator": "LMT",
    "Technology": "GSM 900 MHz900 2100 MHz UMTS DC HSPA 800 1800 2100 2600 MHz LTE LTE A3500 MHz 5G NR",
    "Subscribers": "1.449 Q3 2021",
    "Owner": "Republic of Latvia and related 51% Telia 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Latvia",
    "Rank": 2,
    "Operator": "Tele2",
    "Technology": "GSM 900 MHz900 2100 MHz UMTS800 1800 2100 2600 MHz LTE LTE A3500 MHz 5G NR",
    "Subscribers": "1.005 Q3 2021",
    "Owner": "Tele2",
    "OperatorLink": ""
  },
  {
    "Country": "Latvia",
    "Rank": 3,
    "Operator": "Bite",
    "Technology": "GSM 900 MHz900 2100 MHz UMTS HSPA 800 1800 2100 2600 MHz LTE LTE A3500 MHz 5G NR",
    "Subscribers": "0.467 September 2012",
    "Owner": "Providence Equity Partners",
    "OperatorLink": ""
  },
  {
    "Country": "Latvia",
    "Rank": 4,
    "Operator": "Triatel",
    "Technology": "450 MHz CDMA2000",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": ""
  },
  {
    "Country": "Liechtenstein",
    "Rank": 1,
    "Operator": "FL1 de formerly Mobilkom Liechtenstein AG",
    "Technology": "GSM 900 1800 2100 MHz UMTS 800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "State owned",
    "OperatorLink": ""
  },
  {
    "Country": "Liechtenstein",
    "Rank": 2,
    "Operator": "7acht formerly Orange FL",
    "Technology": "GSM 900 18002100 MHz UMTS1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Salt Mobile SA",
    "OperatorLink": ""
  },
  {
    "Country": "Liechtenstein",
    "Rank": 3,
    "Operator": "Swisscom FL",
    "Technology": "GSM 900 1800 2100 MHz UMTS900 1800 2100 MHz LTEVoLTE",
    "Subscribers": "NA",
    "Owner": "Swisscom",
    "OperatorLink": ""
  },
  {
    "Country": "Lithuania",
    "Rank": 1,
    "Operator": "Tele2",
    "Technology": "GSM 900 1800 MHz EDGE 900 2100 MHz UMTS2600 MHz LTE",
    "Subscribers": "1.889 October 2020",
    "Owner": "Tele2",
    "OperatorLink": ""
  },
  {
    "Country": "Lithuania",
    "Rank": 2,
    "Operator": "Telia Formerly Omnitel Teo LT",
    "Technology": "GSM 900 MHz EDGE 2100 MHz UMTS HSPA 800 1800 2100 2600 MHz LTE LTE AVoLTE",
    "Subscribers": "1.385 October 2020",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Lithuania",
    "Rank": 3,
    "Operator": "Bite",
    "Technology": "GSM 900 1800 MHz EDGE 2100 MHz UMTS HSPA 800 1800 2600 MHz LTE",
    "Subscribers": "0.99 20.1% Oct 2013",
    "Owner": "Providence Equity Partners",
    "OperatorLink": ""
  },
  {
    "Country": "Lithuania",
    "Rank": 4,
    "Operator": "Lietuvos radijo ir televizijos centras MEZON",
    "Technology": "3500 MHz WiMAX 2300 MHz LTE",
    "Subscribers": "0.097 2% May 2020",
    "Owner": "State owned Pending sale to BITE",
    "OperatorLink": ""
  },
  {
    "Country": "Luxembourg",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 1800 EDGE 2100 MHz UMTS DC HSPA 1800 MHz LTE3500 MHz 5G NR",
    "Subscribers": "0.076 September 2007",
    "Owner": "Orange S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Luxembourg",
    "Rank": 2,
    "Operator": "Tango",
    "Technology": "GSM 900 18002100 MHz UMTS HSPA 1800 MHz LTEVoLTE3500 MHz 5G NR",
    "Subscribers": "0.280 December 2013",
    "Owner": "Proximus Group",
    "OperatorLink": ""
  },
  {
    "Country": "Luxembourg",
    "Rank": 3,
    "Operator": "POST",
    "Technology": "GSM 900 1800 2100 MHz UMTS HSDPA1800 MHz LTE3500 MHz 5G NR",
    "Subscribers": "0.250 citation needed",
    "Owner": "POST Luxembourg",
    "OperatorLink": ""
  },
  {
    "Country": "Luxembourg",
    "Rank": 4,
    "Operator": "Luxembourg Online",
    "Technology": "2100 MHz UMTS HSDPA",
    "Subscribers": "NA",
    "Owner": "Luxembourg Online",
    "OperatorLink": ""
  },
  {
    "Country": "Malta",
    "Rank": 1,
    "Operator": "Epic Formerly Vodafone Malta and Telecell",
    "Technology": "GSM 900 1800 MHz GPRS 900 2100 MHz UMTS HSDPA HSUPA800 1800 2600 MHz LTE LTE A LTE A Pro",
    "Subscribers": "0.302 February 2020",
    "Owner": "Monaco Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "Malta",
    "Rank": 2,
    "Operator": "GO Formerly MALTACOM",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA800 1800 2600 MHz LTE LTE A LTE A Pro",
    "Subscribers": "0.230 December 2013",
    "Owner": "Dubai Holding 40% Public 60%",
    "OperatorLink": ""
  },
  {
    "Country": "Malta",
    "Rank": 3,
    "Operator": "Melita",
    "Technology": "2100 MHz UMTS HSDPA800 1800 MHz LTE LTE A Pro",
    "Subscribers": "0.076 December 2013",
    "Owner": "EQT Partners",
    "OperatorLink": ""
  },
  {
    "Country": "Moldova",
    "Rank": 1,
    "Operator": "Orange",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE",
    "Subscribers": "2.097 Q3 2021",
    "Owner": "Orange S.A. 94.3%",
    "OperatorLink": ""
  },
  {
    "Country": "Moldova",
    "Rank": 2,
    "Operator": "Moldcell",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 1800 2600 MHz LTE",
    "Subscribers": "1.4 2020",
    "Owner": "CG Corp Global",
    "OperatorLink": ""
  },
  {
    "Country": "Moldova",
    "Rank": 3,
    "Operator": "Moldtelecom",
    "Technology": "450 MHz CDMA2000 EVDO Rev. A 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 1800 MHz LTE LTE A",
    "Subscribers": "0.459 2020",
    "Owner": "Moldtelecom",
    "OperatorLink": ""
  },
  {
    "Country": "Moldova",
    "Rank": 4,
    "Operator": "Interdnestrcom operating in Transnistria region only",
    "Technology": "800 MHz CDMA2000 EVDO Rev. A 800 MHz LTE",
    "Subscribers": "0.291 2012",
    "Owner": "Sheriff",
    "OperatorLink": ""
  },
  {
    "Country": "Monaco",
    "Rank": 1,
    "Operator": "Monaco Telecom",
    "Technology": "1800 MHz GSM 900 2100 MHz UMTS800 1800 2600 MHz LTE",
    "Subscribers": 0.021,
    "Owner": "NJJ Capital Xavier Niel 55% Soci t Nationale de Financement 45%",
    "OperatorLink": ""
  },
  {
    "Country": "Montenegro",
    "Rank": 1,
    "Operator": "Telekom Formerly Monet",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2600 MHz LTE",
    "Subscribers": "0.389 Q3 2021",
    "Owner": "Hrvatski Telekom 76.53% Deutsche Telekom 60.49% free float 23.47%",
    "OperatorLink": ""
  },
  {
    "Country": "Montenegro",
    "Rank": 2,
    "Operator": "Mtel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 1800 MHz LTE",
    "Subscribers": "0.344 Q1 2021",
    "Owner": "51% Telekom Srbija 49% Telekom Srpske",
    "OperatorLink": ""
  },
  {
    "Country": "Montenegro",
    "Rank": 3,
    "Operator": "Telenor Formerly Promonte",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 2600 MHz LTEWiMAX",
    "Subscribers": "0.327 Q2 2021",
    "Owner": "PPF",
    "OperatorLink": ""
  },
  {
    "Country": "Netherlands",
    "Rank": 1,
    "Operator": "T Mobile Includes the previous Tele2 and Orange networks.",
    "Technology": "2G discontinued on the 1st of June 2021900 2100 MHz UMTS HSDPA HSUPA HSPA 800 900 1800 2100 2600 MHz LTE LTE A2600 MHz TD LTEVoLTE VoWiFi AML 700 MHz 5G NR",
    "Subscribers": "6.853 Q2 2021",
    "Owner": "WP AP Telecom Holdings IV B.V. Warburg Pincus 50% Apax Partners 50%",
    "OperatorLink": ""
  },
  {
    "Country": "Netherlands",
    "Rank": 2,
    "Operator": "KPN Includes the previous Telfort network.",
    "Technology": "900 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 3G to be discontinued as of 31 March 2022 800 1500 1800 2100 2600 MHz LTE LTE A2600 MHz TD LTEVoLTE VoWiFi AML 700 2100 MHz 5G NR450 MHz CDMA Smart meters",
    "Subscribers": "5.563 Q3 2021",
    "Owner": "Koninklijke KPN N.V.",
    "OperatorLink": ""
  },
  {
    "Country": "Netherlands",
    "Rank": 3,
    "Operator": "Vodafone Formerly Libertel",
    "Technology": "900 1800 MHz GSM GPRS EDGE 700 800 1500 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi AML 1800 MHz 5G NR Dynamic Spectrum Sharing",
    "Subscribers": "5.328 Q3 2021",
    "Owner": "VodafoneZiggo",
    "OperatorLink": ""
  },
  {
    "Country": "North Macedonia",
    "Rank": 1,
    "Operator": "Telekom Formerly T Mobile MOBIMAK",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA800 1800 MHz LTE LTE A VoLTE",
    "Subscribers": "1.211 Q3 2021",
    "Owner": "Magyar Telekom 59.21% Deutsche Telekom 60.49%",
    "OperatorLink": ""
  },
  {
    "Country": "North Macedonia",
    "Rank": 2,
    "Operator": "A1 Formerly one.Vip",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSPA 800 1800 MHz LTE LTE A VoLTE 5G Test",
    "Subscribers": "1.084 Q2 2021",
    "Owner": "A1 Telekom Austria Group Am rica M vil 51% BAG 28 42% free float 20 58%",
    "OperatorLink": ""
  },
  {
    "Country": "Norway",
    "Rank": 1,
    "Operator": "Telenor",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 800 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "2.764 Q3 2021",
    "Owner": "Telenor",
    "OperatorLink": ""
  },
  {
    "Country": "Norway",
    "Rank": 2,
    "Operator": "Telia Formerly NetCom Includes the previous Tele2 network.",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 800 900 1800 2100 2600 MHz LTE LTE AVoLTE3500 MHz 5G NR",
    "Subscribers": "2.292 Q3 2021",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Norway",
    "Rank": 3,
    "Operator": "Ice Formerly Ice.net",
    "Technology": "450 700 800 900 1800 2100 MHz LTE Roams on Telias s network where there is no coverage",
    "Subscribers": "0.337 December 2016",
    "Owner": "Ice group",
    "OperatorLink": ""
  },
  {
    "Country": "Poland",
    "Rank": 1,
    "Operator": "Orange Formerly Idea",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz HSDPA HSUPA HSPA 800 1800 2100 2600 MHz LTE LTE A2100 MHz 5G NRVoLTE VoWiFi",
    "Subscribers": "16.590 Q3 2021",
    "Owner": "Orange S.A. 50.67% free float 49.33%",
    "OperatorLink": ""
  },
  {
    "Country": "Poland",
    "Rank": 2,
    "Operator": "Plus Formerly Plus GSM",
    "Technology": "CDMA 420 MHzGSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz HSDPA HSUPA HSPA DC HSPA 900 1800 2100 2600 MHz LTE LTE A 2600 MHz 5G NR TDDVoLTE VoWiFi",
    "Subscribers": "13.045 Q3 2021",
    "Owner": "Cyfrowy Polsat S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Poland",
    "Rank": 3,
    "Operator": "Play",
    "Technology": "E GSM GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz HSDPA HSUPA HSPA 800 1800 2100 2600 MHz LTE LTE A2100 MHz 5G NRVoLTE ViLTE VoWiFi for supported iOS Android devices Roaming 2G 3G LTE on Orange Poland network. Limited to 3 Mbit s download 1 Mbit s upload . available till 2025 on some areas already inactive",
    "Subscribers": "12.087 Q3 2021",
    "Owner": "Iliad S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Poland",
    "Rank": 4,
    "Operator": "T Mobile Formerly Era",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS 900 2100 MHz HSDPA HSUPA HSPA 800 1800 2100 2600 MHz LTE LTE A2100 MHz 5G NRVoLTE VoWiFi available till 2023",
    "Subscribers": "11.425 Q3 2021",
    "Owner": "Deutsche Telekom AG",
    "OperatorLink": ""
  },
  {
    "Country": "Portugal",
    "Rank": 1,
    "Operator": "MEO Formerly TMN",
    "Technology": "GSM 900 GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE A3600 MHz 5G NR",
    "Subscribers": "6.147 Q3 2021",
    "Owner": "Altice",
    "OperatorLink": ""
  },
  {
    "Country": "Portugal",
    "Rank": 2,
    "Operator": "NOS Formerly Optimus",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2100 2600 MHz LTE700 3500 MHz 5G NR",
    "Subscribers": "5.209 Q3 2021",
    "Owner": "NOS",
    "OperatorLink": ""
  },
  {
    "Country": "Portugal",
    "Rank": 3,
    "Operator": "Vodafone Formerly Telecel",
    "Technology": "GSM 900 GPRS EDGE 900 MHz UMTS HSPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE A 2600 MHz TD LTE VoLTE3500 MHz 5G NR",
    "Subscribers": "4.688 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Romania",
    "Rank": 1,
    "Operator": "Orange Formerly Dialog",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "9.860 Q3 2021",
    "Owner": "Orange S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Romania",
    "Rank": 2,
    "Operator": "Vodafone Formerly Connex",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 900 1800 2100 MHz LTE LTE AVoLTE ViLTE VoWiFi3500 MHz 5G NR",
    "Subscribers": "9.803 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Romania",
    "Rank": 3,
    "Operator": "Digi",
    "Technology": "GSM 900 2100 MHz UMTS HSPA HSPA 900 2100 MHz LTE 2600 MHz TD LTEVoLTE VoWiFi3600 MHz 5G NR",
    "Subscribers": "4.035 Q3 2021",
    "Owner": "RCS RDS",
    "OperatorLink": ""
  },
  {
    "Country": "Romania",
    "Rank": 4,
    "Operator": "Telekom Formerly Cosmorom Cosmote",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 900 1800 2100 MHz LTE",
    "Subscribers": "3.602 Q3 2021",
    "Owner": "OTE Deutsche Telekom 48.3%",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": "/wiki/Federal_subjects_of_Russia",
    "Operator": "/wiki/Republic_of_Crimea",
    "Technology": "/wiki/Sevastopol",
    "Subscribers": "",
    "Owner": "",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 1,
    "Operator": "MTS",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 800 900 1800 2600 MHz LTE VoLTE1800 2100 2600 4700 MHz 5G NR",
    "Subscribers": "79.7 Q3 2021",
    "Owner": "Sistema 43 23% MTS PJSC companies 13 76% free float 43 01%",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 2,
    "Operator": "MegaFon",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2100 2600 MHz LTE LTE A",
    "Subscribers": "70.5 Q1 2021",
    "Owner": "USM Holdings",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 3,
    "Operator": "Tele2 Russia",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 800 1800 2600 MHz LTECDMA2000 1x 450 MHz450 MHz LTE as Skylink CDMA2000 EV DO Rev. A 450 MHz NMT 450i 450 MHz",
    "Subscribers": "49.9 Q3 2020",
    "Owner": "Rostelecom",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 4,
    "Operator": "Beeline",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE A",
    "Subscribers": "49.7 Q3 2020",
    "Owner": "Veon LetterOne 47.9% The Stichting 8.3% free float 43.8%",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": "/wiki/Federal_subjects_of_Russia",
    "Operator": "/wiki/Republic_of_Crimea",
    "Technology": "/wiki/Sevastopol",
    "Subscribers": "",
    "Owner": "",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 5,
    "Operator": "Win Mobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 2600 MHz LTE",
    "Subscribers": "N A",
    "Owner": "K Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 6,
    "Operator": "Volna mobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 2600 MHz LTE",
    "Subscribers": "N A",
    "Owner": "KTK Telecom",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 7,
    "Operator": "Sevmobile",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 1800 MHz LTE",
    "Subscribers": "N A",
    "Owner": "Sevmobile",
    "OperatorLink": ""
  },
  {
    "Country": "Russia",
    "Rank": 8,
    "Operator": "Krymtelekom",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA",
    "Subscribers": "N A",
    "Owner": "Krymtelekom",
    "OperatorLink": ""
  },
  {
    "Country": "San Marino",
    "Rank": 1,
    "Operator": "Telefonia Mobile Sammarinese TMS",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE A Uses the Italian TIM network",
    "Subscribers": "0.019 February 2012",
    "Owner": "Gruppo TIM 51% Government of San Marino 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Serbia",
    "Rank": 1,
    "Operator": "mts",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA DC HSPA 800 1800 2100 MHz LTE LTE A LTE A Pro VoLTE 2600 3500 MHz 5G",
    "Subscribers": "3.898 Q4 2020",
    "Owner": "Telekom Srbija",
    "OperatorLink": ""
  },
  {
    "Country": "Serbia",
    "Rank": 2,
    "Operator": "Telenor",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA DC HSPA 800 1800 2100 MHz LTE LTE A LTE A Pro VoLTE ViLTE 2600 3500 MHz 5G",
    "Subscribers": "2.888 Q2 2021",
    "Owner": "PPF",
    "OperatorLink": ""
  },
  {
    "Country": "Serbia",
    "Rank": 3,
    "Operator": "A1 Formerly Vip",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA DC HSPA 800 1800 2100 MHz LTE LTE A LTE A Pro VoLTE",
    "Subscribers": "2.388 Q3 2021",
    "Owner": "A1 Telekom Austria Group Am rica M vil 51% BAG 28 42% free float 20 58%",
    "OperatorLink": ""
  },
  {
    "Country": "Slovakia",
    "Rank": 1,
    "Operator": "Telekom Formerly Eurotel T Mobile",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA DC HSPA 800 1800 2600 MHz LTE LTE AVoLTE RCS2100 MHz 5G NR",
    "Subscribers": "2.493 Q3 2021",
    "Owner": "Deutsche Telekom",
    "OperatorLink": ""
  },
  {
    "Country": "Slovakia",
    "Rank": 2,
    "Operator": "Orange Formerly Globtel Slovtel",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA DC HSPA Shares 3G with Swan Mobile 4ka 800 1800 2600 MHz LTE LTE AVoLTE VoWiFi RCS1800 3500 MHz 5G NR",
    "Subscribers": "2.490 Q3 2021",
    "Owner": "Orange S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Slovakia",
    "Rank": 3,
    "Operator": "O2 Formerly Telefonica O2 Telefonica",
    "Technology": "900 1800 MHz GSM GPRS EDGE Roaming on Telekom Slovakia 2G when needed 900 2100 MHz UMTS HSDPA800 1800 MHz LTE LTE A3500 3700 MHz TD LTE Planned transition to mobile 5G NR VoLTE VoWiFi1800 3700 MHz 5G NR",
    "Subscribers": "2.236 Q2 2021",
    "Owner": "PPF",
    "OperatorLink": ""
  },
  {
    "Country": "Slovakia",
    "Rank": 4,
    "Operator": "4ka SWAN Mobile",
    "Technology": "900 1800 MHz GSM GPRS EDGE Roaming on Orange Slovakia 2G when needed 900 2100 MHz UMTS HSDPA DC HSPA Shares 3G with Orange Slovakia 1800 MHz LTEVoLTE ViLTE1800 3700 MHz 5G NR",
    "Subscribers": "0.557 2Q 2020",
    "Owner": "SWAN Mobile a. s.",
    "OperatorLink": ""
  },
  {
    "Country": "Slovenia",
    "Rank": 1,
    "Operator": "Telekom Slovenije formerly Mobitel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA HSPA DC HSPA 800 900 1800 2600 MHz LTE LTE AVoLTE VoWiFi2600 3600 MHz 5G NR",
    "Subscribers": "0.964 Q3 2021 Including Izimobil Spar Mobil Hip mobil Mega M Novatel MOBIL SoftNet mobil",
    "Owner": "Telekom Slovenije",
    "OperatorLink": ""
  },
  {
    "Country": "Slovenia",
    "Rank": 2,
    "Operator": "A1 formerly Si.mobil",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE AVoLTE3600 MHz 5G NR",
    "Subscribers": "0.832 Q3 2021 Including bob Hotmobil",
    "Owner": "A1 Telekom Austria Group",
    "OperatorLink": ""
  },
  {
    "Country": "Slovenia",
    "Rank": 3,
    "Operator": "Telemach formerly Tu mobil",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA Roams on Telekom Slovenije 2G 3G where there is no Telemach coverage 800 1800 2100 MHz LTE LTE AVoLTE700 3600 MHz 5G NR",
    "Subscribers": "0.625 Q3 2021",
    "Owner": "United Group",
    "OperatorLink": ""
  },
  {
    "Country": "Slovenia",
    "Rank": 4,
    "Operator": "T 2",
    "Technology": "2100 MHz UMTS HSPA Roaming on Telekom Slovenije 2G 3G LTE network when needed 2100 MHz LTE3600 MHz 5G NR testing",
    "Subscribers": "0.169 Q3 2021",
    "Owner": "Gratel 87%",
    "OperatorLink": ""
  },
  {
    "Country": "Spain",
    "Rank": 1,
    "Operator": "Movistar",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE A1800 2100 3500 MHz 5G NRVoLTEVoWiFiViLTE",
    "Subscribers": "15.974 Oct 2021",
    "Owner": "Telef nica",
    "OperatorLink": ""
  },
  {
    "Country": "Spain",
    "Rank": 2,
    "Operator": "Orange Formerly Amena",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE AVoLTEVoWiFi3500 MHz 5G NR",
    "Subscribers": "12.846 Oct 2021",
    "Owner": "Orange",
    "OperatorLink": ""
  },
  {
    "Country": "Spain",
    "Rank": 3,
    "Operator": "Vodafone Formerly Airtel",
    "Technology": "900 1800 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE AVoLTE 3700 MHz 5G NR",
    "Subscribers": "12.457 Oct 2021",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "Spain",
    "Rank": 4,
    "Operator": "Yoigo",
    "Technology": "1800 MHz GSM GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA 1800 2100 MHz LTE Roams on Orange and Movistar where there is no Yoigo coverage 3500 MHz 5G NR",
    "Subscribers": "11.321 Oct 2021",
    "Owner": "Grupo M SM VIL",
    "OperatorLink": ""
  },
  {
    "Country": "Sweden",
    "Rank": 1,
    "Operator": "Telia",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSDPA800 1800 2600 MHz LTE LTE A 700 3500 MHz 5G NR",
    "Subscribers": "6.689 Q3 2021",
    "Owner": "Telia Company",
    "OperatorLink": ""
  },
  {
    "Country": "Sweden",
    "Rank": 2,
    "Operator": "Tele2",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA800 900 1800 2600 MHz LTE LTE A 3500 5G NR",
    "Subscribers": "2.962 Q3 2021",
    "Owner": "Tele2",
    "OperatorLink": ""
  },
  {
    "Country": "Sweden",
    "Rank": 3,
    "Operator": "Telenor Formerly Vodafone",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA800 900 1800 2600 MHz LTE LTE A 3500 5G NR",
    "Subscribers": "2.741 Q3 2021",
    "Owner": "Telenor",
    "OperatorLink": ""
  },
  {
    "Country": "Sweden",
    "Rank": 4,
    "Operator": "Tre",
    "Technology": "900 2100 MHz UMTS HSDPA800 2100 2600 MHz FD LTE 2600 MHz TD LTE LTE A 2100 2600 3500 MHz 5G NRVoLTE VoWiFi",
    "Subscribers": "2.267 Q2 2021",
    "Owner": "CK Hutchison Holdings 60% Investor AB 40%",
    "OperatorLink": ""
  },
  {
    "Country": "Switzerland",
    "Rank": 1,
    "Operator": "Swisscom",
    "Technology": "900 MHz UMTS HSPA HSPA DC HSPA 700 800 900 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi700 2100 3500 MHz 5G NR",
    "Subscribers": 6.378,
    "Owner": "Partially State owned 51% free float 49%",
    "OperatorLink": ""
  },
  {
    "Country": "Switzerland",
    "Rank": 2,
    "Operator": "Sunrise Formerly diAx",
    "Technology": "GSM 900 GPRS EDGE 900 2100 MHz UMTS HSDPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE AVoLTE3500 MHz 5G NR",
    "Subscribers": "2.590 Q3 2021",
    "Owner": "Liberty Global",
    "OperatorLink": ""
  },
  {
    "Country": "Switzerland",
    "Rank": 3,
    "Operator": "Salt formerly Orange CH",
    "Technology": "GSM 900 1800 GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 1800 2100 2600 MHz LTE LTE AVoLTE VoWiFi",
    "Subscribers": "2.141 Q3 2014",
    "Owner": "NJJ Capital",
    "OperatorLink": ""
  },
  {
    "Country": "Turkey",
    "Rank": 1,
    "Operator": "Turkcell",
    "Technology": "GSM 900 MHz GPRS EDGE 900 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 3C HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi5G testing",
    "Subscribers": "39.300 Q3 2021",
    "Owner": "Turkey Wealth Fund 26.2% LetterOne 19.8% free float 54%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Turkcell"
  },
  {
    "Country": "Turkey",
    "Rank": 2,
    "Operator": "Vodafone Formerly Telsim",
    "Technology": "GSM 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi 5G testing",
    "Subscribers": "24.699 Q3 2021",
    "Owner": "Vodafone",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vodafone_Turkey"
  },
  {
    "Country": "Turkey",
    "Rank": 3,
    "Operator": "T rk Telekom Formerly Avea Aria Aycell",
    "Technology": "GSM 900 only in rural areas 1800 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 800 900 1800 2100 2600 MHz LTE LTE A LTE A Pro VoLTE VoWiFi5G testing",
    "Subscribers": "23.900 Q3 2021",
    "Owner": "T rk Telekom",
    "OperatorLink": "https://www.wikipedia.org/wiki/T%C3%BCrk_Telekom"
  },
  {
    "Country": "Ukraine",
    "Rank": 1,
    "Operator": "Kyivstar Including previous Beeline Ukraine network",
    "Technology": "900 1800 MHz GSM GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 900 1800 2600 MHz LTE LTE AVoLTE",
    "Subscribers": "25.9 2Q 2021",
    "Owner": "Veon",
    "OperatorLink": ""
  },
  {
    "Country": "Ukraine",
    "Rank": 2,
    "Operator": "Vodafone Formerly MTS Ukraine",
    "Technology": "900 1800 MHz GSM GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 900 1800 2600 MHz LTE LTE A",
    "Subscribers": "18.9 2Q 2021",
    "Owner": "Bakcell",
    "OperatorLink": ""
  },
  {
    "Country": "Ukraine",
    "Rank": 3,
    "Operator": "Lifecell Formerly Life",
    "Technology": "900 1800 MHz GSM GPRS EDGE 2100 MHz UMTS HSDPA HSUPA HSPA HSPA DC HSPA 3C HSPA 900 1800 2600 MHz LTE LTE A LTE A Pro",
    "Subscribers": "9.9 Q3 2021",
    "Owner": "Turkcell",
    "OperatorLink": ""
  },
  {
    "Country": "Ukraine",
    "Rank": 4,
    "Operator": "Intertelecom",
    "Technology": "800 MHz CDMA2000 CDMA2000 EV DO rel.0 rev. A rev. B The gradual shutdown of the network began in many regions since 2020",
    "Subscribers": "1.4 3Q 2017",
    "Owner": "Odinaco Ltd 49% Victor Gushan 35.7%",
    "OperatorLink": ""
  },
  {
    "Country": "Ukraine",
    "Rank": 5,
    "Operator": "TriMob",
    "Technology": "2100 MHz UMTS HSDPA HSUPA HSPA Own 3G network in Kyiv city centre only. Free 2G 3G roaming on Vodafone network available",
    "Subscribers": "0.300 2Q 2018 excluding Lycamobile",
    "Owner": "Ukrtelecom",
    "OperatorLink": ""
  },
  {
    "Country": "Ukraine",
    "Rank": 6,
    "Operator": "PEOPLEnet",
    "Technology": "800 MHz CDMA2000 CDMA2000 EV DO Network in Dnipropetrovsk region only",
    "Subscribers": "0.853 4Q 2012",
    "Owner": "Telesystemy Ukrainy",
    "OperatorLink": ""
  },
  {
    "Country": "United Kingdom",
    "Rank": 1,
    "Operator": "O2",
    "Technology": "900 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 700 800 900 1800 2100 MHz LTE LTE A2300 2600 MHz TD LTE700 3500 MHz 5G NRVoLTE VoWiFi AML RCS",
    "Subscribers": "24.1 Q3 2021 Includes Virgin Mobile",
    "Owner": "Virgin Media O2",
    "OperatorLink": ""
  },
  {
    "Country": "United Kingdom",
    "Rank": 2,
    "Operator": "EE Formerly Orange T Mobile",
    "Technology": "1800 MHz GSM GPRS EDGE 2100 MHz UMTS HSPA HSPA 800 1800 2100 2600 MHz LTE LTE A700 2100 3500 MHz 5G NRVoLTE VoWiFi AML",
    "Subscribers": "21.4 Q3 2021 Includes BT Mobile and Plusnet Mobile",
    "Owner": "BT Group",
    "OperatorLink": ""
  },
  {
    "Country": "United Kingdom",
    "Rank": 3,
    "Operator": "Vodafone",
    "Technology": "900 MHz GSM GPRS EDGE 900 2100 MHz UMTS HSPA HSPA DC HSPA 800 900 1500 1800 2100 2600 MHz LTE LTE A2600 MHz TD LTE2100 3500 MHz 5G NRVoLTE VoWiFi AML RCS",
    "Subscribers": "17.0 Q3 2021 Includes VOXI",
    "Owner": "Vodafone",
    "OperatorLink": ""
  },
  {
    "Country": "United Kingdom",
    "Rank": 4,
    "Operator": "Three",
    "Technology": "2100 MHz UMTS HSPA HSPA DC HSPA 700 800 1500 1800 2100 MHz LTE LTE A3500 MHz 5G NRVoLTE VoWiFi AML",
    "Subscribers": "9.4 Q2 2021 Includes SMARTY",
    "Owner": "CK Hutchison Holdings",
    "OperatorLink": ""
  },
  {
    "Country": "Anguilla",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "850 1900 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Anguilla",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "850 1900 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Antigua and Barbuda",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM GPRS EDGE 900 UMTS HSPA 1900 2 850 5 LTE 700 17",
    "Subscribers": "0.062 Sep 2015",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Antigua and Barbuda",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "GSM GPRS EDGE 850 UMTS HSPA 1900 2 850 5 LTE 1900 2 1700 4",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Antigua and Barbuda",
    "Rank": 3,
    "Operator": "APUA inet",
    "Technology": "GSM 850",
    "Subscribers": "NA",
    "Owner": "Antigua Public Utilities Authority",
    "OperatorLink": ""
  },
  {
    "Country": "Argentina",
    "Rank": 1,
    "Operator": "Claro",
    "Technology": "GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 1700 B4 700 B28 MHz LTE",
    "Subscribers": "21.3 October 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Argentina",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "GSM 850 1900 GPRS EDGE 1900 MHz UMTS HSDPA HSPA 1700 MHz LTE",
    "Subscribers": "20.8 Dec 2016",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Argentina",
    "Rank": 3,
    "Operator": "Personal",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSDPA HSPA 2600 B7 1700 B4 700 B28 MHz LTE",
    "Subscribers": "18.8 May 2020",
    "Owner": "Telecom Argentina",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telecom_Personal"
  },
  {
    "Country": "Argentina",
    "Rank": 4,
    "Operator": "Tuenti using Movistar network",
    "Technology": "",
    "Subscribers": "0.040 Nov 2014",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org#cite_note-8"
  },
  {
    "Country": "Argentina",
    "Rank": 5,
    "Operator": "Nuestro using Personal network",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "FECOSUR",
    "OperatorLink": "https://www.wikipedia.org#cite_note-11"
  },
  {
    "Country": "Aruba",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "CdmaOne GSM 900 1800 2100 MHz UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Aruba",
    "Rank": 2,
    "Operator": "MIO",
    "Technology": "CDMA 2000 EV DO",
    "Subscribers": "NA",
    "Owner": "DTH Television and telecommunications N.V.",
    "OperatorLink": ""
  },
  {
    "Country": "Aruba",
    "Rank": 3,
    "Operator": "SetarNV",
    "Technology": "GSM 900 GPRS EDGE 850 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Setar N.V.",
    "OperatorLink": "https://www.wikipedia.org/wiki/SetarNV"
  },
  {
    "Country": "Bahamas",
    "Rank": 1,
    "Operator": "BTC",
    "Technology": "GSM 850 1900 GPRS EDGE 850 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "0.31 March 2017",
    "Owner": "Liberty Latin America 49% of ownership 51% of voting rights",
    "OperatorLink": "https://www.wikipedia.org/wiki/BTC_(Bahamas)"
  },
  {
    "Country": "Bahamas",
    "Rank": 2,
    "Operator": "Aliv",
    "Technology": "850 1900 MHz UMTS HSPA 700 1900 2100 MHz LTE",
    "Subscribers": "0.15 June 2019",
    "Owner": "Cable Bahamas Government of the Bahamas",
    "OperatorLink": "https://www.wikipedia.org/wiki/Aliv"
  },
  {
    "Country": "Barbados",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM 1900UMTS HSPA 1900 2 850 5 LTE 1900 2 1800 3 850 5",
    "Subscribers": "0.13 March 2017",
    "Owner": "Liberty Latin America 81%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Barbados",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800UMTS HSPA 2100 1 900 8 LTE 1900 2 700 12 17",
    "Subscribers": "NA",
    "Owner": "Digicel 75 %",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Belize",
    "Rank": 1,
    "Operator": "Digi",
    "Technology": "GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSPA LTE Band 2 1900 17 700",
    "Subscribers": "0.120 June 2007",
    "Owner": "Belize Telemedia Limited",
    "OperatorLink": "https://www.wikipedia.org#cite_note-22"
  },
  {
    "Country": "Belize",
    "Rank": 2,
    "Operator": "Smart",
    "Technology": "CdmaOneCDMA2000 1x CDMA2000 EV DO LTE",
    "Subscribers": "0.025 Dec 2005",
    "Owner": "Speednet",
    "OperatorLink": "https://www.wikipedia.org/wiki/Speednet"
  },
  {
    "Country": "Bermuda",
    "Rank": 1,
    "Operator": "Cell One",
    "Technology": "CdmaOneCDMA2000 1xRTT EV DOGSM 1900UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Bermuda Digital Communications Ltd",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cell_One"
  },
  {
    "Country": "Bermuda",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 1900 850 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Bolivia",
    "Rank": 1,
    "Operator": "Entel",
    "Technology": "GSM 1900 GPRS EDGE 850 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "5.0 Dec 2015",
    "Owner": "Ministry of Public Works Services and Housing 97.4671% and 1200 other shareholders 2.5329%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Entel_Bolivia"
  },
  {
    "Country": "Bolivia",
    "Rank": 2,
    "Operator": "Tigo",
    "Technology": "D AMPS GSM 850 GPRS EDGE 850 MHz UMTS HSDPALTE",
    "Subscribers": "3.371 July 2020",
    "Owner": "Millicom 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Bolivia",
    "Rank": 3,
    "Operator": "Viva",
    "Technology": "GSM 1900 GPRS EDGE 850 MHz UMTS",
    "Subscribers": "2.2 Dec 2016",
    "Owner": "Trilogy International Partners 71.5% and COMTECO R.L. Cooperativa de Telecomunicaciones Cochabamba R.L. 28.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Bonaire",
    "Rank": 1,
    "Operator": "Digicel formerly TELBO",
    "Technology": "GSM 900 1800 MHz GPRS EDGE UMTS HSPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Bonaire",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "GSMUMTS HSDPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/FLOW_(brand)"
  },
  {
    "Country": "Brazil",
    "Rank": 1,
    "Operator": "Vivo",
    "Technology": "GSM 900 1800 GPRS EDGE 850 2100 MHz UMTS HSDPA HSPA 700 1800 2600 MHz LTE LTE A LTE A Pro450 MHz LTE M",
    "Subscribers": "76.7 Oct 2020",
    "Owner": "Telef nica 73.68%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vivo_(telecommunications)"
  },
  {
    "Country": "Brazil",
    "Rank": 2,
    "Operator": "Claro Includes Nextel Claro nxt",
    "Technology": "GSM 900 1800 GPRS EDGE 850 2100 MHz UMTS HSDPA HSPA 700 1800 2100 2600 MHz LTE LTE A LTE A Pro450 MHz LTE M2600 MHz 5G NR Dynamic Spectrum Sharing",
    "Subscribers": "60.0 Oct 2020",
    "Owner": "Am rica M vil",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Brasil"
  },
  {
    "Country": "Brazil",
    "Rank": 3,
    "Operator": "TIM",
    "Technology": "GSM 900 1800 GPRS EDGE 850 900 2100 MHz UMTS HSDPA HSPA 700 850 1800 2100 2600 MHz LTE LTE A LTE A Pro450 MHz LTE M",
    "Subscribers": "52 Jun 2020",
    "Owner": "Telecom Italia 67%",
    "OperatorLink": "https://www.wikipedia.org/wiki/TIM_Brasil"
  },
  {
    "Country": "Brazil",
    "Rank": 4,
    "Operator": "Oi",
    "Technology": "GSM 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSPA 1800 2100 2600 MHz LTE LTE A LTE A Pro450 MHz LTE M",
    "Subscribers": "36.6 Jun 2020",
    "Owner": "Free float 76.36%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Oi_(telecommunications)"
  },
  {
    "Country": "Brazil",
    "Rank": 5,
    "Operator": "Algar Telecom Only in some areas of GO MG MS and SP states",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA HSPA 700 1800 MHz LTE roams on Surf where there s no Algar coverage",
    "Subscribers": "2.1 Jun 2020",
    "Owner": "Grupo Algar",
    "OperatorLink": "https://www.wikipedia.org/wiki/Algar_Telecom"
  },
  {
    "Country": "Brazil",
    "Rank": 6,
    "Operator": "Surf Only in city of S o Paulo Operates nationally as MVNE using TIM and Oi networks",
    "Technology": "2600 MHz TDD LTE",
    "Subscribers": "0.4 Jun 2020",
    "Owner": "Surf Telecom S.A.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_virtual_network_enabler"
  },
  {
    "Country": "Brazil",
    "Rank": 7,
    "Operator": "SKY 4G domestic broadband",
    "Technology": "2600 MHz TDD LTE",
    "Subscribers": "0.2 Jun 2020",
    "Owner": "AT T Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/SKY_Brasil"
  },
  {
    "Country": "Brazil",
    "Rank": 8,
    "Operator": "Sercomtel Only in some areas of PR",
    "Technology": "GSM 900 1800 850 MHz UMTS HSDPA",
    "Subscribers": "0.05 Jun 2020",
    "Owner": "Bordeaux Fundo de Investimento",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sercomtel"
  },
  {
    "Country": "Brazil",
    "Rank": 9,
    "Operator": "Datora Using TIM Operates as MVNE",
    "Technology": "Same frequency table of TIM",
    "Subscribers": "0.5 Jun 2020",
    "Owner": "Datora Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_virtual_network_enabler"
  },
  {
    "Country": "Brazil",
    "Rank": 10,
    "Operator": "Cinco Using Claro M2M and IoT only",
    "Technology": "Same frequency table of Claro",
    "Subscribers": "0.3 Jun 2020",
    "Owner": "Safra Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Machine_to_machine"
  },
  {
    "Country": "Brazil",
    "Rank": 11,
    "Operator": "Americanet Using TIM Operates as MVNE",
    "Technology": "Same frequency table of TIM",
    "Subscribers": "NA",
    "Owner": "Americanet Ltda.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Mobile_virtual_network_enabler"
  },
  {
    "Country": "Brazil",
    "Rank": 12,
    "Operator": "Correios Celular Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Correios",
    "OperatorLink": "https://www.wikipedia.org/wiki/Correios"
  },
  {
    "Country": "Brazil",
    "Rank": 13,
    "Operator": "Geek Celular Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Surf Telecom S.A.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 14,
    "Operator": "ARQIA Using TIM M2M and IoT only",
    "Technology": "Same frequency table of Datora",
    "Subscribers": "NA",
    "Owner": "Datora Telecom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Machine_to_machine"
  },
  {
    "Country": "Brazil",
    "Rank": 15,
    "Operator": "MAGA Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Magazine Luiza",
    "OperatorLink": "https://www.wikipedia.org/wiki/Magazine_Luiza"
  },
  {
    "Country": "Brazil",
    "Rank": 16,
    "Operator": "Eseye Using TIM M2M and IoT only",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Eseye Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Machine_to_machine"
  },
  {
    "Country": "Brazil",
    "Rank": 17,
    "Operator": "Gospelcel Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Gospelcel",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 18,
    "Operator": "CITY Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "City Transporte Intermodal Ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 19,
    "Operator": "Parais polis Celular Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Parais polis Celular Ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 20,
    "Operator": "INTERCEL Using TIM and Oi",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Banco Inter S.A.",
    "OperatorLink": "https://pt.wikipedia.org/wiki/Banco_Inter"
  },
  {
    "Country": "Brazil",
    "Rank": 21,
    "Operator": "Veek Using TIM",
    "Technology": "Same frequency table of Americanet",
    "Subscribers": "NA",
    "Owner": "Lanis Redes e Consultoria Ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 22,
    "Operator": "Uni global Using Vivo and Claro",
    "Technology": "Same frequency table of Vivo and Claro",
    "Subscribers": "NA",
    "Owner": "UNI Global Union",
    "OperatorLink": "https://www.wikipedia.org/wiki/UNI_Global_Union"
  },
  {
    "Country": "Brazil",
    "Rank": 23,
    "Operator": "fluke Using Vivo",
    "Technology": "Same frequency table of Vivo",
    "Subscribers": "NA",
    "Owner": "Fluke Telefonia M vel Celular Ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 24,
    "Operator": "INFINITI Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Infiniti Tecnologia em comunica o ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 25,
    "Operator": "Cart o de TODOS Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "TODOS Empreendimentos ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 26,
    "Operator": "Conlife Telecom Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Farma Conde ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "Brazil",
    "Rank": 27,
    "Operator": "Vestcasa Celular Using TIM",
    "Technology": "Same frequency table of Surf",
    "Subscribers": "NA",
    "Owner": "Vestcasa Baher Comercial Importadora e Exportadora ltda.",
    "OperatorLink": ""
  },
  {
    "Country": "British Virgin Islands",
    "Rank": 1,
    "Operator": "CCT",
    "Technology": "GSM 850 1900850 MHz UMTS LTE",
    "Subscribers": "NA",
    "Owner": "CCT Global Communications",
    "OperatorLink": "https://www.wikipedia.org#cite_note-46"
  },
  {
    "Country": "British Virgin Islands",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "GSM 850 1900 GPRS EDGE 850 MHz UMTS HSPA 700 1900 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "British Virgin Islands",
    "Rank": 3,
    "Operator": "Digicel",
    "Technology": "GSM 1900 1900 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicel 85%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Canada",
    "Rank": 1,
    "Operator": "Rogers includes Fido and Chatr",
    "Technology": "GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSPA HSPA 600 71 700 12 13 17 850 5 1700 4 1900 2 2600 7 MHz LTE LTE A600 n71 1700 n66 2600 n38 n41 MHz NR",
    "Subscribers": "11.002 Q2 2021",
    "Owner": "Rogers Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/Rogers_Wireless"
  },
  {
    "Country": "Canada",
    "Rank": 2,
    "Operator": "Telus Includes Koodo and Public Mobile",
    "Technology": "UMTS HSPA DC HSPA 600 71 700 12 13 17 29 850 5 1900 2 1700 4 2600 7 MHz LTE LTE A1700 n66 MHz NR",
    "Subscribers": "10.600 Q3 2020",
    "Owner": "Telus Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telus_Mobility"
  },
  {
    "Country": "Canada",
    "Rank": 3,
    "Operator": "Bell includes MTS and Virgin Mobile",
    "Technology": "UMTS HSPA DC HSPA 700 12 13 17 29 850 5 1900 2 1700 4 2600 7 MHz LTE LTE A1700 n66 MHz NR",
    "Subscribers": "9.213 Q2 2021",
    "Owner": "Bell Canada",
    "OperatorLink": "https://www.wikipedia.org/wiki/Bell_Mobility"
  },
  {
    "Country": "Canada",
    "Rank": 4,
    "Operator": "Freedom Mobile Includes Shaw Mobile",
    "Technology": "1700 2100 4 MHz AWS UMTS HSPA DC HSPA LTE1700 2100 66 MHz AWS 3 1900 2100 MHz AWS 1 2600 MHz LTE",
    "Subscribers": "1.881 Q4 2020",
    "Owner": "Shaw Communications",
    "OperatorLink": "https://www.wikipedia.org/wiki/Freedom_Mobile"
  },
  {
    "Country": "Canada",
    "Rank": 5,
    "Operator": "Vid otron",
    "Technology": "1700 MHz UMTS HSPA DC HSPA 700 1700 MHz LTE",
    "Subscribers": "1.05 Q1 2016",
    "Owner": "Quebecor",
    "OperatorLink": "https://www.wikipedia.org/wiki/Vid%C3%A9otron_Mobile"
  },
  {
    "Country": "Canada",
    "Rank": 6,
    "Operator": "SaskTel",
    "Technology": "850 1900 MHz UMTS HSPA DC HSPA 1700 MHz LTE",
    "Subscribers": "0.618 Q1 2016",
    "Owner": "SaskTel",
    "OperatorLink": "https://www.wikipedia.org/wiki/SaskTel_Mobility"
  },
  {
    "Country": "Canada",
    "Rank": 7,
    "Operator": "TNW Wireless",
    "Technology": "850 MHz UMTS HSPA HSPA 850 MHz",
    "Subscribers": "N A Q1 2017",
    "Owner": "TNW Wireless Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/UMTS"
  },
  {
    "Country": "Cayman Islands",
    "Rank": 1,
    "Operator": "FLOW Cayman",
    "Technology": "GSM 850 1900850 MHz UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Cayman Islands",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "CdmaOne GSM 900 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Digicelee 96.875%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Chile",
    "Rank": 1,
    "Operator": "Entel",
    "Technology": "GSM 1900 GPRS EDGE 900 1900 MHz UMTS HSDPA DC HSPA 700 28 1900 2 2600 7 MHz LTE FDD LTE A",
    "Subscribers": "7.46 June 2020",
    "Owner": "Almendral Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Entel_(Chile)"
  },
  {
    "Country": "Chile",
    "Rank": 2,
    "Operator": "Movistar formerly Telef nica M vil Includes the previous Bellsouth network.",
    "Technology": "Previously D AMPS GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA DC HSPA 700 28 2600 7 MHz LTE FDD 2600 38 MHz LTE TDD LTE A",
    "Subscribers": "6.16 June 2020",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Chile",
    "Rank": 3,
    "Operator": "Claro formerly Smartcom",
    "Technology": "Previously CdmaOne CDMA2000 1x CDMA2000 EV DO GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA DC HSPA 700 28 1900 2 2600 7 MHz LTE FDD LTE A",
    "Subscribers": "5.36 June 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Chile",
    "Rank": 4,
    "Operator": "WOM formerly Nextel",
    "Technology": "Previously iDEN 800 MHz 1700 2100 AWS 700 28 MHz UMTS HSDPA DC HSPA LTE Roams on Movistar and Claro using 2G and 3G technologies where its native network is not available.",
    "Subscribers": "4.86 June 2020",
    "Owner": "Novator",
    "OperatorLink": "https://www.wikipedia.org/wiki/IDEN"
  },
  {
    "Country": "Chile",
    "Rank": 5,
    "Operator": "VTR M vil using Movistar",
    "Technology": "1700 2100 MHz AWS MHz UMTS HSDPA DC HSPA Native network is shut down as of 2013. Mobile services are provided exclusively through Movistar network.",
    "Subscribers": "0.291 June 2020",
    "Owner": "Liberty Latin America 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/VTR_(telecom_company)"
  },
  {
    "Country": "Chile",
    "Rank": 6,
    "Operator": "Virgin Mobile using Movistar",
    "Technology": "",
    "Subscribers": "0.181 June 2020",
    "Owner": "Virgin Mobile Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Virgin_Mobile_Chile"
  },
  {
    "Country": "Chile",
    "Rank": 7,
    "Operator": "Simple using Movistar",
    "Technology": "",
    "Subscribers": "0.0303 March 2019",
    "Owner": "Simple SpA",
    "OperatorLink": "https://www.wikipedia.org#cite_note-Subtel_Chile-57"
  },
  {
    "Country": "Chile",
    "Rank": 8,
    "Operator": "Telsur GTD M vil using Movistar",
    "Technology": "",
    "Subscribers": "0.00836 March 2019",
    "Owner": "Grupo GTD",
    "OperatorLink": "https://www.wikipedia.org#cite_note-Subtel_Chile-57"
  },
  {
    "Country": "Chile",
    "Rank": 9,
    "Operator": "Netline using Movistar",
    "Technology": "",
    "Subscribers": "0.0003 March 2019",
    "Owner": "Netline",
    "OperatorLink": "https://www.wikipedia.org#cite_note-Subtel_Chile-57"
  },
  {
    "Country": "Colombia",
    "Rank": 1,
    "Operator": "Claro",
    "Technology": "GSM 850 GPRS EDGE 1900 MHz UMTS HSDPA HSPA 2600 MHz 1900 MHz 700 MHz LTE",
    "Subscribers": "33.5 March 2021",
    "Owner": "Am rica M vil 99.4%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Colombia"
  },
  {
    "Country": "Colombia",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSDPA HSPA 1700 MHz 1900 MHz 850 MHz 2600 TDD MHz LTE",
    "Subscribers": "16.7 March 2021",
    "Owner": "Telef nica 67.5% Colombian Government 32.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Colombia",
    "Rank": 3,
    "Operator": "Tigo",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSDPA HSPA 1700 MHz 700 MHz LTE",
    "Subscribers": "13.0 March 2021",
    "Owner": "Millicom 50% 1 Share EPM and ETB",
    "OperatorLink": "https://www.wikipedia.org/wiki/Colombia_M%C3%B3vil"
  },
  {
    "Country": "Colombia",
    "Rank": 4,
    "Operator": "WOM",
    "Technology": "GSM 850 1900 GPRS EDGE 1700 MHz UMTS HSDPA HSPA 1900 MHz iDEN 1700 MHz 2600 MHz 700 MHz LTE",
    "Subscribers": "2.3 Apr 2020",
    "Owner": "Novator Partners majority stake",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Colombia",
    "Rank": 5,
    "Operator": "Virgin Mobile using Movistar",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSDPA HSPA 1700 MHz LTE",
    "Subscribers": "3.0 March 2021",
    "Owner": "Virgin Mobile",
    "OperatorLink": "https://www.wikipedia.org/wiki/Virgin_Mobile#Virgin_Mobile_Colombia"
  },
  {
    "Country": "Colombia",
    "Rank": 6,
    "Operator": "M vil xito using Tigo",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSDPA HSPA",
    "Subscribers": "1.4 March 2021",
    "Owner": "Grupo xito",
    "OperatorLink": "https://www.wikipedia.org/wiki/Grupo_%C3%89xito"
  },
  {
    "Country": "Colombia",
    "Rank": 7,
    "Operator": "ETB using Tigo",
    "Technology": "GSM GPRS EDGE UMTS HSDPALTE",
    "Subscribers": "0.3 March 2021",
    "Owner": "ETB",
    "OperatorLink": "https://www.wikipedia.org/wiki/ETB_(company)"
  },
  {
    "Country": "Colombia",
    "Rank": 8,
    "Operator": "Flash M vil using Tigo",
    "Technology": "GSM GPRS EDGE UMTS HSDPA",
    "Subscribers": "0.2 March 2021",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Flash_M%C3%B3vil&action=edit&redlink=1"
  },
  {
    "Country": "Colombia",
    "Rank": 9,
    "Operator": "Suma M vil using Tigo",
    "Technology": "GSM GPRS EDGE UMTS HSDPA HSPA 2600 MHz LTE",
    "Subscribers": "0.01 March 2021",
    "Owner": "",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Suma_M%C3%B3vil&action=edit&redlink=1"
  },
  {
    "Country": "Costa Rica",
    "Rank": 1,
    "Operator": "K lbi",
    "Technology": "GSM 1800 GPRS EDGE 850 MHz UMTS HSDPA2600 MHz LTE",
    "Subscribers": 4.33,
    "Owner": "Instituto Costarricense de Electricidad",
    "OperatorLink": "https://www.wikipedia.org#cite_note-69"
  },
  {
    "Country": "Costa Rica",
    "Rank": 2,
    "Operator": "Cabletica",
    "Technology": "GSM 1800 GPRS EDGE 850 2100 MHz UMTS HSDPA 1800 MHz LTE",
    "Subscribers": 1.42,
    "Owner": "Liberty Global",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Cabletica&action=edit&redlink=1"
  },
  {
    "Country": "Costa Rica",
    "Rank": 3,
    "Operator": "Claro",
    "Technology": "GSM 1800 GPRS EDGE 2100 MHz UMTS HSPA 1800 MHz LTE",
    "Subscribers": 1.13,
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Americas"
  },
  {
    "Country": "Costa Rica",
    "Rank": 4,
    "Operator": "Tuyo M vil using K lbi",
    "Technology": "GSM GPRS EDGE UMTS HSDPA",
    "Subscribers": 0.14,
    "Owner": "Televisora de Costa Rica S.A.",
    "OperatorLink": "https://www.wikipedia.org#cite_note-71"
  },
  {
    "Country": "Costa Rica",
    "Rank": 5,
    "Operator": "Fullm vil using K lbi",
    "Technology": "GSM GPRS EDGE UMTS HSDPA",
    "Subscribers": 0.03,
    "Owner": "Virtualis",
    "OperatorLink": "https://www.wikipedia.org#cite_note-72"
  },
  {
    "Country": "Cuba",
    "Rank": 1,
    "Operator": "Cubacel",
    "Technology": "GSM 850 900 MHz GPRS EDGE 2100 MHz UMTS HSDPA HSUPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "ETECSA",
    "OperatorLink": "https://www.wikipedia.org#cite_note-74"
  },
  {
    "Country": "Curaao",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 1900UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Curaao",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "GSM 900 2100 MHz UMTS HSDPA 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/FLOW_(brand)"
  },
  {
    "Country": "Dominica",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 1800 900 UMTS HSPA 2100 1 900 8 LTE 1900 2 700 12 17",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Dominica",
    "Rank": 2,
    "Operator": "FLOW",
    "Technology": "GSM 1900 850 UMTS HSPA 1900 2 850 5 LTE 1900 2 700 13",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Dominican Republic",
    "Rank": 1,
    "Operator": "Claro formerly CODETEL",
    "Technology": "CdmaOne CDMA2000 1x GSM 850 1900 GPRS EDGE 850 MHz UMTS HSDPA1700 MHz LTE",
    "Subscribers": "4.9 Dec 2018",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Rep%C3%BAblica_Dominicana"
  },
  {
    "Country": "Dominican Republic",
    "Rank": 2,
    "Operator": "Altice GSM formerly Orange and Tricom",
    "Technology": "CdmaOne GSM 1800 1900 GPRS EDGE CDMA2000 1x CDMA2000 EV DO 900 MHz UMTS DC HSPA 1800 1900 MHz LTE",
    "Subscribers": "3.3 Dec 2018",
    "Owner": "Altice Hispaniola",
    "OperatorLink": "https://www.wikipedia.org/wiki/CdmaOne"
  },
  {
    "Country": "Dominican Republic",
    "Rank": 3,
    "Operator": "Viva",
    "Technology": "CdmaOne CDMA2000 1x CDMA2000 EV DO GSM 1900 GPRS EDGE",
    "Subscribers": "0.6 Dec 2018",
    "Owner": "Trilogy International Partners",
    "OperatorLink": "https://www.wikipedia.org/wiki/Trilogy_Dominicana_(Viva)"
  },
  {
    "Country": "Ecuador",
    "Rank": 1,
    "Operator": "Claro",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "7.822 October 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Americas"
  },
  {
    "Country": "Ecuador",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "4.54 December 2016",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Ecuador",
    "Rank": 3,
    "Operator": "CNT",
    "Technology": "CdmaOneCDMA2000 1x CDMA2000 EV DO GSM 1900UMTS HSPA 1700 MHz LTE",
    "Subscribers": "0.723 Q1 2015",
    "Owner": "Corporaci n Nacional de Telecomunicaciones CNT EP",
    "OperatorLink": "https://es.wikipedia.org/wiki/Corporaci%C3%B3n_Nacional_de_Telecomunicaciones"
  },
  {
    "Country": "Ecuador",
    "Rank": 4,
    "Operator": "Tuenti using Movistar network",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Tuenti Ecuador",
    "OperatorLink": "https://www.wikipedia.org#cite_note-83"
  },
  {
    "Country": "El Salvador",
    "Rank": 1,
    "Operator": "Tigo",
    "Technology": "D AMPSGSM 850 1900 GPRS EDGE 850 MHz UMTS HSPA 850 MHz LTE",
    "Subscribers": "2.4 June 2017",
    "Owner": "Millicom 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo_El_Salvador"
  },
  {
    "Country": "El Salvador",
    "Rank": 2,
    "Operator": "Claro",
    "Technology": "GSM 850 GPRS EDGE 850 1900 MHz UMTS HSPA",
    "Subscribers": "1.426 June 2007",
    "Owner": "Am rica M vil 95.8%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "El Salvador",
    "Rank": 3,
    "Operator": "Movistar",
    "Technology": "CdmaOneGSM 850 GPRS EDGE 850 1900 MHz UMTS HSDPA 1900 MHz LTE",
    "Subscribers": "0.847 Dec 2006",
    "Owner": "Telef nica 59.58% Corporaci n Multi Inversiones 40%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "El Salvador",
    "Rank": 4,
    "Operator": "Digicel",
    "Technology": "900 MHz GSM GPRS EDGE 900 MHz UMTS HSDPA HSPA",
    "Subscribers": "1.0 June 2015",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "El Salvador",
    "Rank": 5,
    "Operator": "RED",
    "Technology": "iDEN",
    "Subscribers": "NA",
    "Owner": "Intelfon",
    "OperatorLink": "https://www.wikipedia.org/wiki/IDEN"
  },
  {
    "Country": "Falkland Islands",
    "Rank": 1,
    "Operator": "Sure",
    "Technology": "GSM 900",
    "Subscribers": "NA",
    "Owner": "Batelco",
    "OperatorLink": "https://www.wikipedia.org/wiki/Sure_(company)"
  },
  {
    "Country": "Greenland",
    "Rank": 1,
    "Operator": "TELE Greenland",
    "Technology": "GSM 900 900 MHz UMTS800 MHz LTE",
    "Subscribers": "0.01355 2011",
    "Owner": "TELE Greenland",
    "OperatorLink": ""
  },
  {
    "Country": "Grenada",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM GPRS EDGE 900 1800 UMTS HSPA 850 5 LTE 700 13",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Grenada",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM GPRS EDGE 900 1800 UMTS HSPA 1900 2 LTE 700 17",
    "Subscribers": "NA",
    "Owner": "Digicel 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Guadeloupe, Martinique and French Guiana (Guyane)",
    "Rank": 1,
    "Operator": "Orange Cara be",
    "Technology": "GSM 900 GPRS EDGE UMTS HSPA 1800 2600 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Orange S.A.",
    "OperatorLink": "https://www.wikipedia.org#cite_note-97"
  },
  {
    "Country": "Guadeloupe, Martinique and French Guiana (Guyane)",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSDPA",
    "Subscribers": "NA",
    "Owner": "Digicel 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Guadeloupe, Martinique and French Guiana (Guyane)",
    "Rank": 3,
    "Operator": "SFR Cara be",
    "Technology": "GSM 900 1800 2100 MHz UMTS HSDPA LTE",
    "Subscribers": "15% 25% 27% Q2 2012",
    "Owner": "Numericable Altice",
    "OperatorLink": "https://www.wikipedia.org/wiki/SFR"
  },
  {
    "Country": "Guatemala",
    "Rank": 1,
    "Operator": "Tigo",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSPA 850 MHz LTE",
    "Subscribers": "10.6 June 2017",
    "Owner": "Millicom 55%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Guatemala",
    "Rank": 2,
    "Operator": "Claro",
    "Technology": "CdmaOneCDMA2000 1x CDMA2000 EV DO GSM 900 1900 GPRS EDGE 1900 MHz UMTS HSPA",
    "Subscribers": "5.1 June 2017",
    "Owner": "Am rica M vil 99.3%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Guatemala",
    "Rank": 3,
    "Operator": "Movistar",
    "Technology": "CdmaOneCDMA2000 1x CDMA2000 EV DO GSM 1900 GPRS EDGE 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "4.2 June 2017",
    "Owner": "Telef nica 60% Corporaci n Multi Inversiones 40% Purchased by Claro.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Guyana",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 EDGE UMTS HSPA LTE",
    "Subscribers": "0.305 June 2015",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Guyana",
    "Rank": 2,
    "Operator": "Cellink Plus",
    "Technology": "GSM 900 HSPA",
    "Subscribers": "0.203 June 2015",
    "Owner": "Guyana Telephone and Telegraph Company",
    "OperatorLink": ""
  },
  {
    "Country": "Haiti",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "4.7 June 2015",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Haiti",
    "Rank": 2,
    "Operator": "Comcel Voila",
    "Technology": "GSM 850",
    "Subscribers": "1.2 Feb 2011",
    "Owner": "Trilogy International Partners",
    "OperatorLink": "https://www.wikipedia.org/wiki/Comcel_Haiti"
  },
  {
    "Country": "Haiti",
    "Rank": 3,
    "Operator": "NATCOM",
    "Technology": "GSM 9002100 MHz UMTS HSPA 2100 MHz LTE",
    "Subscribers": "0.5 Jan 2012",
    "Owner": "60% owned by Viettel Mobile and 40% by the Haitian State",
    "OperatorLink": ""
  },
  {
    "Country": "Honduras",
    "Rank": 1,
    "Operator": "Tigo",
    "Technology": "CdmaOneGSM 850 GPRS EDGE 850 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "4.3 Jun 2017",
    "Owner": "Millicom 66.7%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Honduras",
    "Rank": 2,
    "Operator": "Claro",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSDPA 1700 MHz LTE",
    "Subscribers": "2.9 April 2016",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Honduras",
    "Rank": 3,
    "Operator": "HONDUTEL",
    "Technology": "GSM 1900",
    "Subscribers": "0.07 April 2016",
    "Owner": "HONDUTEL",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Jamaica",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "CdmaOneCDMA2000 1x CDMA2000 EV DOGSM 900 1800 850 MHz UMTS HSPA DC HSDPA700 MHz Band 17 700 MHz Band 12 1700 MHz Band 4 1900 MHz Band 2 LTE",
    "Subscribers": "2.2 June 2015",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Jamaica",
    "Rank": 2,
    "Operator": "FLOW Jamaica",
    "Technology": "GSM 850 1900 850 1900 MHz UMTS HSPA 700 MHz Band 12 1700 MHz Band 4 1900 MHz Band 2 LTE",
    "Subscribers": "0.93 March 2017",
    "Owner": "Liberty Latin America 82%",
    "OperatorLink": "https://www.wikipedia.org/wiki/FLOW_Jamaica"
  },
  {
    "Country": "Jamaica",
    "Rank": 3,
    "Operator": "Caricel",
    "Technology": "LTE",
    "Subscribers": "NA",
    "Owner": "Symbiote Investments Limited",
    "OperatorLink": "https://www.wikipedia.org/wiki/Caricel"
  },
  {
    "Country": "Mexico",
    "Rank": 1,
    "Operator": "Telcel",
    "Technology": "D AMPSGSM 1900 MHz GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 1700 MHz LTE",
    "Subscribers": "76.6 October 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telcel"
  },
  {
    "Country": "Mexico",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "25.8 March 2017",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Mexico",
    "Rank": 3,
    "Operator": "AT T Mexico Includes Unefon",
    "Technology": "1700 MHz UMTS HSPA 1700 1900 2600 MHz LTE",
    "Subscribers": "12.5 March 2017",
    "Owner": "AT T Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/AT%26T_Mexico"
  },
  {
    "Country": "Mexico",
    "Rank": 4,
    "Operator": "Virgin Mobile using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "0.802 March 2017",
    "Owner": "Virgin Mobile",
    "OperatorLink": "https://www.wikipedia.org/wiki/Virgin_Mobile"
  },
  {
    "Country": "Mexico",
    "Rank": 5,
    "Operator": "QBO Cel using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA",
    "Subscribers": "0.132 March 2017",
    "Owner": "Canaliza Software",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=QBO_Cel&action=edit&redlink=1"
  },
  {
    "Country": "Mexico",
    "Rank": 6,
    "Operator": "weex using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "0.150 March 2017",
    "Owner": "Truu Innovation",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Weex&action=edit&redlink=1"
  },
  {
    "Country": "Mexico",
    "Rank": 7,
    "Operator": "Cierto using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA",
    "Subscribers": "0.015 March 2017",
    "Owner": "Teligentia",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Cierto&action=edit&redlink=1"
  },
  {
    "Country": "Mexico",
    "Rank": 8,
    "Operator": "Maz Tiempo using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA",
    "Subscribers": "0.010 March 2017",
    "Owner": "Maz Tiempo",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Maz_Tiempo&action=edit&redlink=1"
  },
  {
    "Country": "Mexico",
    "Rank": 9,
    "Operator": "Oui M vil using Telcel",
    "Technology": "GSM 1900 MHz GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 1700 MHz LTE",
    "Subscribers": "0.030 March 2017",
    "Owner": "Telecomunicaciones 360",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telcel"
  },
  {
    "Country": "Mexico",
    "Rank": 10,
    "Operator": "Flash Mobile using Movistar",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "0.064 March 2017",
    "Owner": "Log stica ACN M xico",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Flash_Mobile&action=edit&redlink=1"
  },
  {
    "Country": "Montserrat",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM 850 EDGE UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/FLOW_(Brand)"
  },
  {
    "Country": "Montserrat",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM EDGE UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Digicel Group",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Nicaragua",
    "Rank": 1,
    "Operator": "Claro formerly Enitel",
    "Technology": "GSM 1900 GPRS EDGE 850 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "3.67 Dec 2013",
    "Owner": "Am rica M vil 99.6%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Americas"
  },
  {
    "Country": "Nicaragua",
    "Rank": 2,
    "Operator": "Tigo",
    "Technology": "D AMPSCdmaOneCDMA2000 1xGSM 850 GPRS EDGE850 1900 MHz UMTS HSDPA 1900 MHz LTE",
    "Subscribers": "3.14 Dec 2013",
    "Owner": "Millicom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Panama",
    "Rank": 1,
    "Operator": "Cable Wireless",
    "Technology": "GSM 850 GPRS EDGE 850 MHz UMTS HSDPA HSPA 700 MHz LTE Band 28",
    "Subscribers": "1.78 March 2017",
    "Owner": "Cable Wireless 49%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Cable_%26_Wireless_Communications#Panama"
  },
  {
    "Country": "Panama",
    "Rank": 2,
    "Operator": "Tigo",
    "Technology": "GSM 850 GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 700 MHz LTE Band 28",
    "Subscribers": "0.939 Dec 2006",
    "Owner": "Millicom",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Panama",
    "Rank": 3,
    "Operator": "Claro",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 700 1900 MHz LTE Band 28 Band 2",
    "Subscribers": "Available",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Panama",
    "Rank": 4,
    "Operator": "Digicel",
    "Technology": "GSM 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA HSPA 700 MHz LTE Band 28",
    "Subscribers": "Available",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Paraguay",
    "Rank": 1,
    "Operator": "Tigo",
    "Technology": "GSM 850 1900 GPRS EDGE 850 MHz UMTS HSDPA1700 MHz LTE",
    "Subscribers": "3.4 Jun 2017",
    "Owner": "Millicom 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Tigo"
  },
  {
    "Country": "Paraguay",
    "Rank": 2,
    "Operator": "Personal",
    "Technology": "GSM 850 GPRS EDGE 850 1900 MHz UMTS DC HSPA 1900 MHz LTE",
    "Subscribers": "2.4 Nov 2015",
    "Owner": "ABC Color and Telecom Argentina",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telecom_Personal"
  },
  {
    "Country": "Paraguay",
    "Rank": 3,
    "Operator": "Claro",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "0.8 Nov 2015",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Paraguay",
    "Rank": 4,
    "Operator": "VOX",
    "Technology": "GSM 850 1900 GPRS EDGE 900 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "0.36 Nov 2015",
    "Owner": "COPACO S.A.",
    "OperatorLink": "https://es.wikipedia.org/wiki/Vox_(Paraguay)"
  },
  {
    "Country": "Peru",
    "Rank": 1,
    "Operator": "Movistar",
    "Technology": "D AMPSCdmaOne CDMA2000 1x CDMA2000 EV DO GSM 850 GPRS EDGE 850 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "15.0 Mar 2017",
    "Owner": "Telef nica 98.57%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Peru",
    "Rank": 2,
    "Operator": "Claro Formerly TIM",
    "Technology": "GSM 1900 GPRS EDGE 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "10.552 October 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_(mobile_phone_network)"
  },
  {
    "Country": "Peru",
    "Rank": 3,
    "Operator": "Entel Formerly Nextel",
    "Technology": "iDEN1900 MHz UMTS HSDPA1700 MHz LTE",
    "Subscribers": "5.9 Mar 2017",
    "Owner": "Entel Chile",
    "OperatorLink": "https://www.wikipedia.org/w/index.php?title=Entel_(Peru)&action=edit&redlink=1"
  },
  {
    "Country": "Peru",
    "Rank": 4,
    "Operator": "Bitel Formerly Viettel Peru S.A.C.",
    "Technology": "1900 MHz UMTS HSPA 900 MHz LTE",
    "Subscribers": "4.6 Mar 2017",
    "Owner": "Viettel Mobile",
    "OperatorLink": "https://www.wikipedia.org/wiki/Viettel_Mobile"
  },
  {
    "Country": "Peru",
    "Rank": 5,
    "Operator": "Cuy M vil using Claro",
    "Technology": "",
    "Subscribers": "NA",
    "Owner": "Cuy M vil",
    "OperatorLink": "https://es.wikipedia.org/wiki/Cuy_M%C3%B3vil"
  },
  {
    "Country": "Puerto Rico & United States Virgin Islands",
    "Rank": 1,
    "Operator": "Liberty Latin America formerly AT T",
    "Technology": "850 1900 MHz UMTS HSDPA HSUPA HSPA HSPA 850 5 700 12 14 17 29 1700 4 66 1900 2 2300 30 MHz LTE LTE A 850 5 MHz 5G NR2G was discontinued as of 31 December 2016.",
    "Subscribers": "1.1 Nov 2020",
    "Owner": "Liberty Latin America",
    "OperatorLink": ""
  },
  {
    "Country": "Puerto Rico & United States Virgin Islands",
    "Rank": 2,
    "Operator": "Claro",
    "Technology": "GSM 850 1900 GPRS EDGE 850 1900 MHz UMTS HSDPA HSUPA HSPA HSPA 700 1700AWS MHz LTE",
    "Subscribers": "1.4 timeframe",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Puerto_Rico"
  },
  {
    "Country": "Puerto Rico & United States Virgin Islands",
    "Rank": 3,
    "Operator": "T Mobile Includes Metro by T Mobile Includes the previous Sprint network",
    "Technology": "CDMA2000 1xRTT EV DO Rev 0 Rev AGSM 1900 GPRS EDGE AWS UMTS HSDPA HSUPA HSPA HSPA 600 700 12 13 1700 1900 MHz LTE LTE A 600 2500 MHz 5G NR",
    "Subscribers": "NA",
    "Owner": "Deutsche Telekom AG 43% SoftBank Group Corporation 24% free float 33%",
    "OperatorLink": "https://www.wikipedia.org/wiki/T-Mobile_US"
  },
  {
    "Country": "Puerto Rico & United States Virgin Islands",
    "Rank": 4,
    "Operator": "Viya formerly Innovative VI",
    "Technology": "GSM EDGE850MHz UMTS HSPA CDMA EVDO 1900 MHzLTE 2500 MHz",
    "Subscribers": "NA",
    "Owner": "Atlantic Tele Network",
    "OperatorLink": "https://www.wikipedia.org/wiki/GSM"
  },
  {
    "Country": "Puerto Rico & United States Virgin Islands",
    "Rank": 5,
    "Operator": "TracFone Wireless",
    "Technology": "CdmaOneCDMA2000 1xRTTGSM GPRS",
    "Subscribers": "NA",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/TracFone_Wireless"
  },
  {
    "Country": "Saint Kitts and Nevis",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM 850 1900UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Saint Kitts and Nevis",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 2100 MHz UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Saint Lucia",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM 850UMTS HSPA 700 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Global",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Saint Lucia",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 EDGE 2100 MHz UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Digicel 91.02%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Saint Vincent and the Grenadines",
    "Rank": 1,
    "Operator": "FLOW",
    "Technology": "GSM 850 GPRS UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "Saint Vincent and the Grenadines",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 2100 MHz UMTS",
    "Subscribers": "NA",
    "Owner": "Digicel",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Suriname",
    "Rank": 1,
    "Operator": "Telesur",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 2100 MHz UMTS HSPA 700 1800 MHz LTE",
    "Subscribers": "NA",
    "Owner": "Telesur",
    "OperatorLink": "https://www.wikipedia.org/wiki/Telesur_(Suriname)"
  },
  {
    "Country": "Suriname",
    "Rank": 2,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 MHz GPRS EDGE 850 MHz UMTS HSPA",
    "Subscribers": "NA",
    "Owner": "Digicel 87.7%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Trinidad and Tobago",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM GPRS EDGE 850 UMTS HSPA DC HSPA 850 5 1900 2 LTE LTE A 700 28 1700 4 1900 2",
    "Subscribers": "1.35 2016",
    "Owner": "Digicel 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Trinidad and Tobago",
    "Rank": 2,
    "Operator": "bmobile",
    "Technology": "GSM GPRS EDGE 850 UMTS HSPA DC HSPA 850 5 1900 2 LTE LTE A 700 28 1900 2 2500 41 for FWB NR 2500 FWB",
    "Subscribers": "1.2 2016",
    "Owner": "Cable Wireless LLA 49% Government of Trinidad and Tobago 51%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Bmobile"
  },
  {
    "Country": "Turks and Caicos Islands",
    "Rank": 1,
    "Operator": "Digicel",
    "Technology": "GSM 900 1800 GPRS EDGE 2100 MHz UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Digicel 51%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digicel"
  },
  {
    "Country": "Turks and Caicos Islands",
    "Rank": 2,
    "Operator": "FLOW which acquired the assets of Islandcom Wireless",
    "Technology": "GSM 850UMTS HSPA LTE",
    "Subscribers": "NA",
    "Owner": "Liberty Latin America",
    "OperatorLink": "https://www.wikipedia.org/wiki/Flow_(brand)"
  },
  {
    "Country": "United States",
    "Rank": 1,
    "Operator": "Verizon",
    "Technology": "CDMA2000 1xRTT EV DO Rev A700 13 850 5 1700 4 66 1900 2 3500 48 MHz LTE850 n5 3700 n77 28000 n261 39000 n260 MHz 5G NR",
    "Subscribers": "121.3 Q2 2021",
    "Owner": "Verizon Communications Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/Verizon_(mobile_network)"
  },
  {
    "Country": "United States",
    "Rank": 2,
    "Operator": "T Mobile US Includes Metro by T Mobile",
    "Technology": "CDMA2000 1xRTT 1x Advanced EV DO Rev AGSM 1900 GPRS EDGE 1700 4 1900 2 MHz UMTS HSDPA HSUPA HSPA HSPA 600 71 700 12 850 5 26 1700 4 66 1900 2 25 2500 41 3500 48 MHz LTE LTE A600 n71 2500 n41 26000 n258 28000 n261 39000 n260 MHz 5G NR",
    "Subscribers": "104.8 Q2 2021",
    "Owner": "Deutsche Telekom AG 52.1% free float 39.4% SoftBank Group Corporation 8.5%",
    "OperatorLink": "https://www.wikipedia.org/wiki/T-Mobile_US"
  },
  {
    "Country": "United States",
    "Rank": 3,
    "Operator": "AT T Mobility Includes Cricket Wireless",
    "Technology": "850 1900 MHz UMTS HSDPA HSUPA HSPA HSPA 850 5 700 12 14 17 29 1700 4 66 1900 2 2300 30 MHz LTE LTE A850 n5 7700 n77 28000 n261 39000 n260 MHz 5G NR2G was discontinued as of 31 December 2016.",
    "Subscribers": "97.8 Q2 2021",
    "Owner": "AT T Inc.",
    "OperatorLink": "https://www.wikipedia.org/wiki/AT%26T_Mobility"
  },
  {
    "Country": "United States",
    "Rank": 4,
    "Operator": "Dish Wireless Includes Boost Mobile",
    "Technology": "600 n71 700 n29 850 n26 1700 n66 70 MHz 5G NR in construction primarily roaming on the T Mobile network",
    "Subscribers": "8.9 Q2 2021",
    "Owner": "Dish Network Corporation",
    "OperatorLink": "https://www.wikipedia.org/wiki/Dish_Wireless"
  },
  {
    "Country": "United States",
    "Rank": 5,
    "Operator": "U.S. Cellular",
    "Technology": "CDMA2000 1xRTT EV DO Rev A600 71 700 12 850 5 26 1700 4 66 1900 2 25 2500 41 MHz LTE LTE A 600 n71 26000 n258 39000 n260 MHz 5G NR",
    "Subscribers": "4.9 Q4 2020",
    "Owner": "Telephone and Data Systems Inc. 84%",
    "OperatorLink": "https://www.wikipedia.org/wiki/U.S._Cellular"
  },
  {
    "Country": "Uruguay",
    "Rank": 1,
    "Operator": "Antel",
    "Technology": "1800 MHz GSM2100 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "2.75 June 2020",
    "Owner": "State owned",
    "OperatorLink": "https://www.wikipedia.org/wiki/Antel"
  },
  {
    "Country": "Uruguay",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "850 1900 MHz GSM850 1900 MHz UMTS HSPA 1900 MHz LTE",
    "Subscribers": "1.63 June 2020",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Uruguay",
    "Rank": 3,
    "Operator": "Claro",
    "Technology": "1900 MHz GSM1900 MHz UMTS HSPA 1700 MHz LTE",
    "Subscribers": "0.96 June 2020",
    "Owner": "Am rica M vil 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Claro_Americas"
  },
  {
    "Country": "Venezuela",
    "Rank": 1,
    "Operator": "Movilnet",
    "Technology": "D AMPSCdmaOneCDMA2000 1x CDMA2000 EV DO Rev. 0 GSM 850 GPRS EDGE 1900 MHz UMTS HSDPA 1700 2100 MHz LTE Jan. 2017",
    "Subscribers": "8.85 Dec 2018",
    "Owner": "CANTV",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movilnet"
  },
  {
    "Country": "Venezuela",
    "Rank": 2,
    "Operator": "Movistar",
    "Technology": "GSM 850 GPRS EDGE 1900 MHz UMTS HSPA HSPA 1700 2100 MHz LTE",
    "Subscribers": "8.84 Dec 2018",
    "Owner": "Telef nica 100%",
    "OperatorLink": "https://www.wikipedia.org/wiki/Movistar"
  },
  {
    "Country": "Venezuela",
    "Rank": 3,
    "Operator": "Digitel GSM",
    "Technology": "GSM 900 GPRS EDGE 900 MHz UMTS HSDPA HSUPA HSPA 1800 MHz LTE",
    "Subscribers": "3.04 Dec 2018",
    "Owner": "Telvenco",
    "OperatorLink": "https://www.wikipedia.org/wiki/Digitel_GSM"
  }

]
    }
},
  name: 'CarrierTable',
    components: {
},
mounted () {
     document.getElementById("input1").focus();
 var self = this
            window.addEventListener('keyup', function(event) {
                              //console.log(evenat)
                console.log(self.searchSplit)
                document.getElementById("input1").focus();
                this.page_number = 1
            });
},
methods: {
  ExportToExcel(type, fn, dl) {
      if (confirm('This will download an excel file with the table as shown. You will only download filtered results.')) {
 var elt = document.getElementById('mnotable');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64', cellStyles:false }) :
                XLSX.writeFile(wb, fn || ('mnoinfo.' + (type || 'xlsx')));
  console.log('Thing was saved to the database.');
} else {
  // Do nothing!
  console.log('Thing was not saved to the database.');
}
            
        }
},
  computed: {
      
  searchResult() {
  
  let tmp_data = this.mno_data.sort(function(a, b){
    if(a.Country < b.Country) { return -1; }
    if(a.Country > b.Country) { return 1; }
    return 0;
})
if (this.search != '' && this.search) {
tmp_data = this.mno_data
.filter(x => x.Country
.toString()
.toUpperCase()
.includes(this.search.toUpperCase()) == this.search.toString().toUpperCase().includes(this.search.toUpperCase()) || x.Operator.toString().toUpperCase().includes(this.search.toUpperCase()) == this.search.toString().toUpperCase().includes(this.search.toUpperCase()));

}
  return tmp_data

  },
  ownerToggle () {
    if (this.ownerShow) {
      return 'Hide'
    } else { return 'Show' }
  },
  techToggle () {
    if (this.technologyShow) {
      return 'Hide'
    } else { return 'Show' }
  },
}
}

</script>
<style scoped>
* {
    margin:0;
}
.table {
  margin-top: 15px;
}
.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}
.btn-hover.color-8 {
    background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}
.btn-hover.color-9 {
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.btn-hover.color-11 {
       background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);  box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}
</style>