<template>
  <nav class="navbar is-black">
  <div style="font-family: 'Baloo 2', cursive; font-size: 2rem; font-weight: 800;" class="navbar-brand">
    <a class="navbar-item">
      <router-link class="routerlink navbar-item" to="/">mnoinfo.com</router-link>
    </a>
    
    
       
    <div @click="showNav = !showNav" class="navbar-burger" data-target="navbarExampleTransparentExample">
      <span> </span>
      <span> </span>
      <span> </span>
      
    </div>
  </div>

  <div id="navbarExampleTransparentExample" class="has-background-black-bis navbar-menu" v-bind:class="{'is-active': showNav}">
    <div class="navbar-start">
        <router-link @click="showNav = !showNav" class="routerlink navbar-item" to="/">Carriers</router-link>
        <router-link @click="showNav = !showNav" class="routerlink navbar-item" to="/mccmncs">MCCMNCs</router-link>
      <!-- <a style="font-family: 'Baloo 2', cursive; font-weight: 800;" class="navbar-item" href="#">
      </a> -->
      <!-- <div class="navbar-item">
         <a style="font-family: 'Baloo 2', cursive; font-weight: 800;" class="navbar-link" href="https://github.com/Swabuu" target="_blank"> -->
        <!-- </a>
        
      </div> -->
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="field is-grouped">
          <p class="control">
            <a class="btn-hover color-9 bd-tw-button button" >
              <span class="icon">
                <i class="fab fa-twitter"></i>
              </span>
              <span>
                On Twitter
              </span>
            </a>
          </p>
          <p class="control">
            <a class="btn-hover color-1 bd-tw-button button">
              <span class="icon">
                <i class="fab fa-linkedin-in"></i>
              </span>
              <span>LinkedIn</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

</nav>
</template>   


<script>

export default {
  data () {
    return {
      showNav: false
    }
    },
  name: 'NavBar',
  props: {
    msg: String
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-brand {
  padding: 
}
.routerlink {
    color: white;
    font-family: 'Baloo 2', cursive; font-weight: 800;
}
.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 0px;
    height: 35px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
.btn-hover.color-9 {
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
</style>
