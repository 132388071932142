<template>
      <CarrierPage />
      <!-- <MnoTable /> -->


</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import NavBar from '@/components/NavBar.vue'
import CarrierPage from '@/components/CarrierPage.vue'

export default {
    data() {
    return { 
      search: '',
     
     }
  },
  name: 'Carriers',
  components: {
    Hero,
    NavBar,
    CarrierPage
  }
}
</script>
