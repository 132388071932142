<template>
<section>
<div class="container">

<CarrierTable />

<!-- <div v-if="showModal === true" class="modal is-active">
  <div @click="showModal = false;" class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head has-background-black-bis">
      <p class="modal-card-title has-text-danger-light">{{test}}</p>
      <button @click="showModal = false;" class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body has-background-black-bis">
      <iframe style="width:100%; height: 800px;"  :src="modalLink"></iframe>
    </section>
    <footer class="modal-card-foot has-background-black-bis">
      
      <button @click="showModal = false;" class="button is-danger">Close</button>
    </footer>
  </div>
</div> -->
</div>
</section>
</template>

<script>
import CarrierTable from '@/components/CarrierTable.vue'

export default {
  name: 'CarrierPage',
    components: {
      CarrierTable
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>